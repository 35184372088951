import gql from 'graphql-tag'
import {
  CoverLetterGetDocument,
  CoverLetterGetListDocument,
  CoverLetterCreateDocument,
  CoverLetterCreateMutationVariables,
  CoverLetterUpdateDocument,
  CoverLetterUpdateMutationVariables,
  CoverLetterDeleteManyDocument,
  CoverLetterSource,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
  getOne: {
    query: CoverLetterGetDocument,
  },
  getList: {
    query: CoverLetterGetListDocument,
    parseResponse: response => {
      const { data, total } = response.data
      return {
        total: total,
        data: data.map(edge => edge.node),
      }
    },
  },
  create: {
    query: CoverLetterCreateDocument,
    variables(args): CoverLetterCreateMutationVariables {
      return {
        input: {
          ...args,
          source: CoverLetterSource.MANUAL_BY_ADMIN,
        },
      }
    },
    input(params) {
      /* eslint-disable */
      const {
        createdAt,
        updatedAt,
        deletedAt,
        isScrapped,
        scrapCount,
        score,
        __typename,
        ...other
      } = params
      return other
    },
  },
  update: {
    query: CoverLetterUpdateDocument,
    variables(id, args): CoverLetterUpdateMutationVariables {
      return {
        input: {
          id,
          ...args,
        },
      }
    },
  },
  deleteMany: {
    query: CoverLetterDeleteManyDocument,
  },
}

gql`
  fragment CoverLetterFragment on CoverLetter {
    id
    passedAt
    organizationName
    role
    university
    major
    grades
    languageScore
    career
    content
    types
    createdAt
    updatedAt
    deletedAt
    source
    status
    score
    sourceKey
    scrapCount
    activity
    license
  }
`

gql`
  query CoverLetterGet($id: ID!) {
    data: coverLetter(id: $id) {
      ...CoverLetterFragment
    }
  }
`

gql`
  query CoverLetterGetList(
    $filterBy: CoverLetterFilter!
    $orderBy: CoverLetterOrder
    $pagination: Pagination
  ) {
    data: coverLetters(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: edges {
        node {
          id
          ...CoverLetterFragment
        }
        highlight
      }
      total: totalCount
    }
  }
`

gql`
  mutation CoverLetterCreate($input: CoverLetterCreateInput!) {
    data: coverLetterCreate(input: $input) {
      data: coverLetter {
        ...CoverLetterFragment
      }
    }
  }
`

gql`
  mutation CoverLetterUpdate($input: CoverLetterUpdateInput!) {
    data: coverLetterUpdate(input: $input) {
      data: coverLetter {
        ...CoverLetterFragment
      }
    }
  }
`

gql`
  mutation CoverLetterDeleteMany($ids: [ID!]!) {
    data: coverLetterDeleteMany(ids: $ids) {
      data: coverLetters {
        ...CoverLetterFragment
      }
    }
  }
`
gql`
  mutation CoverLetterSyncWithElastic {
    syncCoverLetterWithElastic
  }
`
