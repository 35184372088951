import React, { FunctionComponent } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import {
  List as RaList,
  ExportButton,
  Filter,
  TextInput,
  sanitizeListRestProps,
  TopToolbar,
  SelectInput,
} from 'react-admin'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { CoverLetterField } from 'generated/graphql'
import UpdateOrganizationNameForm from './UpdateOrganizationNameForm'

const useListStyles = makeStyles({
  content: {
    width: 'inherit',
    background: 'transparent',
    boxShadow: 'none',
    paddingTop: 24,
  },
})

const usePostActionsStyles = makeStyles({
  root: {
    justifyContent: 'center',
    flexGrow: 1,
    paddingTop: 8,
  },
})

const PostFilter: React.FC<any> = props => (
  <Filter {...props} submitOnEnter>
    <SelectInput
      label="필드"
      source="field"
      choices={[
        { id: CoverLetterField.ORGANIZATION_NAME, name: '기업명' },
        { id: CoverLetterField.ROLE, name: '직무' },
      ]}
      alwaysOn
    />
    <TextInput label="이전 이름" source="q" alwaysOn />
  </Filter>
)

const PostActions: React.FC<any> = ({
  currentSort,
  resource,
  exporter,
  filterValues,
  total,
  ...rest
}) => {
  const classes = usePostActionsStyles()
  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <UpdateOrganizationNameForm
        query={filterValues.q || ''}
        field={filterValues.field}
      />
      <Box flexGrow={1} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  )
}

const OrgNameGrid: React.FC<any> = ({ ids, data }) => {
  return (
    <Container maxWidth="sm">
      <Paper>
        <List>
          {ids.map(id => (
            <ListItem key={id} divider>
              <ListItemAvatar>
                <Avatar>{get(data, `${id}.count`, 0)}</Avatar>
              </ListItemAvatar>

              <ListItemText primary={get(data, `${id}.query`, 0)} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  )
}

const CoverLetterOrganizationNameList: FunctionComponent = props => {
  const classes = useListStyles()

  return (
    <RaList
      hasEdit={false}
      hasShow={false}
      classes={classes}
      {...props}
      bulkActionButtons={false}
      pagination={null}
      actions={<PostActions />}
      filters={<PostFilter />}
    >
      <OrgNameGrid />
    </RaList>
  )
}

export const mapStateToProps = () => ({
  basePath: '/cover-letters/update-field',
  resource: 'cover-letters.update-field',
})

export default connect(mapStateToProps)(CoverLetterOrganizationNameList)
