import React from 'react'

const DeleteIcon = () => {
  return (
    <svg
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fontSize="inherit"
      fill="currentColor"
    >
      <path
        d="M343.959 139.566l4.146-4.146a.5.5 0 00-.707-.707l-4.146 4.146-4.147-4.146a.5.5 0 10-.707.707l4.147 4.146-4.147 4.147a.5.5 0 00.707.707l4.147-4.147 4.146 4.147a.5.5 0 00.707-.707z"
        transform="translate(-338.252 -134.566)"
      ></path>
    </svg>
  )
}

export default DeleteIcon
