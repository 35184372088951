import React, { FC, useEffect, useState } from 'react'
import {
  IconButton,
  LinearProgress,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import CoverLetterPlagiarismCheckItem from './CoverLetterPlagiarismCheckItem'
import { Launch } from '@material-ui/icons'
import gql from 'graphql-tag'
import { useCoverLettersPlagiarismLazyQuery } from 'generated/graphql'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      paddingTop: 50,

      [theme.breakpoints.up('xs')]: {
        // 900px: 우측 EDIT 패널, 240px 좌측 메뉴
        width: 'calc(100vw - 900px - 240px)',
      },
    },
    header: {
      display: 'flex',
      padding: 14,
      height: 30,
      fontSize: '1.2rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
    },
    headerResult: {
      fontSize: '0.9rem',
      marginLeft: 6,
    },
    hintColor: {
      color: theme.palette.text.hint,
      fontSize: '0.9rem',
    },
    textarea: {
      width: '100%',
      height: '300px',
      resize: 'none',
      border: 0,
      font: 'inherit',
      background: 'none',
      paddingRight: 8,
    },
    idField: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      color: theme.palette.info.main,
    },
    launchIcon: {
      textDecoration: 'underline',
      fontSize: '0.9rem',
      marginLeft: 2,
    },
    circularProgress: {
      marginLeft: 4,
    },
  }
})

interface SelectedItems {
  [id: string]: boolean
}

interface CoverLetterPlagiarismCheckProps {
  coverLetterID: string
  onCancel: any
  className?: string
}

const CoverLetterPlagiarismCheck: FC<CoverLetterPlagiarismCheckProps> = ({
  coverLetterID,
  onCancel,
  className,
}) => {
  const classes = useStyles()
  const [
    getCoverLettersPlagiarism,
    { data, loading, called, error },
  ] = useCoverLettersPlagiarismLazyQuery({
    variables: {
      id: coverLetterID,
    },
  })
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({})

  const makeNameById = (id: string) => {
    return `check-item-${id}`
  }

  const accordionIsOpen = (name: string): boolean => {
    return selectedItems[name]
  }

  const initialSelectedItem = () => {
    const newObject = {}
    data?.coverLetterPlagiarismList?.nodes?.map(({ id }) => {
      newObject[makeNameById(id)] = false
    })

    setSelectedItems(newObject)
  }

  const toggleSelectedItem = (name: string) => {
    setSelectedItems(prev => {
      return {
        ...prev,
        [name]: !prev[name],
      }
    })
  }

  const onChangeAccordion = (name: string) => {
    toggleSelectedItem(name)
  }

  const onClickIdField = id => {
    window.open(`/cover-letters/${id}`)
  }

  const onClickRequestButton = () => {
    getCoverLettersPlagiarism()
  }

  const parseFloatWithCalcPercent = (floatString: string): string => {
    return `${(parseFloat(floatString) * 100).toFixed(1)}%`
  }

  useEffect(() => {
    if (!loading && !!data?.coverLetterPlagiarismList?.nodes) {
      initialSelectedItem()
    }
  }, [data, loading])

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.header}>
        <span>
          표절률 검사
          {data?.coverLetterPlagiarismList?.totalCount === undefined ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.headerResult}
              disabled={called || loading}
              onClick={onClickRequestButton}
            >
              요청
              {loading && (
                <CircularProgress
                  size={10}
                  className={classes.circularProgress}
                />
              )}
            </Button>
          ) : (
            <span className={classes.headerResult}>
              (검색 결과: {data.coverLetterPlagiarismList?.totalCount} 건)
            </span>
          )}
        </span>

        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      {/* TODO 조건부 리팩토링 */}
      {!called && '위의 요청 버튼으로 검사를 시도해주세요.'}
      {called && loading && (
        <>
          <LinearProgress />
          <div>
            로딩중..{' '}
            <span className={classes.hintColor}>
              (캐시가 없을 경우, 약 1분 정도 소요됩니다.)
            </span>
          </div>
        </>
      )}
      {called && !loading && (
        <div>
          {!!data?.coverLetterPlagiarismList?.nodes &&
          data?.coverLetterPlagiarismList?.nodes?.length > 0 ? (
            data?.coverLetterPlagiarismList?.nodes?.map(
              ({ id, content, score }) => {
                const itemName = makeNameById(id)
                return (
                  <CoverLetterPlagiarismCheckItem
                    key={itemName}
                    name={itemName}
                    onChange={onChangeAccordion}
                    open={accordionIsOpen(itemName)}
                    headerTitle={
                      <span
                        onClick={() => {
                          onClickIdField(id)
                        }}
                        className={classes.idField}
                      >
                        자소서 ID: {id}{' '}
                        <Launch className={classes.launchIcon} />
                      </span>
                    }
                    headerDesc={`(${parseFloatWithCalcPercent(
                      score,
                    )}) ${content}`}
                  >
                    <textarea className={classes.textarea} readOnly>
                      {content}
                    </textarea>
                  </CoverLetterPlagiarismCheckItem>
                )
              },
            )
          ) : (
            <div>
              {error?.message
                ? error?.message
                : '표절률 50% 이상 매칭되는 문서가 존재하지 않습니다.'}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CoverLetterPlagiarismCheck

gql`
  query CoverLettersPlagiarism($id: ID!) {
    coverLetterPlagiarismList(id: $id) {
      nodes {
        id
        content
        score
      }
      totalCount
    }
  }
`
