import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import addMinutes from 'date-fns/addMinutes'
import { KIBANA_BASE_URL } from 'config'

const LOG_TIME_SPAN_IN_MINUTES = 60

export interface KibanaFieldProps {
  label?: string
  record?: any
  addLabel?: boolean
  source: string
  dateSource: string
  dateOffsetInMinutes?: number // 로그 뷰 기준 시각를 구하기 위해 dateSource에 더해지는 값
  esField: string // elasticsearch document field
}

const KibanaField: FunctionComponent<KibanaFieldProps> = ({
  record = {},
  source,
  dateSource,
  dateOffsetInMinutes = 10, // log가 elasticsearch에 전송되는 시간을 고려하여 10분을 더한다
  esField,
  children,
}) => {
  const value = get(record, source)
  if (!value) {
    return null
  }

  const dateTo = addMinutes(
    record[dateSource] || Date.now(),
    dateOffsetInMinutes,
  )
  const dateFrom = addMinutes(dateTo, -LOG_TIME_SPAN_IN_MINUTES)

  const _g = `(refreshInterval:(pause:!t,value:0),time:(from:'${dateFrom.toISOString()}',to:'${dateTo.toISOString()}'))`
  const _a = `(columns:!(message,fields.${esField}),interval:auto,query:(language:kuery,query:'fields.${esField}:+%22${value}%22'),sort:!(!('@timestamp',desc)))`
  const url = `${KIBANA_BASE_URL}#/discover?_g=${_g}&_a=${_a}`

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children || value}
    </a>
  )
}

KibanaField.defaultProps = {
  addLabel: true,
}

export default KibanaField
