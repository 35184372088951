import React, { FunctionComponent, useMemo } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { IntlProvider, useIntl } from 'react-intl'
import { Admin, Resource } from 'react-admin'
import { createBrowserHistory as createHistory } from 'history'
import Dashboard from './components/Dashboard'
import { theme } from './theme'
import { apolloClient, graphqlClient } from './graphql'
import { createAuthProvider } from './auth'
import { createI18nProvider, messages } from './i18n'
import querieBuilders from './queries'
import { createDataProvider } from './data-provider'
import { Layout } from './components/layout'
import * as resources from './resources'
import customRoutes from 'customRoutes'

const dataProvider = createDataProvider(graphqlClient, querieBuilders)
const authProvider = createAuthProvider()

// https://github.com/mui-org/material-ui/issues/11843
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
})

const history = createHistory()

const AdminInstallation: FunctionComponent = () => {
  const intl = useIntl()
  const i18nProvider = useMemo(() => createI18nProvider(intl), [intl])

  return (
    <Admin
      customRoutes={customRoutes}
      theme={theme}
      layout={Layout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      history={history}
    >
      {() => [
        <Resource
          key="activities"
          name="activities"
          list={resources.ActivityList}
        />,
        <Resource key="replies" name="replies" list={resources.ReplyList} />,
        <Resource
          key="users"
          name="users"
          list={resources.UserList}
          edit={resources.UserEdit}
          show={resources.UserShow}
        />,
        <Resource
          key="signup-sessions"
          name="signup-sessions"
          list={resources.SignupSessionList}
          show={resources.SignupSessionShow}
        />,
        <Resource
          key="search-page.recommend-keyword"
          name="search-page.recommend-keyword"
          create={resources.RecommendKeywordCreate}
          list={resources.RecommendKeywordList}
          edit={resources.RecommendKeywordEdit}
        />,
        <Resource
          key="search-page.curation"
          name="search-page.curation"
          create={resources.CurationCreate}
          list={resources.CurationList}
          edit={resources.CurationEdit}
          show={resources.CurationShow}
        />,
        <Resource
          key="cover-letters"
          name="cover-letters"
          create={resources.CoverLetterCreate}
          list={resources.CoverLetterList}
          show={resources.CoverLetterShow}
        />,
        <Resource
          key="cover-letters.recommend-keyword"
          name="cover-letters.recommend-keyword"
          create={resources.CoverLetterRecommendKeywordCreate}
          list={resources.CoverLetterRecommendKeywordList}
          edit={resources.CoverLetterRecommendKeywordEdit}
        />,
        <Resource
          key="cover-letters.update-field"
          name="cover-letters.update-field"
          list={resources.CoverLetterOrganizationNameList}
        />,
        <Resource
          key="advertisement"
          name="advertisement"
          list={resources.AdvertisementList}
        />,
        <Resource
          key="linkareer-magazine"
          name="linkareer-magazine"
          edit={resources.LinkareerMagazine}
        />,
      ]}
    </Admin>
  )
}

const App = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <SnackbarProvider maxSnack={3}>
        <IntlProvider
          locale="ko"
          defaultLocale="ko"
          messages={messages}
          onError={() => {}}
        >
          <ApolloProvider client={apolloClient as any}>
            <AdminInstallation />
          </ApolloProvider>
        </IntlProvider>
      </SnackbarProvider>
    </StylesProvider>
  )
}

export default App
