import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

interface DeleteDialogProps {
  open: boolean
  onClose: () => void
  description: string
  cancleOnClick: () => void
  deleteOnClick: () => void
  title?: string
}

const DeleteDialog: FC<DeleteDialogProps> = ({
  open,
  onClose,
  title,
  description,
  cancleOnClick,
  deleteOnClick,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      {title && <DialogTitle id="delete-dialog-title">{title}</DialogTitle>}

      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancleOnClick}>취소</Button>
        <Button onClick={deleteOnClick} color="primary">
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
