import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import gql from 'graphql-tag'
import { NetworkStatus } from '@apollo/client'
import {
  PlacementList_AdPlacementFragment,
  usePlacementListQuery,
  AdPlacementOrderField,
  OrderDirection,
  PlacementListQueryVariables,
} from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import Table, { BooleanCell } from 'components/Table'
import { createGlobalQueryField } from 'components/Table/FilterBar'
import Error from 'components/Error'
import {
  useQueryStringVariables,
  paginationSchema,
  orderBySchema,
} from 'hooks/useVariablesQueryStringSync'

const useStyles = makeStyles({
  fieldset: {
    marginBottom: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    color: 'rgba(0, 0, 0, 0.53)',
  },
})

const PlacementList: FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const [queryVariables, updateQueryString] = useQueryStringVariables({
    type: 'object',
    properties: {
      pagination: paginationSchema,
      orderBy: {
        ...orderBySchema,
        default: {
          field: AdPlacementOrderField.NAME,
          direction: OrderDirection.DESC,
        },
      },
    },
    filterBy: {
      type: 'object',
      properties: {
        q: { type: 'string' },
      },
    },
  })

  const {
    data,
    error,
    variables,
    refetch,
    networkStatus,
  } = usePlacementListQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const filterFields: any = []
  if (variables.filterBy && variables.filterBy.q) {
    filterFields.push(createGlobalQueryField(variables.filterBy.q))
  }

  return (
    <>
      <fieldset className={classes.fieldset}>
        <legend>설명</legend>
        <p>새로운 광고 지면을 추가하시려면 개발 담당자와 상의해 주세요.</p>
        <p>지면이 사이트에 노출되는 조건</p>
        <ol>
          <li>
            지면이 활성화 되어있을때(지면이 활성화 되어있지 않으면, 해당 지면에
            대해 진행중인 캠페인이 있더라도 사이트에 노출되지 않습니다.)
          </li>
          <li>
            하우스 배너 설정 되어 있을때(하우스 배너 랜딩 URL과 이미지 중
            하나라도 설정되어 있지 않으면 노출되지 않습니다.)
          </li>
        </ol>
      </fieldset>
      <Table<PlacementList_AdPlacementFragment>
        title="광고지면"
        hasCreate
        columns={[
          {
            Header: 'ID',
            minWidth: 40,
            maxWidth: 40,
            align: 'center',
            accessor: 'id' as const,
          },
          {
            Header: '지면',
            accessor: 'name' as const,
            sortField: AdPlacementOrderField.NAME,
          },
          {
            Header: '사이즈',
            align: 'center',
            accessor: ({ width, height }) => {
              return `${width} x ${height}`
            },
          },
          {
            Header: '활성여부',
            maxWidth: 40,
            align: 'center',
            accessor: 'isActive' as const,
            Cell: BooleanCell,
          },
          {
            Header: '하우스 배너 설정 여부',
            align: 'center',
            accessor: ({ defaultImage, defaultLandingURL }) => {
              const isHouseBannerActive = defaultImage && defaultLandingURL
              return isHouseBannerActive
            },
            Cell: BooleanCell,
          },
        ]}
        data={get(data, 'adPlacements.nodes', [])}
        pagination={queryVariables.pagination}
        orderBy={queryVariables.orderBy}
        pageCount={get(data, 'adPlacements.totalCount', 0)}
        filterFields={filterFields}
        onStateChange={({ pagination, orderBy, filterFields = [] }) => {
          const filterBy: PlacementListQueryVariables['filterBy'] = {}
          filterFields.forEach(filter => {
            if (filter.filterType === 'global-query' && filter.value) {
              filterBy.q = filter.value
            }
          })

          const nextVar = {
            pagination,
            orderBy,
            ...(isEmpty(filterBy) ? {} : { filterBy }),
          }

          refetch(nextVar)
          updateQueryString(nextVar)
        }}
        loading={networkStatus === NetworkStatus.setVariables}
        onRowClick={row => {
          history.push(`/advertisement/placements/${row.values.id}`)
        }}
      />
    </>
  )
}

export default PlacementList

gql`
  fragment PlacementList_adPlacement on AdPlacement {
    id
    name
    isActive
    width
    height
    defaultImage {
      id
    }
    defaultLandingURL
  }

  query PlacementList(
    $filterBy: AdPlacementFilters
    $orderBy: AdPlacementOrder
    $pagination: Pagination
  ) {
    adPlacements(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      nodes {
        ...PlacementList_adPlacement
      }
      totalCount
    }
  }
`
