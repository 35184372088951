import gql from 'graphql-tag'
import {
  RecommendKeywordGetDocument,
  RecommendKeywordGetListDocument,
  RecommendKeywordCreateDocument,
  RecommendKeywordUpdateDocument,
  RecommendKeywordGetListQueryVariables,
  RecommendKeywordCreateMutationVariables,
  RecommendKeywordUpdateMutationVariables,
  RecommendSearchKeywordType,
} from 'generated/graphql'
import isString from 'lodash/isString'
import { QueryBuilder } from './base.query'

export function prepareInput(input: any) {
  if (isString(input.seq)) {
    input.seq = parseInt(input.seq, 10)
  }

  return input
}

export const queryBuilder: QueryBuilder = {
  getList: {
    query: RecommendKeywordGetListDocument,
    variables(): RecommendKeywordGetListQueryVariables {
      return {
        type: RecommendSearchKeywordType.ACTIVITY,
      }
    },
  },
  getOne: {
    query: RecommendKeywordGetDocument,
  },
  create: {
    query: RecommendKeywordCreateDocument,
    variables(args): RecommendKeywordCreateMutationVariables {
      return {
        input: {
          type: RecommendSearchKeywordType.ACTIVITY,
          ...args,
        },
      }
    },
  },
  update: {
    query: RecommendKeywordUpdateDocument,
    variables(id, args): RecommendKeywordUpdateMutationVariables {
      return {
        id,
        input: {
          type: RecommendSearchKeywordType.ACTIVITY,
          ...args,
        },
      }
    },
  },

  prepareInput,
}

gql`
  fragment RecommendKeywordFragment on RecommendSearchKeyword {
    id
    title
    seq
    searchField
  }
`

gql`
  query RecommendKeywordGetList($type: RecommendSearchKeywordType) {
    data: recommendSearchKeywordList(type: $type) {
      data: recommendSearchKeywords {
        ...RecommendKeywordFragment
      }
      total: totalCount
    }
  }
`

gql`
  query RecommendKeywordGet($id: ID!) {
    data: recommendSearchKeyword(id: $id) {
      ...RecommendKeywordFragment
    }
  }
`

gql`
  mutation RecommendKeywordCreate($input: RecommendSearchKeywordCreateInput!) {
    data: recommendSearchKeywordCreate(input: $input) {
      data: recommendSearchKeyword {
        ...RecommendKeywordFragment
      }
    }
  }
`

gql`
  mutation RecommendKeywordUpdate(
    $id: ID!
    $input: RecommendSearchKeywordUpdateInput!
  ) {
    data: recommendSearchKeywordUpdate(id: $id, input: $input) {
      data: recommendSearchKeyword {
        ...RecommendKeywordFragment
      }
    }
  }
`
