import React, {
  useMemo,
  RefForwardingComponent,
  forwardRef,
  useImperativeHandle,
} from 'react'
import gql from 'graphql-tag'
import { useCampaignReport_CampaignStatisticQuery } from 'generated/graphql'
import Loading from 'components/Loading'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import orderBy from 'lodash/orderBy'
import { format } from 'date-fns'
import CampaignReportChart from './CampaignReportChart'
import CampaignReportTable from './CampaignReportTable'

export enum ReportViewType {
  TABLE,
  CHART,
}

export function calculateCTR({
  impressionCount,
  clickCount,
}: {
  impressionCount: number
  clickCount: number
}) {
  return (clickCount * 100) / impressionCount
}

export interface CampaignStatistic {
  date: Date
  adPlacementID: string
  adPlacementName: string
  impressionCount: number
  clickCount: number
  ctr: number
}

export interface CampaignStatisticsByDate {
  statisticID
  date: Date
  totalImpressionCount: number
  totalClickCount: number
  campaignStatistics: CampaignStatistic[]
}

export type CampaignStatisticsByDateMap = Map<Date, CampaignStatisticsByDate>

interface BuildStatisticDataProps {
  header?: string
  adPlacementName?: string
  impressionCount: number
  clickCount: number
  ctr: string
}

function buildStatisticsCSVData({
  header,
  adPlacementName,
  impressionCount,
  clickCount,
  ctr,
}: BuildStatisticDataProps) {
  return {
    ...(header && {
      날짜: header,
    }),
    ...(adPlacementName && {
      지면이름: adPlacementName,
    }),
    노출: impressionCount,
    클릭: clickCount,
    CTR: ctr,
  }
}

export interface CampaignReportMethod {
  refetch(): any
  generateReport(): CampaignStatisticsByDate[]
}

interface CampaignReportProps {
  campaignID: string
  adPlacementIDs: string[]
  viewType?: ReportViewType
}

const CampaignReport: RefForwardingComponent<
  CampaignReportMethod,
  CampaignReportProps
> = ({ campaignID, adPlacementIDs, viewType = ReportViewType.TABLE }, ref) => {
  const { enqueueApolloError } = useErrorSnackbar()
  const { data, loading, refetch } = useCampaignReport_CampaignStatisticQuery({
    variables: {
      filterBy: {
        campaignID,
        adPlacementIDs,
      },
    },
    onError: enqueueApolloError,
  })

  const totalImpressionCount =
    data?.campaignStatistics?.totalImpressionCount ?? 0
  const totalClickCount = data?.campaignStatistics?.totalClickCount ?? 0
  const campaignStatistics = data?.campaignStatistics?.nodes || []

  const statisticsByDateMap = useMemo(
    () =>
      campaignStatistics.reduce<CampaignStatisticsByDateMap>(
        (statisticsMap, statistic) => {
          const {
            id,
            date,
            adPlacement,
            impressionCount,
            clickCount,
            ctr,
          } = statistic
          const mapKey = date

          if (!statisticsMap.has(mapKey)) {
            statisticsMap.set(mapKey, {
              statisticID: id,
              date: mapKey,
              totalImpressionCount: 0,
              totalClickCount: 0,
              campaignStatistics: [],
            })
          }

          const statisticsByDate = statisticsMap.get(mapKey)
          const totalImpressionCount =
            statisticsByDate?.totalImpressionCount ?? 0
          const totalClickCount = statisticsByDate?.totalClickCount ?? 0

          statisticsByDate!.totalImpressionCount =
            totalImpressionCount + impressionCount
          statisticsByDate!.totalClickCount = totalClickCount + clickCount
          statisticsByDate?.campaignStatistics.push({
            date: date,
            adPlacementID: adPlacement?.id || '',
            adPlacementName: adPlacement?.name || '',
            impressionCount,
            clickCount,
            ctr,
          })

          return statisticsMap
        },
        new Map(),
      ),
    [campaignStatistics],
  )

  const campaignStatisticsByDateArray = Array.from(statisticsByDateMap.values())

  const orderedDataByDateArray = useMemo(
    () => orderBy(campaignStatisticsByDateArray, 'date', 'desc'),
    [campaignStatisticsByDateArray],
  )

  useImperativeHandle(ref, () => ({
    refetch: () => {
      refetch()
    },
    generateReport: () => {
      const csvData = orderedDataByDateArray.reduce<any>((csvData, row) => {
        const {
          campaignStatistics,
          date,
          totalImpressionCount: totalImpressionCountOfRow,
          totalClickCount: totalClickCountOfRow,
        } = row
        const totalCTROfRow = calculateCTR({
          impressionCount: totalImpressionCountOfRow,
          clickCount: totalClickCountOfRow,
        })


        const csvMainData = campaignStatistics.reduce<any>(
          (csvMainData, statisticData, index) => {
            const {
              adPlacementName,
              impressionCount,
              clickCount,
              ctr,
            } = statisticData

            if (index === 0) {
              csvMainData.push(
                buildStatisticsCSVData({
                  header: format(date, 'yyyy년 MM월 dd일'),
                  adPlacementName,
                  impressionCount,
                  clickCount,
                  ctr: ctr.toString(),
                }),
              )

              return csvMainData
            }

            csvMainData.push(
              buildStatisticsCSVData({
                adPlacementName,
                impressionCount,
                clickCount,
                ctr: ctr.toString(),
              }),
            )

            return csvMainData
          },
          [],
        )

        csvData.push(
          ...csvMainData,
          buildStatisticsCSVData({
            header: '총 합',
            impressionCount: totalImpressionCountOfRow,
            clickCount: totalClickCountOfRow,
            ctr: totalCTROfRow.toFixed(3),
          }),
        )
        return csvData
      }, [])

      const totalCTR = calculateCTR({
        impressionCount: totalImpressionCount,
        clickCount: totalClickCount,
      })

      csvData.push(
        buildStatisticsCSVData({
          header: 'total',
          impressionCount: totalImpressionCount,
          clickCount: totalClickCount,
          ctr: totalCTR.toFixed(3),
        }),
      )

      return csvData
    },
  }))

  if (!data || loading) {
    return <Loading />
  }

  return (
    <>
      {viewType === ReportViewType.TABLE ? (
        <CampaignReportTable
          totalImpressionCount={totalImpressionCount}
          totalClickCount={totalClickCount}
          campaignStatisticsByDateArray={orderedDataByDateArray}
        />
      ) : (
        <CampaignReportChart
          campaignStatisticsByDateArray={orderedDataByDateArray}
        />
      )}
    </>
  )
}

export default forwardRef(CampaignReport)

gql`
  query CampaignReport_CampaignStatistic($filterBy: CampaignStatisticFilters) {
    campaignStatistics(filterBy: $filterBy) {
      totalImpressionCount
      totalClickCount
      nodes {
        id
        date
        adPlacement {
          id
          name
        }
        impressionCount
        clickCount
        ctr
      }
    }
  }
`
