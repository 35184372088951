import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { toggleSidebar, LoadingIndicator, UserMenu, Logout } from 'react-admin'
import { useDispatch } from 'react-redux'
import {
  IconButton,
  Typography,
  makeStyles,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { LINKAREER_DOMAIN } from 'config'

const useStyles = makeStyles(theme => ({
  headerWrap: {
    zIndex: 1300,
  },
  mainTitle: {
    margin: 0,
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  spacer: {
    flex: 1,
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
}))

interface CustomAppBarProps {
  style: any
}
const CustomAppBar: FunctionComponent<CustomAppBarProps> = ({ ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <AppBar
      {...props}
      className={classes.headerWrap}
      color="secondary"
      // variant="dense"
    >
      <Toolbar variant="dense" disableGutters>
        <IconButton
          color="inherit"
          onClick={() => dispatch(toggleSidebar())}
          className={clsx(classes.menuButton)}
        >
          <MenuIcon
            classes={{
              root: open
                ? classes.menuButtonIconOpen
                : classes.menuButtonIconClosed,
            }}
          />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <h3 className={classes.mainTitle}>링커리어 ({LINKAREER_DOMAIN})</h3>
        <span className={classes.spacer} />
        <LoadingIndicator />
        <UserMenu logout={<Logout />} />
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppBar
