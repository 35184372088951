import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CampaignStatisticsByDate } from '../CampaignReport'
import CampaignReportTableHeader from './CampaignReportTableHeader'
import CampaignReportTableRow from './CampaignReportTableRow'
import CampaignReportTableFooter from './CampaignReportTableFooter'

const useStyles = makeStyles({
  table: {
    width: '100%',
    border: '1px solid #8c8c8c',
    borderCollapse: 'collapse',

    '& td, th': {
      border: '1px solid #8c8c8c',
    },
  },
})

interface CampaignReportTableProps {
  totalImpressionCount: number
  totalClickCount: number
  campaignStatisticsByDateArray: CampaignStatisticsByDate[]
}

const CampaignReportTable: FC<CampaignReportTableProps> = ({
  totalImpressionCount,
  totalClickCount,
  campaignStatisticsByDateArray,
}) => {
  const classes = useStyles()

  return (
    <table className={classes.table}>
      <CampaignReportTableHeader />
      <tbody>
        {campaignStatisticsByDateArray.map(campaignStatisticsByDate => (
          <CampaignReportTableRow
            key={campaignStatisticsByDate.date.toString()}
            data={campaignStatisticsByDate}
          />
        ))}
      </tbody>
      <CampaignReportTableFooter
        totalImpressionCount={totalImpressionCount}
        totalClickCount={totalClickCount}
      />
    </table>
  )
}

export default CampaignReportTable
