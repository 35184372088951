import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { FilterField } from './fieldType'

interface StringFormProps {
  value?: string
}

export type StringFilterType = 'string-match'

export const StringForm: React.FC<StringFormProps> = ({ value }) => {
  const { control } = useFormContext()

  return (
    <Controller
      as={TextField}
      control={control}
      name="value"
      label="포함 하는 문자"
      placeholder="값"
      defaultValue={value || ''}
      variant="outlined"
      color="secondary"
    />
  )
}

export const renderStringValue = (filterField: FilterField) => {
  return `${filterField.label}에 '${filterField.value}'가 포함된 데이터`
}
