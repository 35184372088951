export * from './base.query'
import { queryBuilder as signupSessions } from './signup-sessions.query'
import { queryBuilder as users } from './users.query'
import { queryBuilder as activities } from './activities.query'
import { queryBuilder as curation } from './curation.query'
import { queryBuilder as recommendKeyword } from './recommend-keyword.query'
import { queryBuilder as signups } from './signups.query'
import { queryBuilder as coverLetters } from './cover-letters.query'
import { queryBuilder as coverLetterRecommendKeyword } from './cover-letter-recommend-keyword.query'
import { queryBuilder as coverLetterSearchExact } from './cover-letter-exact-search.query'
import { queryBuilder as advertisement } from './advertisement.query'
import { queryBuilder as replies } from './replies.query'

export default {
  'signup-sessions': signupSessions,
  users,
  activities,
  replies,
  'search-page.curation': curation,
  'search-page.recommend-keyword': recommendKeyword,
  signups,
  'cover-letters': coverLetters,
  'cover-letters.recommend-keyword': coverLetterRecommendKeyword,
  'cover-letters.update-field': coverLetterSearchExact,
  advertisement,
}
