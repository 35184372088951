import React, { FC, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import gql from 'graphql-tag'
import format from 'date-fns/format'
import { useForm, FormProvider } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import {
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AdType,
  useAdvertisementForm_AdvertisementQuery,
  useAdvertisementForm_CreateMutation,
  useAdvertisementForm_UpdateMutation,
  useAdvertisementForm_DeleteMutation,
} from 'generated/graphql'

import DeleteDialog from 'components/DeleteDialog'
import ImageField, { ImageFieldHandle } from './ImageField'
import ActivityIDField from './ActivityIDField'
import useAttachmentFileUpload, {
  ADVERTISEMENT,
} from 'hooks/useAttachmentFileUpload'
import { AdLimitByType, TitleByType, AdvertisementFormData } from '../types'
import { AWS_S3_BUCKET } from 'config'
import { urlRegexIncludingProtocol } from 'utils'

const useStyles = makeStyles({
  root: {
    margin: '20px 0',
    maxHeight: '100vh',
    height: '100%',
    padding: 50,
  },
  divider: {
    margin: '30px 0',
  },
  formWrapper: {
    width: '70%',
  },
  inlineInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inlineInput: {
    flexGrow: 1,
  },
  inputXSpacing: {
    marginRight: 10,
  },
  inputYSpacing: {
    marginBottom: 50,
  },
  outLink: {
    '& p': {
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const getDateString = (date?: Date | null) => {
  return format(date || new Date(), "yyyy-MM-dd'T'HH:00")
}

const getImageURL = (attachmentID: string) =>
  `https://s3.ap-northeast-2.amazonaws.com/${AWS_S3_BUCKET}/${ADVERTISEMENT}/${attachmentID}`

interface AdvertisementFormProps {
  match: any
  history: any
}

const AdvertisementForm: FC<AdvertisementFormProps> = ({ match, history }) => {
  const { id, adType: adTypeSlug } = match.params
  const adType = (adTypeSlug as string).toUpperCase() as AdType
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<AdvertisementFormData>()
  const { register, handleSubmit, watch, errors } = methods

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const pcImageRef = useRef<ImageFieldHandle>(null)
  const mobileImageRef = useRef<ImageFieldHandle>(null)

  const { fileUpload } = useAttachmentFileUpload()

  const [updateAdvertisement] = useAdvertisementForm_UpdateMutation()
  const [createAdvertisement] = useAdvertisementForm_CreateMutation()
  const [deleteAdvertisement] = useAdvertisementForm_DeleteMutation()

  const { data, loading, error } = useAdvertisementForm_AdvertisementQuery({
    variables: { id: id || '' },
  })

  if (loading || error) return null

  const {
    title,
    activityID,
    outLink,
    startAt,
    endAt,
    seq,
    pcImage: pcImageFromData,
    mobileImage: mobileImageFromData,
  } = data?.advertisement || {}
  const limit = AdLimitByType[adType]

  const handleDelete = async () => {
    await deleteAdvertisement({ variables: { id } })
    history.push(`/advertisement/${adTypeSlug}`)
  }

  const onSubmit = async (data: AdvertisementFormData) => {
    const {
      seq,
      activityID,
      title,
      outLink,
      startAt,
      endAt,
      pcImage: pcImageFiles,
      mobileImage: mobileImageFiles,
    } = data

    const pcImageValue = pcImageFiles?.[0]
    const mobileImageValue = mobileImageFiles?.[0]

    let pcImageURL
    let mobileImageURL

    if (
      adType !== AdType.LIVE_ACTIVITY_INFO &&
      (pcImageRef.current?.isError() || mobileImageRef.current?.isError())
    )
      return

    if (pcImageValue) {
      const pcImageAttachment = await fileUpload(pcImageValue)
      pcImageURL = getImageURL(pcImageAttachment?.id ?? '')
    }

    if (mobileImageValue) {
      const mobileImageAttachment = await fileUpload(mobileImageValue)
      mobileImageURL = getImageURL(mobileImageAttachment?.id ?? '')
    }

    const advertisementInput = {
      activityID,
      adType,
      outLink,
      title,
      ...(pcImageURL && { pcImage: pcImageURL }),
      ...(mobileImageURL && { mobileImage: mobileImageURL }),
      seq: Number(seq),
      startAt: new Date(startAt),
      endAt: new Date(endAt),
    }

    try {
      if (id) {
        await updateAdvertisement({
          variables: { input: { id, ...advertisementInput } },
        })
      } else {
        await createAdvertisement({ variables: { input: advertisementInput } })
      }

      history.push(`/advertisement/${adTypeSlug}`)
    } catch (error) {
      enqueueSnackbar(
        `다른 광고와 순서와 기간이 겹칩니다. 진행 또는 진행 예정 광고를 확인해주세요.`,
        { variant: 'error' },
      )
    }
  }

  const startAtValue = +new Date(watch('startAt'))
  const endAtValue = +new Date(watch('endAt'))

  return (
    <Paper className={classes.root}>
      <div className={classes.formWrapper}>
        <Typography variant="h5">{`광고 관리 > ${TitleByType[adType]} > 소재 관리`}</Typography>
        <Divider className={classes.divider} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div
              className={clsx(
                classes.inlineInputWrapper,
                classes.inputYSpacing,
              )}
            >
              <TextField
                label="순서"
                variant="outlined"
                name="seq"
                type="number"
                required
                defaultValue={seq}
                inputRef={register({ min: 1, max: limit })}
                error={!!errors.seq}
                helperText={`1부터 ${limit}까지 입력가능합니다.`}
                className={clsx(classes.inlineInput, classes.inputXSpacing)}
              />

              <ActivityIDField
                adType={adType}
                className={classes.inlineInput}
                activityID={activityID || undefined}
              />
            </div>
            <TextField
              name="title"
              inputRef={register({ maxLength: 50 })}
              label="공고 제목"
              variant="outlined"
              defaultValue={title}
              fullWidth
              error={!!errors.title}
              {...(errors.title && {
                helperText: '제목은 50자 이내로 적어주세요.',
              })}
              className={classes.inputYSpacing}
            />
            <TextField
              name="outLink"
              inputRef={register({ pattern: urlRegexIncludingProtocol })}
              label="외부 랜딩(URL)"
              variant="outlined"
              defaultValue={outLink}
              fullWidth
              error={!!errors.outLink}
              className={clsx(classes.inputYSpacing, classes.outLink)}
              {...(outLink && {
                helperText: errors.outLink
                  ? `http 또는 https로 시작하는 링크를 입력해주세요.`
                  : `현재: ${outLink || ''}`,
              })}
            />
            {adType !== AdType.LIVE_ACTIVITY_INFO && (
              <div
                className={clsx(
                  classes.inlineInputWrapper,
                  classes.inputYSpacing,
                )}
              >
                <ImageField
                  className={classes.inputXSpacing}
                  imageURLFromData={pcImageFromData || undefined}
                  adType={adType}
                  fieldName="pcImage"
                  ref={pcImageRef}
                />
                <ImageField
                  imageURLFromData={mobileImageFromData || undefined}
                  adType={adType}
                  fieldName="mobileImage"
                  ref={mobileImageRef}
                />
              </div>
            )}

            <div
              className={clsx(
                classes.inlineInputWrapper,
                classes.inputYSpacing,
              )}
            >
              <TextField
                name="startAt"
                inputRef={register}
                label="광고 시작일"
                type="datetime-local"
                variant="outlined"
                defaultValue={getDateString(startAt)}
                className={clsx(classes.inlineInput, classes.inputXSpacing)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name="endAt"
                inputRef={register}
                label="광고 종료일"
                type="datetime-local"
                variant="outlined"
                defaultValue={getDateString(endAt)}
                className={classes.inlineInput}
                error={endAtValue < startAtValue}
                helperText="광고 시작일 이후의 날짜만 선택 가능합니다."
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className={classes.buttonsWrapper}>
              {id && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => setDeleteDialogOpen(true)}
                  >
                    삭제
                  </Button>
                  <DeleteDialog
                    open={deleteDialogOpen}
                    title="광고 삭제"
                    description="정말 이 광고를 삭제하시겠습니까?"
                    onClose={() => setDeleteDialogOpen(false)}
                    cancleOnClick={() => setDeleteDialogOpen(false)}
                    deleteOnClick={handleDelete}
                  />
                </>
              )}

              <Button type="submit" color="secondary" variant="contained">
                저장
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Paper>
  )
}

export default withRouter(AdvertisementForm)

gql`
  fragment AdvertisementForm_advertisement on Advertisement {
    id
    activityID
    title
    pcImage
    mobileImage
    outLink
    startAt
    endAt
    seq
  }

  query AdvertisementForm_advertisement($id: ID!) {
    advertisement(id: $id) {
      ...AdvertisementForm_advertisement
    }
  }

  mutation AdvertisementForm_update($input: AdvertisementUpdateInput!) {
    advertisementUpdate(input: $input) {
      advertisement {
        ...AdvertisementForm_advertisement
      }
    }
  }

  mutation AdvertisementForm_create($input: AdvertisementCreateInput!) {
    advertisementCreate(input: $input) {
      advertisement {
        ...AdvertisementForm_advertisement
      }
    }
  }

  mutation AdvertisementForm_delete($id: ID!) {
    advertisementDelete(id: $id) {
      advertisement {
        ...AdvertisementForm_advertisement
      }
    }
  }
`
