import React, { FC } from 'react'
import Chip from '@material-ui/core/Chip'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { CoverLetter } from 'generated/graphql'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    minWidth: 150,
  },
  chip: { margin: 4 },
})

interface FieldProps<T> {
  addLabel?: boolean
  label?: string
  record?: T
  source?: string
  sortable?: boolean
}

const CoverLetterTypesField: FC<FieldProps<CoverLetter>> = ({ record }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return record ? (
    <span className={classes.main}>
      {record.types &&
        record.types.map(type => {
          return type ? (
            <Chip
              key={type}
              className={classes.chip}
              label={translate(`enum.CoverLetterType.${type}`)}
            />
          ) : null
        })}
    </span>
  ) : null
}

CoverLetterTypesField.defaultProps = {
  addLabel: true,
  sortable: false,
  source: 'types',
}

export default CoverLetterTypesField
