import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  Create,
  SimpleForm,
  TextInput,
  number,
  maxLength,
  minValue,
  maxValue,
  Toolbar,
  SaveButton,
} from 'react-admin'

import { mapStateToProps } from './RecommendKeywordList'

export const RecommendKeywordCreateToolbar: FunctionComponent = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

export interface RecommendKeywordCreateProps {
  location: any
  match?: any
  options?: any
}

const RecommendKeywordCreate: FunctionComponent<RecommendKeywordCreateProps> = props => {
  return (
    <Create
      title="resources.search-page.recommend-keyword.create"
      id={props.match.params.id}
      {...props}
    >
      <SimpleForm toolbar={<RecommendKeywordCreateToolbar {...props} />}>
        <TextInput
          source="title"
          validate={maxLength(10, '최대 10자까지 입력할 수 있습니다.')}
        />
        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 3까지 입력가능합니다.'),
            maxValue(3, '1부터 3까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

export default connect(mapStateToProps)(RecommendKeywordCreate)
