import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { Form, Field, useFormikContext } from 'formik'

interface FormDialogProps extends DialogProps {
  onClose: () => void
  message: string
  fieldName: string
  formLabel?: string | null
  formType?: string | null
  confirmLabel?: string | null
}

const FormDialog: FC<FormDialogProps> = ({
  onClose,
  message,
  fieldName,
  formLabel,
  formType,
  confirmLabel,
  ...props
}) => {
  const formik = useFormikContext()
  const { isSubmitting, setFieldValue } = formik
  const handleChange = event => {
    setFieldValue(fieldName as never, event.target.value)
  }

  return (
    <Form>
      <Dialog aria-labelledby="form-dialog-title" {...props}>
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>

          <Field
            autoFocus
            fullWidth
            id="formdialog-field-id"
            name={fieldName}
            onKeyUp={handleChange}
            labelRequiredVariant={formType || 'text'}
            component={TextField}
            label={formLabel}
            disabled={isSubmitting}
          />
          <DialogActions>
            <Button color="secondary" type="submit">
              {confirmLabel || '확인'}
            </Button>
            <Button onClick={onClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Form>
  )
}

export default FormDialog
