import React from 'react'
import { Route } from 'react-router-dom'
import {
  ActivityActivityList,
  ActivityClubList,
  ActivityContestList,
  ActivityRecruitList,
  ActivityEducationList,
} from 'resources/activities'

import { AdvertisementForm, AdvertisementList } from 'resources/advertisement'

import {
  CurationCreate,
  CurationList,
  CurationEdit,
  CurationShow,
  RecommendKeywordList,
  RecommendKeywordEdit,
  RecommendKeywordCreate,
} from 'resources/search-page'

import {
  CoverLetterRecommendKeywordList,
  CoverLetterRecommendKeywordEdit,
  CoverLetterRecommendKeywordCreate,
  CoverLetterOrganizationNameList,
} from 'resources/cover-letters'

import { LinkareerMagazine } from 'resources/linkareer-magazine'
import { MediaIntroduction } from 'resources/media-introduction'

import customRoutes from './pages/customRoutes'

export default [
  ...customRoutes,
  <Route
    exact
    key="/activities/activity"
    path="/activities/activity"
    component={ActivityActivityList}
  />,
  <Route
    exact
    key="/activities/club"
    path="/activities/club"
    component={ActivityClubList}
  />,
  <Route
    exact
    key="/activities/contest"
    path="/activities/contest"
    component={ActivityContestList}
  />,
  <Route
    exact
    key="/activities/recruit"
    path="/activities/recruit"
    component={ActivityRecruitList}
  />,
  <Route
    exact
    key="/activities/education"
    path="/activities/education"
    component={ActivityEducationList}
  />,
  <Route
    exact
    key="/search-page/recommend-keyword"
    path="/search-page/recommend-keyword/create"
    component={RecommendKeywordCreate}
  />,
  <Route
    exact
    key="/search-page/recommend-keyword"
    path="/search-page/recommend-keyword"
    component={RecommendKeywordList}
  />,
  <Route
    exact
    key="/search-page/recommend-keyword"
    path="/search-page/recommend-keyword/:id"
    component={RecommendKeywordEdit}
  />,
  <Route
    exact
    key="/search-page/curation"
    path="/search-page/curation/create"
    component={CurationCreate}
  />,
  <Route
    exact
    key="/search-page/curation"
    path="/search-page/curation"
    component={CurationList}
  />,
  <Route
    exact
    key="/search-page/curation"
    path="/search-page/curation/:id"
    component={CurationEdit}
  />,
  <Route
    exact
    key="/search-page/curation"
    path="/search-page/curation/:id/show"
    component={CurationShow}
  />,
  <Route
    exact
    key="/cover-letters/recommend-keyword"
    path="/cover-letters/recommend-keyword/create"
    component={CoverLetterRecommendKeywordCreate}
  />,
  <Route
    exact
    key="/cover-letters/recommend-keyword"
    path="/cover-letters/recommend-keyword"
    component={CoverLetterRecommendKeywordList}
  />,
  <Route
    exact
    key="/cover-letters/recommend-keyword"
    path="/cover-letters/recommend-keyword/:id"
    component={CoverLetterRecommendKeywordEdit}
  />,
  <Route
    exact
    key="/cover-letters/update-field"
    path="/cover-letters/update-field"
    component={CoverLetterOrganizationNameList}
  />,
  // create component has to be above list component due to url path
  <Route
    exact
    key="advertisement"
    path={`/advertisement/:adType/create`}
    component={AdvertisementForm}
  />,
  <Route
    exact
    key="advertisement"
    path={`/advertisement/:adType`}
    component={AdvertisementList}
  />,
  <Route
    exact
    key="advertisement"
    path={`/advertisement/:adType/:id`}
    component={AdvertisementForm}
  />,
  <Route
    exact
    key="linkareer-magazine"
    path="/linkareer-magazine"
    component={LinkareerMagazine}
  />,
  <Route
    exact
    key="media-introduction"
    path="/media-introduction"
    component={MediaIntroduction}
  />,
]
