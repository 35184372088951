import { defaultProps } from 'recompose'
import { UserType } from 'generated/graphql'
import EnumCell from './EnumCell'

const userTypeCellMap: {
  [key in UserType]: string
} = {
  NORMAL: '일반 회원',
  MANAGER: '대외활동 담당자',
  WRITER: '읽을거리 페이지 작가',
  MANAGER_VERIFIED: '기업 회원 (사업자 번호 O)',
  MANAGER_UNVERIFIED: '기업 회원 (사업자 번호 X)',
  MANAGER_REJECTED: '기업 회원 (블랙리스트)',
}

export default defaultProps({
  enumCellMap: userTypeCellMap,
})(EnumCell)
