import { AdType } from 'generated/graphql'

// 수정 시, api-server의 advertisement.service.ts의 AdLimitByType도 함께 수정
export const AdLimitByType = {
  [AdType.HOTLINE_BANNER]: 60,
  [AdType.RECOMMEND_S_BANNER]: 1,
  [AdType.RECOMMEND_A_BANNER]: 6,
  [AdType.POPULAR_ACTIVITY]: 10,
  [AdType.LIVE_POPULAR_RECRUIT]: 4,
  [AdType.LIVE_ACTIVITY_INFO]: 8,
}

export const TitleByType = {
  [AdType.HOTLINE_BANNER]: '핫라인 배너',
  [AdType.RECOMMEND_S_BANNER]: '추천활동 S배너',
  [AdType.RECOMMEND_A_BANNER]: '추천활동 A배너',
  [AdType.POPULAR_ACTIVITY]: '금주의 인기활동',
  [AdType.LIVE_POPULAR_RECRUIT]: '채용 배너',
  [AdType.LIVE_ACTIVITY_INFO]: '실시간 활동정보',
}

export const ImageSizeByType = {
  [AdType.HOTLINE_BANNER]: {
    PC: '2217*360',
    MO: '990*240',
  },
  [AdType.RECOMMEND_S_BANNER]: {
    PC: '1581*1080',
    MO: '1080*633',
  },
  [AdType.RECOMMEND_A_BANNER]: {
    PC: '750*750',
    MO: '1080*633',
  },
  [AdType.POPULAR_ACTIVITY]: {
    PC: '465*465',
    MO: '465*465',
  },
  [AdType.LIVE_POPULAR_RECRUIT]: {
    PC: '750*750',
    MO: '465*465',
  },
}

export interface AdvertisementFormData {
  seq: number
  activityID: string
  pcImage: any[]
  mobileImage: any[]
  title: string
  outLink: string
  startAt: Date
  endAt: Date
}
