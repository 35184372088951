import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react'
import gql from 'graphql-tag'
import {
  useAdvertisementInput_PlacementListQuery,
  AdvertisementInput_AdPlacementFragment,
} from 'generated/graphql'
import { NetworkStatus } from '@apollo/client'
import Loading from 'components/Loading'
import Error from 'components/Error'
import {
  Typography,
  TextField,
  Theme,
  Grid,
  Button,
  Paper,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import differenceBy from 'lodash/differenceBy'
import ImageInput from 'components/ImageInput'
import { getImageURL } from 'utils/fileUpload'
import { ADVERTISEMENT } from 'hooks/useAttachmentFileUpload'
import { buildIndex } from 'utils'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.8rem',
  },
  autocomplete: {
    display: 'inline-block',
    marginBottom: 10,
  },
  searchInput: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  optionPlacementName: {
    flexGrow: 1,
  },
  selectedOptionIcon: {
    margin: '0 5px 0 -5px',
  },
  placementHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  placementCard: {
    padding: 10,
  },
}))

interface Advertisement {
  id?: string
  adPlacementID: string
  imageID?: string
  imageFiles?: FileList
}

export interface AdvertisementInputFormData {
  upsertAds: Advertisement[]
  deleteAds: Advertisement[]
}

interface AdvertisementInputProps {
  label?: string
  defaultValue?: Advertisement[]
  onChange?(ads: AdvertisementInputFormData): void
}

const AdvertisementInput: FC<AdvertisementInputProps> = ({
  label,
  defaultValue: defaultAds = [],
  onChange,
}) => {
  const classes = useStyles()

  const [upsertAndDeleteAds, setUpsertAndDeleteAds] = useState<
    AdvertisementInputFormData
  >(() => ({
    upsertAds: [...defaultAds],
    deleteAds: [],
  }))

  const {
    data,
    error,
    networkStatus,
  } = useAdvertisementInput_PlacementListQuery()

  const originalAdIndex = useMemo(
    () => buildIndex(defaultAds, value => value.adPlacementID),
    [defaultAds],
  )

  const placements = data?.adPlacements.nodes ?? []

  const placementIndex = useMemo(
    () => buildIndex(placements, placement => placement.adPlacementID),
    [placements],
  )

  const selectedPlacements = upsertAndDeleteAds.upsertAds.reduce<
    AdvertisementInput_AdPlacementFragment[]
  >((placements, ad) => {
    const placement = placementIndex.get(ad.adPlacementID)

    if (!placement) {
      return placements
    }

    placements.push(placement)
    return placements
  }, [])

  const addPlacement = (adPlacementID: string) => {
    if (!adPlacementID) return

    setUpsertAndDeleteAds(prev => ({
      ...prev,
      upsertAds: [
        ...prev.upsertAds,
        {
          adPlacementID,
        },
      ],
    }))
  }

  const removePlacement = (adPlacementID: string) => {
    if (!adPlacementID) return

    setUpsertAndDeleteAds(prev => {
      const upsertAds = prev.upsertAds.filter(
        prevAd => prevAd.adPlacementID !== adPlacementID,
      )
      const deletedAd = prev.upsertAds.filter(
        prevAd => prevAd.adPlacementID === adPlacementID,
      )[0]
      const originalAd = originalAdIndex.get(adPlacementID)

      const deleteAds = [...prev.deleteAds]

      if (originalAd && deletedAd.id) {
        deleteAds.push(originalAd)
      }

      return {
        upsertAds,
        deleteAds,
      }
    })
  }

  const buildUpdatePlacementImageURL = (adPlacementID: string) => {
    return (imageFiles?: FileList) => {
      setUpsertAndDeleteAds(prev => ({
        ...prev,
        upsertAds: prev.upsertAds.map(prevAd => {
          if (prevAd.adPlacementID !== adPlacementID) {
            return prevAd
          }

          return {
            ...(prevAd.id && { id: prevAd.id }),
            ...(prevAd.imageID && { imageID: prevAd.imageID }),
            adPlacementID,
            imageFiles,
          }
        }),
      }))
    }
  }

  const handleAutocompleteChange = (
    e: ChangeEvent<{}>,
    selectedPlacements: AdvertisementInput_AdPlacementFragment[],
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'select-option') {
      const newPlacement = differenceBy(
        selectedPlacements,
        upsertAndDeleteAds.upsertAds,
        'adPlacementID',
      )[0]

      addPlacement(newPlacement.adPlacementID)
    } else if (reason === 'remove-option') {
      const deletedPlacement = differenceBy(
        upsertAndDeleteAds.upsertAds,
        selectedPlacements,
        'adPlacementID',
      )[0]

      removePlacement(deletedPlacement.adPlacementID)
    }
  }

  const handlePlacementDelete = useCallback((adPlacementID: string) => {
    return () => {
      removePlacement(adPlacementID)
    }
  }, [])

  const handlePlacementImageChange = useCallback((adPlacementID: string) => {
    return (imageFiles: FileList) => {
      const placementUpdateImageURL = buildUpdatePlacementImageURL(
        adPlacementID,
      )
      placementUpdateImageURL(imageFiles)
    }
  }, [])

  const handlePlacementImageDelete = useCallback((adPlacementID: string) => {
    return () => {
      const placementUpdateImageURL = buildUpdatePlacementImageURL(
        adPlacementID,
      )
      placementUpdateImageURL(undefined)
    }
  }, [])

  useEffect(() => {
    onChange?.(upsertAndDeleteAds)
  }, [upsertAndDeleteAds])

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Autocomplete<AdvertisementInput_AdPlacementFragment>
        className={classes.autocomplete}
        multiple
        value={selectedPlacements}
        onChange={handleAutocompleteChange}
        options={placements}
        getOptionLabel={({ name, width, height }) => {
          return `${name} (${width}x${height})`
        }}
        renderInput={params => (
          <TextField
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder="광고 지면 검색"
            variant="outlined"
            className={classes.searchInput}
          />
        )}
        renderTags={() => null}
        renderOption={({ name, width, height }, { selected }) => (
          <>
            <div className={classes.optionPlacementName}>
              {name}
              <br />
              <Typography variant="caption">
                {`size: ${width} x ${height}`}
              </Typography>
            </div>
            {selected && <DoneIcon className={classes.selectedOptionIcon} />}
          </>
        )}
      />
      {upsertAndDeleteAds.upsertAds.length > 0 && (
        <Grid container spacing={2}>
          {upsertAndDeleteAds.upsertAds.map(({ adPlacementID, imageID }) => {
            const placement = placementIndex.get(adPlacementID)

            if (!placement) return null

            const thumbnailImageURL = imageID
              ? getImageURL(ADVERTISEMENT, imageID)
              : undefined

            return (
              <Grid item xs={12} sm={6} key={adPlacementID}>
                <Paper className={classes.placementCard}>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.placementHeader}
                  >
                    {placement.name}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handlePlacementDelete(adPlacementID)}
                    >
                      지면 삭제
                    </Button>
                  </Typography>
                  <ImageInput
                    onImageChange={handlePlacementImageChange(adPlacementID)}
                    onImageDelete={handlePlacementImageDelete(adPlacementID)}
                    inputProps={{
                      placeholder: `size: ${placement.width} x ${placement.height}`,
                    }}
                    {...(thumbnailImageURL && {
                      thumbnailProps: {
                        src: thumbnailImageURL,
                      },
                    })}
                  />
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default AdvertisementInput

gql`
  fragment AdvertisementInput_adPlacement on AdPlacement {
    adPlacementID: id
    name
    isActive
    width
    height
  }

  query AdvertisementInput_PlacementList {
    adPlacements(
      filterBy: { isActive: true }
      pagination: { page: 1, pageSize: 999 }
    ) {
      nodes {
        ...AdvertisementInput_adPlacement
      }
    }
  }
`
