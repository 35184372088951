import gql from 'graphql-tag'
import { useAttachmentDeleteMutation } from 'generated/graphql'

function useAttachmentFileDelete() {
  const [attachmentDelete] = useAttachmentDeleteMutation()

  async function fileDelete(id: string) {
    const { data } = await attachmentDelete({
      variables: {
        attachmentID: id,
      },
    })

    return data?.attachmentDelete?.attachment
  }

  return { fileDelete }
}

export default useAttachmentFileDelete

gql`
  mutation AttachmentDelete($attachmentID: ID!) {
    attachmentDelete(attachmentID: $attachmentID) {
      attachment {
        ...useAttachmentFileDelete_attachment
      }
    }
  }

  fragment useAttachmentFileDelete_attachment on Attachment {
    id
    url
    name
    type
    size
  }
`
