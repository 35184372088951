import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkareer: {
      display: 'block',
      color: theme.palette.text.primary,
    },
    timespread: {
      display: 'block',
      color: theme.palette.text.secondary,
    },
  }),
)

interface LinkareerTimespreadCellProps {
  value: {
    linkareer: string
    timespread: string
  }
}

const LinkareerTimespreadCell: React.FC<LinkareerTimespreadCellProps> = ({
  value,
}) => {
  const classes = useStyles()
  return (
    <>
      <span className={classes.linkareer}>LK: {value.linkareer || '-'}</span>
      <span className={classes.timespread}>TS: {value.timespread || '-'}</span>
    </>
  )
}

export default LinkareerTimespreadCell
