import { NetworkStatus } from '@apollo/client'
import { FormControl, Select, makeStyles } from '@material-ui/core'
import Error from 'components/Error'
import Loading from 'components/Loading'
import Table, {
  BooleanCell,
  FormattedDateTimeCell,
  TableState,
} from 'components/Table'
import { createGlobalQueryField } from 'components/Table/FilterBar'
import { unflatten } from 'flat'
import {
  OrderDirection,
  OrganizationTableUserDocument,
  OrganizationTableUserQueryVariables,
  OrganizationTable_UserFragment,
  UserGetListQueryVariables,
  UserOrderField,
  useOrganizationTableUserQuery,
} from 'generated/graphql'
import gql from 'graphql-tag'
import useApolloExcute from 'hooks/useApolloExcute'
import {
  orderBySchema,
  paginationSchema,
  useQueryStringVariables,
} from 'hooks/useVariablesQueryStringSync'
import { isEmpty, orderBy } from 'lodash'
import get from 'lodash/get'
import queryString from 'query-string'
import React, { FC, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UserLoginCell } from './UserTable'

interface UseFilterUpdateHandlerProps {
  field: string
  updater: any
}

function useOrganizationTableUserQueryStringVariables() {
  const [queryVariables, updateQueryString] = useQueryStringVariables({
    type: 'object',
    properties: {
      pagination: paginationSchema,
      orderBy: {
        ...orderBySchema,
        default: {
          field: UserOrderField.ID,
          direction: OrderDirection.DESC,
        },
      },
      filterBy: {
        type: 'object',
        default: {
          isOrganization: true,
        },
        properties: {
          isOrganization: {
            type: 'boolean',
          },
          q: { type: 'string' },
          isConfirmed: {
            type: 'boolean',
          },
        },
      },
    },
  })

  return [queryVariables, updateQueryString]
}

const useToolbarStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  selectInput: {
    padding: 5,
    marginLeft: 10,
  },
})

const OrganizationTableExtendedToolbar: FC<TableState> = () => {
  const classes = useToolbarStyles()
  const [
    { filterBy, orderBy },
    updateQueryString,
  ] = useOrganizationTableUserQueryStringVariables()
  const { isConfirmed } = filterBy
  const location = useLocation()
  const value =
    orderBy.field === UserOrderField.CONFIRMATION_REQUESTED_AT
      ? UserOrderField.CONFIRMATION_REQUESTED_AT
      : typeof isConfirmed === 'boolean'
      ? String(isConfirmed)
      : ''

  const handleFilterUpdateHandler = ({
    field,
    updater,
  }: UseFilterUpdateHandlerProps) => {
    const prevQuery = unflatten(queryString.parse(location.search)) || {}

    return useCallback(
      event => {
        const filterBy: UserGetListQueryVariables['filterBy'] = {
          ...prevQuery.filterBy,
          [field]: event.target.value,
        }

        updater({
          ...prevQuery,
          filterBy,
          orderBy: {
            field: UserOrderField.ID,
            direction: OrderDirection.DESC,
          },
        })
      },
      [field, updater, prevQuery],
    )
  }

  const handleSwitchChange = handleFilterUpdateHandler({
    field: 'isConfirmed',
    updater: updateQueryString,
  })

  const handleChangeSelect = event => {
    const prevQuery = unflatten(queryString.parse(location.search)) || {}

    if (event.target.value === 'CONFIRMATION_REQUESTED_AT') {
      const orderBy: UserGetListQueryVariables['orderBy'] = {
        ...prevQuery.orderBy,
        field: 'CONFIRMATION_REQUESTED_AT',
        direction: OrderDirection.DESC,
      }
      const filterBy: UserGetListQueryVariables['filterBy'] = {
        ...prevQuery.filterBy,
        isOrganization:true,
      }

      delete filterBy?.isConfirmed

      updateQueryString({
        ...prevQuery,
        orderBy,
        filterBy,
      })
    } else {
      handleSwitchChange(event)
    }
  }

  return (
    <div className={classes.wrapper}>
      <FormControl>
        <Select
          inputProps={{
            name: 'isConfirmed',
          }}
          value={value}
          onChange={handleChangeSelect}
          className={classes.selectInput}
          native
        >
          <option value="">전체</option>
          <option value={'false' as string}>대기</option>
          <option value={'true' as string}>승인</option>
          <option value={UserOrderField.CONFIRMATION_REQUESTED_AT}>
            갱신순
          </option>
        </Select>
      </FormControl>
    </div>
  )
}

const OrganizationTable: FC = () => {
  const history = useHistory()
  const apolloExcute = useApolloExcute()
  const [
    queryVariables,
    updateQueryString,
  ] = useOrganizationTableUserQueryStringVariables()

  const {
    data,
    error,
    variables,
    networkStatus,
  } = useOrganizationTableUserQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
    },
  })

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const filterFields: any = []

  if (variables.filterBy && variables.filterBy.q) {
    filterFields.push(createGlobalQueryField(variables.filterBy.q))
  }

  return (
    <>
      <Table<OrganizationTable_UserFragment>
        extendedToolbar={OrganizationTableExtendedToolbar}
        title="기업회원"
        columns={[
          {
            Header: '회원 ID',
            accessor: 'id' as const,
            width: 70,
            defaultCanSort: true,
            sortField: UserOrderField.ID,
          },
          {
            Header: '기업 ID',
            // @ts-ignore
            accessor: 'organization.id' as const,
            width: 50,
          },
          {
            Header: '기관명',
            minWidth: 180,
            // @ts-ignore
            accessor: 'organization.name' as const,
          },
          {
            Header: '기관분류',
            minWidth: 140,
            // @ts-ignore
            accessor: 'organization.organizationType.name' as const,
          },
          {
            Header: '사업자번호',
            // @ts-ignore
            accessor: 'organization.registrationNumber' as const,
          },
          {
            Header: '담당자명',
            accessor: 'managerName' as const,
          },
          {
            Header: '담당자 이메일',
            minWidth: 220,
            accessor: 'email' as const,
          },
          {
            Header: '담당자 연락처',
            accessor: 'phoneNumber' as const,
          },
          {
            Header: '가입일',
            accessor: 'createdAt' as const,
            minWidth: 280,
            Cell: FormattedDateTimeCell,
            defaultCanSort: true,
            sortField: UserOrderField.CREATED_AT,
          },
          {
            Header: '갱신일',
            // @ts-ignore
            accessor: 'organization.confirmationRequestedAt' as const,
            Cell: FormattedDateTimeCell,
            minWidth: 280,
            sortField: UserOrderField.CONFIRMATION_REQUESTED_AT,
          },
          {
            Header: '탈퇴일',
            minWidth: 280,
            accessor: 'deletedAt' as const,
            Cell: FormattedDateTimeCell,
          },
          {
            Header: '승인상태',
            width: 60,
            // @ts-ignore
            accessor: 'organization.isConfirmed' as const,
            Cell: BooleanCell,
          },
          {
            Header: '로그인',
            minWidth: 100,
            id: 'login',
            accessor: 'id' as const,
            Cell: UserLoginCell,
          },
        ]}
        data={get(data, 'users.nodes', [])}
        pagination={queryVariables.pagination}
        orderBy={queryVariables.orderBy}
        pageCount={get(data, 'users.totalCount', 0)}
        filterFields={filterFields}
        onStateChange={({ pagination, orderBy, filterFields = [] }) => {
          const filterBy: OrganizationTableUserQueryVariables['filterBy'] = {}
          filterFields.forEach(filter => {
            if (filter.filterType === 'global-query' && filter.value) {
              filterBy.q = filter.value
            }
          })

          const nextVar = {
            pagination,
            orderBy,
            ...(isEmpty(filterBy)
              ? {}
              : {
                  filterBy: {
                    ...filterBy,
                    isOrganization: true,
                  },
                }),
          }
          updateQueryString(nextVar)
        }}
        loading={networkStatus === NetworkStatus.setVariables}
        getExportData={async () => {
          const result = await apolloExcute({
            query: OrganizationTableUserDocument,
            variables: {
              ...queryVariables,
              pagination: { page: 1, pageSize: 1000 },
            },
          })

          return get(result, 'data.users.nodes', [])
        }}
        onRowClick={row => {
          history.push(`/organizations/list/${row.values.id}`)
        }}
      />
    </>
  )
}

export default OrganizationTable

gql`
  fragment OrganizationTable_organizationType on OrganizationType {
    id
    name
  }

  fragment OrganizationTable_organization on Organization {
    id
    confirmationRequestedAt
    organizationType {
      ...OrganizationTable_organizationType
    }
    name
    registrationNumber
    isConfirmed
  }

  fragment OrganizationTable_user on User {
    id
    managerName
    phoneNumber
    email
    createdAt
    deactivated
    organization {
      ...OrganizationTable_organization
    }
    deletedAt
  }

  fragment OrganizationTable_userConnection on UserConnection {
    nodes {
      ...OrganizationTable_user
    }
    totalCount
  }

  query OrganizationTableUser(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      ...OrganizationTable_userConnection
    }
  }
`
