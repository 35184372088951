import gql from 'graphql-tag'
import {
  AuthLoginDocument,
  AuthLogoutDocument,
  AuthCheckDocument,
  AuthGetPermissionsDocument,
  AuthCheckQuery,
} from './generated/graphql'
import { apolloClient } from './graphql'

interface CreateAuthProviderOptions {
  onCheckAuth?: (currentUser: AuthCheckQuery['currentUser']) => any
}

export function createAuthProvider(options: CreateAuthProviderOptions = {}) {
  return {
    login: async ({ username, password }) => {
      const result = await apolloClient.mutate({
        variables: { email: username, password },
        mutation: AuthLoginDocument,
      })

      const { user } = result.data.login
      return user
        ? null
        : Promise.reject(new Error('login error: current user not found'))
    },

    logout: async () => {
      // called when the user clicks on the logout button
      const result = await apolloClient.mutate({
        mutation: AuthLogoutDocument,
      })
      const logout = result.data.logout
      return logout
        ? null
        : Promise.reject(new Error('logout error: current user not found'))
    },

    checkError: params => {
      console.error(params)
    },

    checkAuth: async () => {
      // called when the user navigates to a new location
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: AuthCheckDocument,
      })
      console.log(result.data)

      const { currentUser } = result.data
      if (options.onCheckAuth) {
        options.onCheckAuth(currentUser)
      }

      if (!currentUser.isSuperuser) {
        return Promise.reject(
          new Error('permission error: current user is not superuser'),
        )
      }

      return currentUser
        ? null
        : Promise.reject(new Error('check auth error: current user not found'))
    },

    getPermissions: async () => {
      // called when the user navigates to a new location
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: AuthGetPermissionsDocument,
      })

      const { currentUser } = result.data
      if (!currentUser) {
        return Promise.reject(
          new Error('permission error: current user not found'),
        )
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, __typename, ...other } = currentUser
      return other
    },
  }
}

gql`
  mutation AuthLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
      }
    }
  }
`

gql`
  mutation AuthLogout {
    logout
  }
`

gql`
  query AuthCheck {
    currentUser {
      id
      isSuperuser
    }
  }
`

gql`
  query AuthGetPermissions {
    currentUser {
      id
    }
  }
`
