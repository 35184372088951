import { useApolloClient } from '@apollo/react-hooks'
import { execute, makePromise, GraphQLRequest } from 'apollo-link'

function useApolloExcute() {
  const client = useApolloClient()
  function excuteOperation(operation: GraphQLRequest) {
    return makePromise(execute(client.link, operation))
  }

  return excuteOperation
}

export default useApolloExcute
