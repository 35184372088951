import gql from 'graphql-tag'
import {
  UserGetDocument,
  UserGetListDocument,
  UserGetManyDocument,
  UserUpdateDocument,
  UserDeleteDocument,
  UserDeleteManyDocument,
  UserInput,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface UserInputInEditor extends UserInput {
  lastLoginSession: string
  timespread: any
}

// UserDetailFragment 안에 들어 있지만 UserFragment에 없는 데이터를 항상 diff에서 추출함
// ex. previousData.lastLoginSession == null, data.lastLoginSession = ''
// 더 나은 해결책이 있을 것 같다
function prepareInput(input: UserInputInEditor): UserInput {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lastLoginSession, timespread, ...other } = input
  return other
}

export const queryBuilder: QueryBuilder = {
  getList: {
    query: UserGetListDocument,
  },
  getOne: {
    query: UserGetDocument,
  },
  getMany: {
    query: UserGetManyDocument,
  },
  update: {
    query: UserUpdateDocument,
  },
  delete: {
    query: UserDeleteDocument,
  },
  deleteMany: {
    query: UserDeleteManyDocument,
  },
  prepareInput,
}

gql`
  fragment UserFragment on User {
    id
    type
    username
    email
    birthday
    phoneNumber
    signupSession {
      ipAddress
    }
    timespread {
      id
      name
      email
      birthday
      profileURL
      phoneNumber
      cash
      facebookID
      kakaoID
      naverID
    }
    facebook {
      id
    }
    kakao {
      id
    }
    naver {
      id
    }
    createdAt
    lastLoginAt

    specupJoined
    specupJoinedAt
    specupJoinedSession
    deactivated
    deletedAt
  }
`

gql`
  fragment UserDetailFragment on User {
    ...UserFragment
    lastLoginSession
  }
`

gql`
  query UserGetList(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    data: users(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...UserFragment
      }
      total: totalCount
    }
  }
`

gql`
  query UserGet($id: ID!) {
    data: user(id: $id) {
      ...UserDetailFragment
    }
  }
`

gql`
  query UserGetMany($ids: [ID!]!) {
    data: usersByID(ids: $ids) {
      ...UserFragment
    }
  }
`

gql`
  mutation UserUpdate($id: ID!, $input: UserInput!) {
    data: userUpdate(id: $id, input: $input) {
      data: user {
        ...UserDetailFragment
      }
    }
  }
`

gql`
  mutation UserDelete($id: ID!) {
    data: deleteUserAccount(id: $id) {
      data: user {
        ...UserFragment
      }
    }
  }
`

gql`
  mutation UserDeleteMany($ids: [ID!]!) {
    data: deleteManyUserAccounts(ids: $ids) {
      data: users {
        ...UserFragment
      }
    }
  }
`
