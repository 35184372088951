import { AWS_S3_BUCKET } from 'config'
import imageCompression from 'browser-image-compression'

export const optimizeImage = async (file: File) => {
  return await new Promise<File>(resolve => {
    const image = document.createElement('img')
    image.src = window.URL.createObjectURL(file)
    // 이미지가 로드되면 canvas를 기존 이미지와 같은 크기로 만들고 이미지를 그림
    image.onload = async () => {
      window.URL.revokeObjectURL(image.src)
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context?.drawImage(image, 0, 0)
      const isPng = file.type === 'image/png'

      if (isPng) {
        // png는 라이브러리 사용 (효율 더 높음)
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        }

        const compressedFile = await imageCompression(file, options)
        const newFile = new File([compressedFile], file.name)
        resolve(newFile.size <= file.size ? newFile : file)
      } else {
        // canvas에 그려진 이미지를 Blob으로 만들고 다시 File로 만들어 리턴
        context?.canvas.toBlob(
          newImageBlob => {
            const newFile =
              newImageBlob !== null ? new File([newImageBlob], file.name) : file
            // 기존 파일보다 용량이 작은지 확인 후 리턴
            resolve(newFile.size <= file.size ? newFile : file)
          },
          // png 외엔 모두 jpg타입
          'image/jpeg',
          0.92,
        )
      }
    }
  })
}

export const createPreviewURL = (files?: File[]) => {
  const file = files?.[0]
  if (file) {
    const fileURL = window.URL.createObjectURL(file)
    return fileURL
  }
}

export function getImageURL(path: string, attachmentID: string) {
  if (!attachmentID || attachmentID === '') {
    return
  }

  return `https://s3.ap-northeast-2.amazonaws.com/${AWS_S3_BUCKET}/${path}/${attachmentID}`
}
