import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import format from 'date-fns/format'
import { ApolloError } from '@apollo/client'

export function stripTypename(value: any): any {
  if (!value || typeof value !== 'object') {
    return value
  }

  if (Array.isArray(value)) {
    return value.map(stripTypename)
  }

  const result: any = {}
  Object.keys(value).forEach(key => {
    if (key === '__typename') {
      return
    }
    result[key] = stripTypename(value[key])
  })

  return result
}

export function walk(value: any, callback: any, path: any = []) {
  if (!value || typeof value !== 'object' || Array.isArray(value)) {
    return callback(value, path)
  }

  Object.keys(value).forEach(key => {
    walk(value[key], callback, [...path, key])
  })
}

function capitalize(token: string, acronyms: string[]) {
  if (token.length === 0) {
    return token
  }

  if (acronyms.indexOf(token) >= 0) {
    return token.toUpperCase()
  }

  return token[0].toUpperCase() + token.slice(1)
}

function reducePath(path: string[], acronyms: string[]) {
  return path.reduce((prev, token) => {
    return prev + capitalize(token, acronyms)
  })
}

export function extractUpdate(prev: any, cur: any, acronyms: string[]) {
  const result: any = {}

  const keys = Object.keys(cur)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    if (!isEqual(prev[key], cur[key])) {
      const value = stripTypename(cur[key])
      if (typeof value === 'object' && !Array.isArray(value)) {
        walk(
          value,
          (childValue: any, path: string[]) => {
            result[reducePath(path, acronyms)] = childValue
          },
          [key],
        )
      } else {
        result[key] = value
      }
    }
  }

  return result
}

export function diff(prev: any, cur: any) {
  const result: any = {}

  const keys = Object.keys(cur)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]

    if (isEqual(prev[key], cur[key])) {
      continue
    }

    result[key] = cur[key]
  }

  return result
}

export function identity<T>(value: T): T {
  return value
}

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|[a-zA-Z0-9]+\.[^\s]{2,}/
export const urlRegexIncludingProtocol = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/

export function getDateString(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:00")
}

export function getApolloErrorCode(error?: ApolloError): string {
  const errorCode: string = get(
    error,
    'graphQLErrors[0].extensions.code',
    'UNKNOWN_ERROR',
  )

  return errorCode
}

export function buildIndex<TKey, TValue>(
  values: TValue[],
  mapID: (value: TValue) => TKey,
) {
  const indexedValues = new Map<TKey, TValue>()
  for (const value of values) {
    indexedValues.set(mapID(value), value)
  }
  return indexedValues
}

export function pluckBy<T, TResult>(
  array: T[],
  getValue: (item: T) => TResult,
) {
  return array.reduce<TResult[]>((acc, cur) => {
    const value = getValue(cur)

    if (!value) {
      return acc
    }

    acc.push(value)
    return acc
  }, [])
}
