import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Create as CreateIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'
import {
  useOrganizationDetailOrganizationTypesQuery,
  useOrganizationDetailUserQuery,
  useOrganizationDetail_UserUpdateMutation,
  OrganizationDetail_AttachmentFragment,
  UserType,
  useUserDeactivateMutation,
} from 'generated/graphql'
import DeleteIcon from './DeleteIcon'

const useStyles = makeStyles(() => {
  return {
    root: {
      padding: '20px 50px',
    },
    paperRoot: {
      width: '100%',
      padding: '10px 20px',
    },
    fieldRoot: {
      maxWidth: '700px',
      border: '1px solid',
      padding: '4px 16px',
      marginTop: '10px',
      minHeight: '50px',
    },
    subjectMarginTop: {
      marginTop: '20px',
    },
    colorGreen: {
      color: 'green',
    },
    colorGray: {
      color: '#d0d0d0',
    },
    sectionPaper: {
      padding: '15px',
      borderRadius: '5px',
    },
    originPaper: {
      backgroundColor: '#efefef',
    },
    editPaper: {
      backgroundColor: '#fafafa',
    },
    doneGridRoot: {
      marginTop: '10px',
    },
    overflowHidden: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      justifyContent: 'left',
      padding: '4px 5px',
    },
    licenseButton:{
      display:'flex',
      gap:'5px',
      alignItems:'center',
    }
  }
})

type OrganizationDetailSelectItem = {
  title: string | undefined
  value: string | undefined
}

interface OrganizationDetailFieldProps {
  fieldType?: 'text' | 'select' | 'manual'
  name?: string
  label: string
  type?: 'text' | 'password'
  items?: OrganizationDetailSelectItem[] | undefined
  children?: ReactNode
  disabled?: boolean
  value?: string | null | undefined
  onChange?: any
  loading?: boolean
}

const OrganizationDetailField: FC<OrganizationDetailFieldProps> = ({
  fieldType = 'manual',
  label,
  name,
  children,
  items,
  type = 'text',
  disabled = false,
  value,
  onChange,
  loading = false,
}) => {
  const classes = useStyles()
  const getElement = () => {
    const Text = (
      <TextField
        fullWidth
        id={name}
        name={name}
        type={type}
        placeholder={type === 'password' ? '**********' : undefined}
        disabled={disabled}
        value={value}
        onChange={onChange}
        autoComplete="off"
      />
    )
    const SelectComponent = (
      <Select
        id={name}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {items?.map(({ title, value }) => (
          <MenuItem value={value} key={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    )
    const Manual = children

    if (fieldType === 'text') {
      return Text
    } else if (fieldType === 'select') {
      return SelectComponent
    } else if (fieldType === 'manual') {
      return Manual
    }
  }

  return (
    <Grid container className={classes.fieldRoot}>
      <Grid item xs={5} container alignItems="center">
        {label}
      </Grid>
      <Grid
        item
        xs={7}
        container
        alignItems="center"
      >
        {!loading && getElement()}
      </Grid>
    </Grid>
  )
}

type FormDataType = string | null | undefined

interface OrganizationDetailFormData {
  registrationNumber: FormDataType
  organizationName: FormDataType
  representative: FormDataType
  organizationTypeId: FormDataType
  alias: FormDataType
  login: FormDataType
  managerName: FormDataType
  email: FormDataType
  phoneNumber: FormDataType
  password: FormDataType
  licenseImages?: File[]
  licenseImageInfoList?: (OrganizationDetail_AttachmentFragment | null)[]
}

interface OrganizationDetailProps {
  id: string
}

const OrganizationDetail: FC<OrganizationDetailProps> = ({ id }) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [organizationDetailUserUpdate, { loading: userUpdateLoading }] =
    useOrganizationDetail_UserUpdateMutation()
  const [userDeactivate, { loading: deactivateLoading }] =
    useUserDeactivateMutation({
      onCompleted: () => {
        history.push('/organizations/list')
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      },
    })

  const [deleteAttachmentIds, setDeleteAttachmentIds] = useState<string[]>(
    [],
  )

  const formik = useFormik<OrganizationDetailFormData>({
    initialValues: {
      registrationNumber: null,
      organizationName: null,
      representative: null,
      organizationTypeId: null,
      alias: null,
      login: null,
      managerName: null,
      email: null,
      phoneNumber: null,
      password: null,
      licenseImages: [],
      licenseImageInfoList: [],
    },
    onSubmit: async (values) => {
      const {
        organizationName,
        registrationNumber,
        representative,
        organizationTypeId,
        alias,
        managerName,
        phoneNumber,
        email,
        password,
        licenseImages,
      } = values
      const inputValues = {
        organizationTypeID: organizationTypeId,
        managerPhoneNumber: phoneNumber,
        managerEmail: email,
        organizationName,
        managerName,
        registrationNumber,
        representative,
        alias,
        password,
        licenseImages,
        deleteLicenseImageIds:deleteAttachmentIds
      }

      if (password === '') {
        delete inputValues.password
      }
      if (licenseImages && licenseImages.length < 1) {
        delete inputValues.licenseImages
      }
      const result = await organizationDetailUserUpdate({
        variables: {
          id: id,
          input: inputValues,
        },
      })
      if (result?.data) {
        enqueueSnackbar('성공적으로 수정되었습니다.', {
          variant: 'success',
        })
        history.push('/organizations/list')
      }
    },
  })

  const [edit, setEdit] = useState<boolean>(false)
  const { data, loading } = useOrganizationDetailUserQuery({
    variables: {
      id: id,
    },
  })
  const { data: organizationTypesData, loading: organizationTypesLoading } =
    useOrganizationDetailOrganizationTypesQuery()

  useEffect(() => {
    if (!loading) {
      const user = data?.user
      formik.setValues({
        registrationNumber: user?.organization?.registrationNumber,
        organizationName: user?.organization?.name,
        representative: user?.organization?.representative,
        organizationTypeId: user?.organization?.organizationType?.id,
        alias: user?.organization?.alias,
        login: user?.login,
        managerName: user?.managerName,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        password: '',
        licenseImages: [],
        licenseImageInfoList: user?.organization?.licenseImages,
      })
    }
  }, [loading])

  const licenseImageInfoList = formik.values.licenseImageInfoList ?? []
  const licenseImages = formik.values.licenseImages ?? []

  if (loading) {
    return <CircularProgress size={50} />
  }

  if (!data?.user) {
    return <span>사용자 정보를 찾을 수 없습니다.</span>
  }

  const {
    type,
    organization,
    managerName,
    email,
    phoneNumber,
    login,
    deletedAt,
  } = data?.user

  if (type !== UserType.MANAGER_UNVERIFIED) {
    return <span>올바르지 않은 접근입니다. (기업회원 아님)</span>
  }

  const onClickEdit = () => {
    setEdit(true)
  }

  const onClickCancel = () => {
    setEdit(false)
  }

  const onClickConfirm = async () => {
    const result = await organizationDetailUserUpdate({
      variables: {
        id: id,
        input: {
          isConfirmed: true,
        },
      },
    })
    if (result?.data) {
      enqueueSnackbar('성공적으로 승인되었습니다.', {
        variant: 'success',
      })
    }
  }

  const handleClickOpenAttachment = (url: string) => {
    window.open(url)
  }

  const handleClickOpenFilePreview = (file:File)=>{
const previewUrl = window.URL.createObjectURL(file)
window.open(previewUrl)
  }

  const handleDeleteAttachment = async (id?: string) => {
    const filteringLicenseImageInfoList = licenseImageInfoList.filter(
      (attachment) => {
        if (id && attachment?.id === id) {
          setDeleteAttachmentIds((prev) => [...prev, id])
        }
        return attachment?.id !== id
      },
    )
    formik.setFieldValue('licenseImageInfoList', filteringLicenseImageInfoList)
  }
  
  const handleDeleteFile = async (fileName: string, index: number) => {
    const filteringLicenseImages = licenseImages?.filter((file, fileIndex) => {
      return file.name !== fileName && fileIndex !== index
    })
    formik.setFieldValue('licenseImages',filteringLicenseImages)
  }

  const onClickDeactivate = async () => {
    await userDeactivate({ variables: { userID: id } })
  }

  const handleChangeLicenseImage = (event) => {
    const file = event.target.files[0]
    if ((file.size || 0) > 2 * 1024 * 1024) {
      enqueueSnackbar(
        '파일이 너무 큽니다. 2MB이하의 파일만 업로드 할 수 있습니다.',
        {
          variant: 'error',
          resumeHideDuration: 1000,
        },
      )
      return false
    }
    if ((licenseImageInfoList?.length + licenseImages.length) < 2) {
      const appendLicenseImages = [...(licenseImages ?? []), file]
      formik.setFieldValue('licenseImages', appendLicenseImages)
    }
    else {
      enqueueSnackbar('파일은최대2개까지 등록가능합니다.', {
        variant: 'error',
        resumeHideDuration: 1000,
      })
    }
  }

  const onClickDeleteNewLicense = () => {
    const AllLicenseImageInfoIds = licenseImageInfoList.map((attachment)=>{
      return attachment?.id ?? ''
    })
    setDeleteAttachmentIds(AllLicenseImageInfoIds)
    formik.setFieldValue('licenseImageInfoList', [])
    formik.setFieldValue('licenseImages', [])
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Chip
              label={`UserID: ${id}, OrganizationID: 
              ${organization?.id}`}
            />
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            {edit ? (
              ' '
            ) : (
              <Button onClick={onClickEdit}>
                <CreateIcon /> 수정
              </Button>
            )}
          </Grid>
          {deletedAt && (
            <Grid item xs={12}>
              <>
                <Typography variant="h5">탈퇴한 회원입니다.</Typography>
                <span>(탈퇴일 : {format(deletedAt, 'yyyy-MM-dd kk:mm')})</span>
              </>
            </Grid>
          )}
          <Grid item xs={6} container direction="column">
            <div
              className={clsx(classes.sectionPaper, {
                [classes.originPaper]: edit,
              })}
            >
              {edit && (
                <Grid item container justify="center">
                  <Typography variant="h4">수정 전</Typography>
                </Grid>
              )}
              <Typography variant="h5">기관정보 </Typography>
              <OrganizationDetailField label="사업자등록번호">
                {organization?.registrationNumber}
              </OrganizationDetailField>
              <OrganizationDetailField label="기관명">
                {organization?.name}
              </OrganizationDetailField>
              <OrganizationDetailField label="대표자">
                {organization?.representative}
              </OrganizationDetailField>
              <OrganizationDetailField label="기관구분">
                {organization?.organizationType?.name}
              </OrganizationDetailField>
              <OrganizationDetailField label="기관별칭">
                {organization?.alias}
              </OrganizationDetailField>
              <OrganizationDetailField label="사업자등록증(또는 고유번호증)">
                <div
                  style={{
                    padding: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  {organization?.licenseImages && organization.licenseImages.length > 0
                    ? organization?.licenseImages.map((license) => {
                        return (
                          <a
                            href={license?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.overflowHidden}
                            key={license?.id}
                          >
                            {license?.name}
                          </a>
                        )
                      })
                    : '-'}
                </div>
              </OrganizationDetailField>
              <Typography variant="h5" className={classes.subjectMarginTop}>
                계정 정보
              </Typography>
              <OrganizationDetailField label="아이디">
                {login}
              </OrganizationDetailField>
              <OrganizationDetailField label="패스워드">
                **********
              </OrganizationDetailField>
              <Typography variant="h5" className={classes.subjectMarginTop}>
                담당자 정보
              </Typography>
              <OrganizationDetailField label="담당자명">
                {managerName}
              </OrganizationDetailField>
              <OrganizationDetailField label="이메일">
                {email}
              </OrganizationDetailField>
              <OrganizationDetailField label="연락처">
                {phoneNumber}
              </OrganizationDetailField>
              <Typography variant="h5" className={classes.subjectMarginTop}>
                가입상태 :{' '}
                {organization?.isConfirmed ? (
                  <span className={classes.colorGreen}>승인완료</span>
                ) : (
                  <span className={classes.colorGray}>승인대기</span>
                )}
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={onClickConfirm}
                  disabled={
                    !!organization?.isConfirmed || edit || userUpdateLoading
                  }
                >
                  가입승인
                </Button>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            {edit && (
              <div className={clsx(classes.sectionPaper, classes.editPaper)}>
                <Grid container justify="center">
                  <Typography variant="h4">수정 후</Typography>
                </Grid>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h5">기관정보</Typography>
                  <OrganizationDetailField
                    fieldType="text"
                    name="registrationNumber"
                    label="사업자등록번호"
                    value={formik.values.registrationNumber}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField
                    fieldType="text"
                    name="organizationName"
                    label="기관명"
                    value={formik.values.organizationName}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField
                    fieldType="text"
                    name="representative"
                    label="대표자"
                    value={formik.values.representative}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField
                    fieldType="select"
                    name="organizationTypeId"
                    label="기관구분"
                    value={formik.values.organizationTypeId}
                    onChange={formik.handleChange}
                    loading={organizationTypesLoading}
                    items={organizationTypesData?.organizationTypes?.map(
                      (organizationType) => {
                        const item: OrganizationDetailSelectItem = {
                          title: organizationType?.name,
                          value: organizationType?.id,
                        }
                        return item
                      },
                    )}
                  />
                  <OrganizationDetailField
                    fieldType="text"
                    name="alias"
                    label="기관별칭"
                    value={formik.values.alias}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField label="사업자등록증(또는 고유번호증)">
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        {(licenseImageInfoList.length === 0 && licenseImages.length === 0) && '-'}
                        {licenseImageInfoList.map((attachment) => {
                          return (
                            <div className={classes.licenseButton}  key={attachment?.id}>
                            <Button
                              onClick={() =>
                                handleClickOpenAttachment(
                                  attachment?.url ?? '',
                                )
                              }
                              size="small"
                              disabled={!attachment?.name}
                              className={classes.overflowHidden}
                             
                            >
                              {attachment?.name}
                            </Button>
                            <span onClick={()=>handleDeleteAttachment(attachment?.id)}><DeleteIcon/></span>
                            </div>
                          )
                        })}
                        {licenseImages.map((file,index) => {
                          return (
                            <div className={classes.licenseButton} key={`${file.name}-${index}`}>
                            <Button
                              onClick={() =>
                                handleClickOpenFilePreview(file)
                              }
                              size="small"
                              disabled={!file?.name}
                              className={classes.overflowHidden}
                             
                            >
                              {file.name}
                            </Button>
                            <span onClick={()=>handleDeleteFile(file.name,index)}><DeleteIcon/></span>
                            </div>
                          )
                        })}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        spacing={1}
                        justify="flex-end"
                      >
                        <Grid item>
                          <input
                            type="file"
                            accept=".pdf,.PDF,image/*"
                            id="licenseImageUpload"
                            name="licenseImages"
                            onChange={handleChangeLicenseImage}
                            hidden
                          />
                          <label htmlFor="licenseImageUpload">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              size="small"
                            >
                              찾아보기
                            </Button>
                          </label>
                        </Grid>
                        {licenseImageInfoList.length > 0 && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={onClickDeleteNewLicense}
                              size="small"
                            >
                              비우기
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </OrganizationDetailField>

                  <Typography variant="h5" className={classes.subjectMarginTop}>
                    계정 정보
                  </Typography>
                  <OrganizationDetailField label="아이디">
                    {login}
                  </OrganizationDetailField>
                  <OrganizationDetailField
                    fieldType="text"
                    type="password"
                    name="password"
                    label="패스워드"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <Typography variant="h5" className={classes.subjectMarginTop}>
                    담당자 정보
                  </Typography>
                  <OrganizationDetailField
                    fieldType="text"
                    name="managerName"
                    label="담당자명"
                    value={formik.values.managerName}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField
                    fieldType="text"
                    name="email"
                    label="이메일"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <OrganizationDetailField
                    fieldType="text"
                    name="phoneNumber"
                    label="연락처"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                  />
                  <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.doneGridRoot}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClickDeactivate}
                        disabled={
                          userUpdateLoading || deactivateLoading || !!deletedAt
                        }
                      >
                        회원탈퇴
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={onClickCancel}
                        disabled={userUpdateLoading || deactivateLoading}
                      >
                        취소
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={userUpdateLoading || deactivateLoading}
                      >
                        수정 완료
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default OrganizationDetail

gql`
  fragment OrganizationDetail_attachment on Attachment {
    id
    url
    name
  }

  fragment OrganizationDetail_organizationType on OrganizationType {
    id
    name
  }

  fragment OrganizationDetail_organization on Organization {
    id
    organizationType {
      ...OrganizationDetail_organizationType
    }
    name
    registrationNumber
    isConfirmed
    representative
    alias
    licenseImages {
      ...OrganizationDetail_attachment
    }
  }

  fragment OrganizationDetail_user on User {
    id
    login
    type
    managerName
    phoneNumber
    email
    deactivated
    deletedAt
    organization {
      ...OrganizationDetail_organization
    }
  }

  query OrganizationDetailUser($id: ID!) {
    user(id: $id) {
      ...OrganizationDetail_user
    }
  }

  query OrganizationDetailOrganizationTypes {
    organizationTypes {
      ...OrganizationDetail_organizationType
    }
  }

  mutation OrganizationDetail_userUpdate($id: ID!, $input: UserInput!) {
    userUpdate(id: $id, input: $input) {
      user {
        ...OrganizationDetail_user
      }
    }
  }
`
