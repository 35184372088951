import gql from 'graphql-tag'
import {
  SearchExactCoverLetterDocument,
  SearchExactCoverLetterQueryVariables,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
  getList: {
    query: SearchExactCoverLetterDocument,
    variables(args): SearchExactCoverLetterQueryVariables {
      return {
        field: (args.filterBy && args.filterBy.field) || '',
        query: (args.filterBy && args.filterBy.q) || '',
      }
    },
    parseResponse: response => {
      return {
        total: response.data.length,
        data: response.data,
      }
    },
  },
}

gql`
  query SearchExactCoverLetter($query: String!, $field: CoverLetterField!) {
    data: searchExactCoverLetter(query: $query, field: $field) {
      count
      query
    }
  }

  mutation UpdateCoverLetterFieldByQuery(
    $input: UpdateCoverLetterFieldByQueryInput!
  ) {
    updateCoverLetterFieldByQuery(input: $input) {
      updatedCoverLetters {
        organizationName
        role
      }
    }
  }
`
