import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  useDeleteMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin'
import { ResourceType } from 'resources'

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '0.8125rem',
  },
  deleteIcon: {
    marginRight: 4,
  },
}))

interface BulkDeleteButtonProps {
  resource: ResourceType | string
  selectedIds?: string[]
}

/**
 * react-admin은 delete 후, 삭제된 데이터의 id를 받아서 보여줌
 * deleteAPI가 DB상에서 아예 지워버리는 로직인 경우, react-admin의 BulkDeleteButton로 삭제하면
 * 삭제된 데이터를 찾지 못해 에러가 발생하기 때문에 커스텀 버튼을 사용해야 함
 */
const BulkDeleteButton: FC<BulkDeleteButtonProps> = props => {
  const { selectedIds, resource } = props
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
    onSuccess: () => {
      const count = selectedIds ? selectedIds.length : 0

      refresh()
      notify(`${count}개를 삭제했습니다.`)
      unselectAll(resource)
    },
    onFailure: error =>
      notify(`Error: 삭제에 실패했습니다. ${error}`, 'warning'),
  })

  return (
    <Button
      onClick={deleteMany}
      disabled={loading}
      color="secondary"
      startIcon={<DeleteIcon />}
      classes={{
        startIcon: classes.deleteIcon,
      }}
      className={classes.button}
    >
      삭제
    </Button>
  )
}

export default BulkDeleteButton
