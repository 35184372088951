import React, { ReactNode, FC } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Template from './Template'
import ProfileImageEditor from './ProfileImageEditor'
import SendJobPostingDataToNaver from './SendJobPostingDataToNaver'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    paddingRight: 350,
  },
  nav: {
    position: 'fixed',
    right: 0,
    top: 50,
    width: 350,
  },
  menus: {
    padding: 0,
    paddingLeft: 10,
    listStyle: 'none',
  },
  menu: {
    padding: '5px 0',
  },
  link: {
    position: 'relative',
    display: 'inline-block',
    color: '#000',
    textDecoration: 'none',
  },
  indicator: {
    position: 'absolute',
    width: 4,
    top: 0,
    bottom: 0,
    left: -10,
    backgroundColor: 'red',
  },
})

interface DevMenu {
  id: string
  title: string
  description?: string
  component: ReactNode
}

const menus: DevMenu[] = [
  {
    id: 'change-profile-image',
    title: '프로필 사진 삭제',
    description: `
      검색해서 알맞는 프로필이 조회되지 않는다면, CS로 받은 메일정보가 잘못되었을 확률이 높습니다.
      한 번 삭제하면 되돌릴 수 없으니 신중하게 사용해주세요.
    `,
    component: <ProfileImageEditor />,
  },
  {
    id: 'send-job-posting-data-to-naver',
    title: '채용공고 Job posting 데이터 네이버로 전송',
    description: `
      네이버 채용공고 노출 최적화를 위한 Job posting 데이터 전송
      [네이버 Job posting data 정보]
      https://searchadvisor.naver.com/guide/structured-data-job
    `,
    component: <SendJobPostingDataToNaver />,
  },
]

const DevToolsDashboard: FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const location = history.location
  let hash = location.hash.replace('#', '')
  if (hash === '') {
    hash = menus[0].id
  }
  const currentMenuID = hash

  return (
    <div className={classes.root}>
      <main>
        {menus.map(menu => (
          <Template
            id={menu.id}
            key={menu.id}
            title={menu.title}
            description={menu.description}
          >
            {menu.component}
          </Template>
        ))}
      </main>
      <nav className={classes.nav}>
        <ul className={classes.menus}>
          {menus.map(menu => (
            <li key={menu.id} className={classes.menu}>
              <a href={`#${menu.id}`} className={classes.link}>
                {menu.id === currentMenuID && (
                  <span className={classes.indicator}></span>
                )}
                {menu.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default DevToolsDashboard
