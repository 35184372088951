import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { ApolloError } from '@apollo/client'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { getErrorCode, getErrorMessage } from 'utils/errorParser'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    paddingTop: 100,
  },
  page: {
    minHeight: '100vh',
  },
  icon: {
    fontSize: 82,
    marginBottom: 8,
  },
})

interface ErrorProps {
  variant?: 'default' | 'page'
  className?: string
  error: ApolloError
}

const Error: FunctionComponent<ErrorProps> = ({
  variant = 'default',
  className,
  error,
  ...Props
}) => {
  const classes = useStyles()
  const errorCode: string = getErrorCode(error)
  const errorMessage: string = getErrorMessage(error)

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.page]: variant === 'page' },
        className,
      )}
      {...Props}
    >
      <div>
        <ErrorIcon className={classes.icon} />
        <Typography variant="h3">{errorCode}</Typography>
        <Typography variant="h5">{errorMessage}</Typography>
      </div>
    </div>
  )
}

export default Error
