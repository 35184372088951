import React from 'react'
import { StringForm, renderStringValue } from './stringField'
import { FilterType, FilterField } from './fieldType'

export function getFilterForm(filterType: FilterType) {
  if (filterType === 'string-match') {
    return <StringForm />
  }

  return null
}

export function renderValue(filterField: FilterField) {
  if (filterField.filterType === 'string-match') {
    return renderStringValue(filterField)
  } else if (filterField.filterType === 'global-query') {
    return `'${filterField.value || ''}'가 포함된 데이터`
  }

  return null
}
