import React from 'react'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { NetworkStatus } from '@apollo/client'
import {
  useUserStatisticsPageQuery,
  UserStatisticsPageDocument,
} from 'generated/graphql'
import Loading from 'components/Loading'
import Table, { FormattedDateCell } from 'components/Table'
import Error from 'components/Error'
import { useQueryStringVariables } from 'hooks/useVariablesQueryStringSync'
import useApolloExcute from 'hooks/useApolloExcute'

function UserStatisticsPage() {
  const apolloExcute = useApolloExcute()
  const [queryVariables, updateQueryString] = useQueryStringVariables({
    type: 'object',
    properties: {
      pagination: {
        default: {},
        type: 'object',
        properties: {
          page: { type: 'number', default: 1 },
          pageSize: { type: 'number', default: 10 },
        },
      },
    },
  })

  const { data, error, refetch, networkStatus } = useUserStatisticsPageQuery({
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
  })

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <>
      <Table
        title="유저 통계"
        columns={[
          { Header: '날짜', accessor: 'date', Cell: FormattedDateCell },
          { Header: '전체 가입수', accessor: 'totalSignupCount' },
          { Header: '일반유저 가입수', accessor: 'normalSignupCount' },
          { Header: '기관유저 가입수', accessor: 'orgSignupCount' },
        ]}
        data={get(data, 'userStatistics.nodes', [])}
        pagination={queryVariables.pagination}
        pageCount={get(data, 'userStatistics.totalCount', 0)}
        onStateChange={({ pagination }) => {
          refetch({ pagination })
          updateQueryString({ pagination })
        }}
        loading={networkStatus === NetworkStatus.setVariables}
        disableFilter
        getExportData={async () => {
          const result = await apolloExcute({
            query: UserStatisticsPageDocument,
            variables: {
              ...queryVariables,
              pagination: { page: 1, pageSize: 1000 },
            },
          })

          return result?.data?.userStatistics?.nodes || []
        }}
      />
    </>
  )
}

export default UserStatisticsPage

gql`
  query UserStatisticsPage($pagination: Pagination!) {
    userStatistics(pagination: $pagination) {
      totalCount
      nodes {
        id
        date
        createdAt
        orgSignupCount
        totalSignupCount
        normalSignupCount
      }
    }
  }
`
