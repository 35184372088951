import React, { FC, useState, useRef } from 'react'
import {
  Button,
  List,
  Dialog,
  DialogTitle,
  makeStyles,
  Grid,
} from '@material-ui/core'
import DateInput, { DateInputAPI } from './DateInput'

const useStyles = makeStyles({
  children: {
    padding: '16px 24px',
    display: 'inline-grid',
    '& div': {
      marginBottom: 10,
    },
  },

  buttonContainer: {
    placeContent: 'flex-end',
  },
  button: {
    marginRight: 10,
  },
})

export interface SimpleDialogProps {
  title: string
  open: boolean
  elementID?: string
}

const SimpleDialog: FC<SimpleDialogProps> = ({
  title,
  open,
  children,
  elementID,
}) => {
  const classes = useStyles()
  return (
    <Dialog aria-labelledby={elementID} open={open}>
      <Grid>
        <DialogTitle id={elementID}>{title}</DialogTitle>
        <List className={classes.children}>{children}</List>
      </Grid>
    </Dialog>
  )
}

interface DateDialogProps {
  label: string
  source: string
  alwaysOn: boolean
}

const DateDialog: FC<DateDialogProps> = ({ label, source }) => {
  const classes = useStyles()
  const fromInputRef = useRef<DateInputAPI>(null)
  const toInputRef = useRef<DateInputAPI>(null)

  const [open, setOpen] = useState<boolean>(false)

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleInitialize = () => {
    if (fromInputRef.current) {
      fromInputRef.current.init()
    }
    if (toInputRef.current) {
      toInputRef.current.init()
    }

    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleOnClick} color="primary" variant="outlined">
        {label}
      </Button>
      <SimpleDialog title={label} open={open}>
        <DateInput
          ref={fromInputRef}
          source={`${source}.from`}
          alwaysOn={true}
          label="from"
        />
        <DateInput
          ref={toInputRef}
          source={`${source}.to`}
          alwaysOn={true}
          label="to"
        />

        <Grid className={classes.buttonContainer} container>
          <Button
            className={classes.button}
            onClick={handleInitialize}
            color="primary"
          >
            초기화
          </Button>
          <Button onClick={handleClose} color="primary">
            닫기
          </Button>
        </Grid>
      </SimpleDialog>
    </>
  )
}

export default DateDialog
