import React, { ChangeEvent, FC, SyntheticEvent, useMemo } from 'react'
import {
  Campaign_CampaignFragment,
  CampaignScheduleInput,
} from 'generated/graphql'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AdvertisementInput, {
  AdvertisementInputFormData,
} from './AdvertisementInput'
import TimetableInput, { TimetableFormData } from 'components/TimetableInput'
import { urlRegexIncludingProtocol, getDateString } from 'utils'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import range from 'lodash/range'

export function convertTimetableDataToCampaignSchedule(
  timetableData: TimetableFormData[],
) {
  const sortedSchedulesByHour = sortBy(timetableData, props => props.hour)
  const schedulesGroupedByDayOfWeek = groupBy(
    sortedSchedulesByHour,
    props => props.dayOfWeek,
  )

  let result: CampaignScheduleInput[] = []
  for (const dayOfWeek of Object.keys(schedulesGroupedByDayOfWeek)) {
    const currentSchedules = schedulesGroupedByDayOfWeek[dayOfWeek]
    let prevSchedule: TimetableFormData, startTime: string, endTime: string

    const campaignSchedules = currentSchedules.reduce<CampaignScheduleInput[]>(
      (campaignSchedules, schedule, index) => {
        const startOfHour = `${schedule.hour}:00:00`
        const endOfHour = `${schedule.hour}:59:59`

        if (currentSchedules.length === 1) {
          campaignSchedules.push({
            dayOfWeek,
            startTime: startOfHour,
            endTime: endOfHour,
          })

          return campaignSchedules
        }

        if (!prevSchedule) {
          prevSchedule = schedule
          startTime = startOfHour
          endTime = endOfHour
          return campaignSchedules
        }

        if (Number(prevSchedule.hour) + 1 !== Number(schedule.hour)) {
          campaignSchedules.push({
            dayOfWeek,
            startTime,
            endTime,
          })

          startTime = startOfHour
        }

        endTime = endOfHour

        if (index === currentSchedules.length - 1) {
          campaignSchedules.push({
            dayOfWeek,
            startTime,
            endTime,
          })
        }

        prevSchedule = schedule

        return campaignSchedules
      },
      [],
    )
    result = [...result, ...campaignSchedules]
  }

  return result
}

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
  actionButton: {
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
})

export interface CampaignFormData {
  id?: string
  name: string
  content: string
  landingURL: string
  isActive: boolean
  startAt: Date
  endAt: Date
  ads: AdvertisementInputFormData
  schedules: TimetableFormData[]
}

interface CampaignFormProps {
  defaultValues?: Campaign_CampaignFragment | null | undefined
  onSubmit(data: CampaignFormData): void
  onDelete?(e: SyntheticEvent): void
}

const CampaignForm: FC<CampaignFormProps> = ({
  defaultValues,
  onSubmit,
  onDelete: handleDelete,
}) => {
  const classes = useStyles()
  const {
    id,
    name,
    content,
    isActive,
    landingURL,
    startAt,
    endAt,
    ads,
    schedules,
  } = defaultValues || {}

  const methods = useForm<CampaignFormData>()
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    errors,
    control,
  } = methods

  const isEdit = !!id

  const schedulesDefaultValue = useMemo(() => {
    return schedules?.reduce<TimetableFormData[]>(
      (defaultValue, { dayOfWeek, startTime, endTime }) => {
        if (!dayOfWeek || !startTime || !endTime) {
          return defaultValue
        }

        const startHour = Number(startTime.split(':')[0]) ?? 0
        const endHour = Number(endTime.split(':')[0]) ?? 0

        const timetableData = range(startHour, endHour + 1).map<
          TimetableFormData
        >(hour => ({
          dayOfWeek,
          hour: hour.toString(),
        }))

        defaultValue.push(...timetableData)

        return defaultValue
      },
      [],
    )
  }, [schedules])

  const handleIsActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('isActive', e.target.checked)
  }

  const normalizedAds = ads?.map(ad => ({
    id: ad.id,
    adPlacementID: ad.adPlacement?.id ?? '',
    imageID: ad.image?.id ?? '',
  }))

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isActive"
                  defaultChecked={isActive ?? true}
                  inputRef={register}
                  onChange={handleIsActiveChange}
                />
              }
              label="캠페인 활성화 여부"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="캠페인 제목"
              variant="outlined"
              fullWidth
              required
              defaultValue={name}
              inputRef={register({ maxLength: 128 })}
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="content"
              label="캠페인 내용"
              variant="outlined"
              fullWidth
              defaultValue={content}
              inputRef={register}
              error={!!errors.content}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="landingURL"
              label="랜딩 URL"
              variant="outlined"
              fullWidth
              required
              defaultValue={landingURL}
              inputRef={register({ pattern: urlRegexIncludingProtocol })}
              error={!!errors.landingURL}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="startAt"
              label="광고 시작일"
              type="datetime-local"
              variant="outlined"
              fullWidth
              defaultValue={getDateString(startAt ?? new Date())}
              inputRef={register({
                validate: value => {
                  const startAt = new Date(value)
                  const endAt = new Date(getValues('endAt'))

                  if (startAt > endAt) {
                    return '광고 시작일은 종료일보다 이전 일자로 설정해주세요.'
                  }

                  return true
                },
              })}
              InputLabelProps={{ shrink: true }}
              error={!!errors.startAt}
            />
            {errors.startAt && (
              <Typography variant="caption" color="error">
                {errors.startAt.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="endAt"
              label="광고 종료일"
              type="datetime-local"
              variant="outlined"
              fullWidth
              defaultValue={getDateString(endAt ?? new Date())}
              inputRef={register}
              InputLabelProps={{ shrink: true }}
              error={!!errors.endAt}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="ads"
              control={control}
              render={({ onChange: handleChange }) => (
                <AdvertisementInput
                  label="지면별 광고 설정"
                  defaultValue={normalizedAds}
                  onChange={handleChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="schedules"
              render={({ onChange: handleChange }) => (
                <TimetableInput
                  label="노출 요일 및 시간대 설정"
                  fullChecked
                  onChange={handleChange}
                  defaultValue={schedulesDefaultValue}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            {isEdit && (
              <Button
                className={classes.actionButton}
                variant="contained"
                onClick={handleDelete}
              >
                삭제
              </Button>
            )}
            <Button
              className={classes.actionButton}
              type="submit"
              color="secondary"
              variant="contained"
            >
              저장
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default CampaignForm
