import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { CampaignStatisticsByDate, calculateCTR } from '../CampaignReport'

const useTotalCountsRowStyles = makeStyles({
  totalCountsRow: {
    backgroundColor: '#c6deec',
    '& th': {
      padding: '5px',
    },
  },
})

interface TotalCountsRowProps {
  totalImpressionCount: number
  totalClickCount: number
}

const TotalCountsRow: FC<TotalCountsRowProps> = ({
  totalImpressionCount,
  totalClickCount,
}) => {
  const classes = useTotalCountsRowStyles()

  const totalCTR = calculateCTR({
    impressionCount: totalImpressionCount,
    clickCount: totalClickCount,
  })

  return (
    <tr className={classes.totalCountsRow}>
      <th>총합</th>
      <th align="right">{totalImpressionCount}</th>
      <th align="right">{totalClickCount}</th>
      <th align="right">{totalCTR.toFixed(3)}%</th>
    </tr>
  )
}

const useStyles = makeStyles({
  tableRow: {
    '& th': {
      backgroundColor: '#c6deec',
    },
    '& td': {
      padding: '5px',
    },
  },
})

interface CampaignReportTableRowProps {
  data: CampaignStatisticsByDate
}

const CampaignReportTableRow: FC<CampaignReportTableRowProps> = ({ data }) => {
  const classes = useStyles()
  const { campaignStatistics, totalImpressionCount, totalClickCount } = data

  return (
    <>
      {campaignStatistics.map(
        (
          { date, adPlacementName, impressionCount, clickCount, ctr },
          index,
          campaignStatistics,
        ) => {
          const isCampaignStatisticLast =
            index === campaignStatistics.length - 1

          if (index === 0) {
            return (
              <>
                <tr key={index} className={classes.tableRow}>
                  <th rowSpan={campaignStatistics.length + 1}>
                    {format(date, 'yyyy년 MM월 dd일')}
                  </th>
                  <td>{adPlacementName}</td>
                  <td align="right">{impressionCount}</td>
                  <td align="right">{clickCount}</td>
                  <td align="right">{ctr}%</td>
                </tr>
                {isCampaignStatisticLast && (
                  <TotalCountsRow
                    totalImpressionCount={totalImpressionCount}
                    totalClickCount={totalClickCount}
                  />
                )}
              </>
            )
          }

          return (
            <>
              <tr key={index} className={classes.tableRow}>
                <td>{adPlacementName}</td>
                <td align="right">{impressionCount}</td>
                <td align="right">{clickCount}</td>
                <td align="right">{ctr}%</td>
              </tr>
              {isCampaignStatisticLast && (
                <TotalCountsRow
                  totalImpressionCount={totalImpressionCount}
                  totalClickCount={totalClickCount}
                />
              )}
            </>
          )
        },
      )}
    </>
  )
}

export default CampaignReportTableRow
