import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import DevToolsDashboard from './dashboard'

const DevToolsRouter: FC = () => {
  return (
    <Switch>
      <Route path="/devtools/dashboard">
        <DevToolsDashboard />
      </Route>
    </Switch>
  )
}

export default DevToolsRouter
