import React, { FC } from 'react'
import { Formik, Form } from 'formik'
import { useTranslate, useRefresh } from 'react-admin'
import { makeStyles, TextField, Button, Box } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useMutation } from '@apollo/react-hooks'
import {
  UpdateCoverLetterFieldByQueryMutationVariables,
  UpdateCoverLetterFieldByQueryMutation,
  UpdateCoverLetterFieldByQueryDocument,
  SearchExactCoverLetterDocument,
  CoverLetterField,
} from 'generated/graphql'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

interface UpdateOrganizationNameFormProps {
  query: string
  field: CoverLetterField
}

const UpdateOrganizationNameForm: FC<UpdateOrganizationNameFormProps> = props => {
  const { query, field } = props
  const classes = useStyles()
  const translate = useTranslate()
  const refresh = useRefresh()
  const [updateCoverLetterFieldByQuery] = useMutation<
    UpdateCoverLetterFieldByQueryMutation,
    UpdateCoverLetterFieldByQueryMutationVariables
  >(UpdateCoverLetterFieldByQueryDocument, {
    onCompleted: refresh,
  })

  return (
    <Formik
      initialValues={{ nextName: '' }}
      onSubmit={async (values, actions) => {
        try {
          await updateCoverLetterFieldByQuery({
            refetchQueries: [
              {
                query: SearchExactCoverLetterDocument,
                variables: { query: values.nextName, field },
              },
            ],
            variables: {
              input: {
                newName: values.nextName,
                field: CoverLetterField[field],
                oldName: query,
              },
            },
          })
          actions.setSubmitting(false)
          alert('변경 완료')
        } catch (error) {
          alert('변경 실패')

          console.log(error)
        }
      }}
    >
      {({ isSubmitting, values, handleChange, handleBlur }) => {
        return (
          <Form className={classes.root}>
            <ArrowForwardIcon />
            <Box ml={1.5}>
              <TextField
                label={translate('resources.cover-letters.update-field.name')}
                name="nextName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nextName}
                variant="filled"
                size="small"
                required
                disabled={isSubmitting}
                helperText={translate(
                  'resources.cover-letters.update-field.updateForm.helperText',
                )}
              />
            </Box>

            <Box ml={2}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
              >
                {translate(
                  'resources.cover-letters.update-field.updateForm.submit',
                )}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateOrganizationNameForm
