import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import CampaignListPage from './list'
import CampaignShowPage from './show'
import CampaignCreatePage from './create'
import CampaignEditPage from './edit'

const CampaignsRouter: FC = () => {
  return (
    <Switch>
      <Route path="/advertisement/campaigns/list">
        <CampaignListPage />
      </Route>
      <Route path="/advertisement/campaigns/:id/show">
        <CampaignShowPage />
      </Route>
      <Route path="/advertisement/campaigns/create">
        <CampaignCreatePage />
      </Route>
      <Route path="/advertisement/campaigns/:id">
        <CampaignEditPage />
      </Route>
    </Switch>
  )
}

export default CampaignsRouter
