export { default as PlacementList } from './PlacementList'
export { default as PlacementCreate } from './PlacementCreate'
export { default as PlacementEdit } from './PlacementEdit'

import gql from 'graphql-tag'

gql`
  fragment Placement_adPlacement on AdPlacement {
    id
    code
    name
    width
    height
    isActive
    defaultImage {
      id
    }
    defaultLandingURL
    createdAt
    updatedAt
  }

  query PlacementGetOne($id: ID!) {
    adPlacement(id: $id) {
      ...Placement_adPlacement
    }
  }
`
