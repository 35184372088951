import React from 'react'

interface EnumCellCell {
  value?: any
  enumCellMap: object
}

const EnumCellCell: React.FC<EnumCellCell> = ({ value, enumCellMap }) => {
  const text = enumCellMap[value]

  return text || '-'
}

export default EnumCellCell
