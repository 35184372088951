import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    relative: {
      color: theme.palette.text.secondary,
      marginLeft: 4,
    },
  }),
)
import FormattedDateCell from './FormattedDateCell'
import FormattedTimeCell from './FormattedTimeCell'
import FormattedRelativeCell from './FormattedRelativeCell'

interface FormattedDateTimeCell {
  value?: any
}

type FormattedDateProps = typeof FormattedDate.defaultProps
type FormattedTimeProps = typeof FormattedTime.defaultProps

const FormattedDateTimeCell: React.FC<FormattedDateTimeCell &
  FormattedDateProps &
  FormattedTimeProps> = ({ value, ...props }) => {
  const classes = useStyles()
  if (!value) {
    return <span> - </span>
  }
  return (
    <span>
      <span>
        <FormattedDateCell value={value} {...props} />{' '}
        <FormattedTimeCell value={value} {...props} />
      </span>
      <span className={classes.relative}>
        (<FormattedRelativeCell value={value} />)
      </span>
    </span>
  )
}

export default FormattedDateTimeCell
