import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
  useRef,
} from 'react'
import gql from 'graphql-tag'
import {
  useCampaignReportDialog_CampaignQuery,
  CampaignReportDialog_CampaignQuery,
} from 'generated/graphql'
import { NetworkStatus } from '@apollo/client'
import Loading from 'components/Loading'
import { useSnackbar } from 'notistack'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  Chip,
  Typography,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Close as CloseIcon, Refresh as RefreshIcon } from '@material-ui/icons'
import { buildIndex } from 'utils'
import CampaignReport, {
  CampaignReportMethod,
  ReportViewType,
} from './CampaignReport'
import ExportCSVButton from 'components/Table/TableToolbar/ExportCSVButton'

const useStyles = makeStyles<Theme>(theme => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogTitleAndActions: {
    display: 'flex',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 10,

    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  selectWrap: {
    position: 'sticky',
    flexGrow: 0,
    flexShrink: 0,
  },
  select: {
    marginBottom: 20,
  },
  reportTabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    '&:last-child': {
      marginRight: 0,
    },
  },
  notExistAd: {
    textAlign: 'center',
  },
}))

export interface CampaignReportData {
  createdDate: string
  impressionCount: number
  clickCount: number
}

interface CampaignReportDialogProps extends DialogProps {
  data: CampaignReportDialog_CampaignQuery
  onClose?: () => void
}

const CampaignReportDialog: FC<CampaignReportDialogProps> = ({
  data,
  ...other
}) => {
  const { onClose: handleClose } = other
  const classes = useStyles()
  const [reportTabValue, setReportTabValue] = useState<ReportViewType>(
    ReportViewType.TABLE,
  )
  const campaignReportRef = useRef<CampaignReportMethod>(null)

  const campaign = data?.campaign
  const ads = campaign?.ads

  const placements = useMemo(
    () =>
      ads?.reduce<{ id: string; name: string }[]>((placements, ad) => {
        const placement = ad.adPlacement
        if (!placement) {
          return placements
        }

        placements.push({
          id: placement.id,
          name: placement.name,
        })
        return placements
      }, []) || [],
    [ads],
  )
  const [selectedPlacementIDs, setSelectedplacementIDs] = useState<string[]>(
    placements?.map(placement => placement.id) || [],
  )

  const placementIndex = useMemo(
    () => buildIndex(placements || [], placement => placement.id),
    [placements],
  )

  const handleRefreshClick = useCallback(() => {
    campaignReportRef.current?.refetch()
  }, [])

  const handlePlacementChange = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      setSelectedplacementIDs(e.target.value as string[])
    },
    [],
  )

  const handleReportTabChange = useCallback(
    (e: ChangeEvent<{}>, newValue: number) => {
      setReportTabValue(newValue)
    },
    [],
  )

  if (!campaign) {
    return null
  }

  return (
    <Dialog {...other}>
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitleAndActions}>
          <DialogTitle className={classes.dialogTitle}>
            {campaign?.name}
          </DialogTitle>
          <DialogActions>
            <Tooltip title="새로고침">
              <IconButton
                className={classes.iconButton}
                onClick={handleRefreshClick}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <ExportCSVButton
              title={`${campaign.name}-report`}
              // eslint-disable-next-line require-await
              getExportData={async () => {
                const csvData = campaignReportRef.current?.generateReport()
                return csvData || []
              }}
            />
          </DialogActions>
        </div>
        <DialogActions>
          <Tooltip title="닫기">
            <IconButton className={classes.iconButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </div>
      <DialogContent className={classes.selectWrap}>
        <Select
          className={classes.select}
          fullWidth
          multiple
          value={selectedPlacementIDs}
          onChange={handlePlacementChange}
          renderValue={selected =>
            (selected as string[]).map(placementID => {
              const placement = placementIndex.get(placementID)

              if (!placement) {
                return null
              }

              return (
                <Chip
                  className={classes.chip}
                  key={placement.id}
                  label={placement?.name}
                />
              )
            })
          }
        >
          {placements.map(placement => (
            <MenuItem key={placement.id} value={placement.id}>
              {placement.name}
            </MenuItem>
          ))}
        </Select>
        <Tabs
          className={classes.reportTabs}
          variant="fullWidth"
          centered
          value={reportTabValue}
          onChange={handleReportTabChange}
        >
          <Tab
            key={ReportViewType.TABLE}
            label="표"
            value={ReportViewType.TABLE}
          />
          <Tab
            key={ReportViewType.CHART}
            label="그래프"
            value={ReportViewType.CHART}
          />
        </Tabs>
      </DialogContent>
      <DialogContent>
        {placements?.length > 0 ? (
          <CampaignReport
            ref={campaignReportRef}
            campaignID={campaign.id}
            adPlacementIDs={selectedPlacementIDs}
            viewType={reportTabValue}
          />
        ) : (
          <Typography className={classes.notExistAd}>
            설정된 광고가 없습니다.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}

interface CampaignReportDialogContainerProps extends DialogProps {
  campaignID: string
  onClose?: () => void
}

const CampaignReportDialogContainer: FC<CampaignReportDialogContainerProps> = ({
  campaignID,
  ...other
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueApolloError } = useErrorSnackbar()
  const { data, networkStatus } = useCampaignReportDialog_CampaignQuery({
    variables: {
      id: campaignID,
    },
    onCompleted: data => {
      if (!data.campaign) {
        enqueueSnackbar('캠페인이 존재하지 않습니다.', { variant: 'error' })
      }
    },
    onError: enqueueApolloError,
  })

  if (!data || networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  return <CampaignReportDialog data={data} {...other} />
}

export default CampaignReportDialogContainer

gql`
  query CampaignReportDialog_Campaign($id: ID!) {
    campaign(id: $id) {
      id
      name
      ads {
        adPlacement {
          id
          name
        }
      }
    }
  }
`
