import gql from 'graphql-tag'
import { oc } from 'ts-optchain'
import {
  useAttachmentPrepareUploadMutation,
  useAttachmentCompleteUploadMutation,
  UseAttachmentFileUpload_AttachmentFragment,
} from 'generated/graphql'
import { optimizeImage } from 'utils/fileUpload'
interface useAttachmentFileUploadArgs {
  onAttachmentFileAdd?(
    attachment: UseAttachmentFileUpload_AttachmentFragment,
  ): void
  path?: string
  useFileName?: boolean
}

export const ADVERTISEMENT = 'activity_manager/advertisement'

function useAttachmentFileUpload(args?: useAttachmentFileUploadArgs) {
  const { onAttachmentFileAdd, path, useFileName = false } = args || {}
  const [attachmentPrepareUpload] = useAttachmentPrepareUploadMutation()
  const [attachmentCompleteUpload] = useAttachmentCompleteUploadMutation()

  async function fileUpload(file: File) {
    if (!file) {
      return undefined
    }

    const { name, size, type } = file

    if (type.split('/')[0] === 'image') {
      // 이미지 파일이라면 최적화 진행
      file = await optimizeImage(file)
    }

    // prepare upload
    const { data } = await attachmentPrepareUpload({
      variables: {
        name,
        size,
        type,
        path: path || ADVERTISEMENT,
        useFileName,
      },
    })

    const attachmentPrepareUploadData = data && data.attachmentPrepareUpload
    if (!attachmentPrepareUploadData) {
      throw new Error('invalid attachmentPrepareUpload')
    }

    const { signedURL, attachment } = attachmentPrepareUploadData

    // S3 upload
    const s3UploadOption = {
      method: 'PUT',
      body: file,
    }

    await fetch(signedURL, s3UploadOption)

    // update file upload status
    const result = await attachmentCompleteUpload({
      variables: { attachmentID: attachment.id },
    })

    if (onAttachmentFileAdd) {
      const newAttachment = oc(result).data.attachmentCompleteUpload.attachment(
        null,
      )
      newAttachment && onAttachmentFileAdd(newAttachment)
    }

    return attachment
  }

  return {
    fileUpload,
  }
}

export default useAttachmentFileUpload

gql`
  mutation AttachmentPrepareUpload(
    $name: String!
    $size: Int!
    $type: String!
    $path: String
    $useFileName: Boolean
  ) {
    attachmentPrepareUpload(
      name: $name
      size: $size
      type: $type
      path: $path
      useFileName: $useFileName
    ) {
      attachment {
        id
        url
      }
      signedURL
    }
  }

  mutation AttachmentCompleteUpload($attachmentID: ID!) {
    attachmentCompleteUpload(attachmentID: $attachmentID) {
      attachment {
        id
        ...useAttachmentFileUpload_attachment
      }
    }
  }

  fragment useAttachmentFileUpload_attachment on Attachment {
    id
    url
    name
    type
    size
  }
`
