import gql from 'graphql-tag'
import {
  ActivityGetOneDocument,
  ActivityGetListDocument,
  ActivityGetManyDocument,
  ActivityUpdateDocument,
  ActivitiesStatusUpdateDocument,
  ActivityUpdateMutationVariables,
  ActivitiesStatusUpdateMutationVariables,
  ActivityDeleteDocument,
  ActivitiesDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
  getOne: {
    query: ActivityGetOneDocument,
  },
  getList: {
    query: ActivityGetListDocument,
  },
  getMany: {
    query: ActivityGetManyDocument,
  },
  update: {
    query: ActivityUpdateDocument,
    variables(id, args): ActivityUpdateMutationVariables {
      return {
        input: {
          id,
          ...args,
        },
      }
    },
  },
  updateMany: {
    query: ActivitiesStatusUpdateDocument,
    variables(ids, args): ActivitiesStatusUpdateMutationVariables {
      return {
        input: {
          ids,
          ...args,
        },
      }
    },
  },
  delete: {
    query: ActivityDeleteDocument,
  },
  deleteMany: {
    query: ActivitiesDeleteDocument,
  },
}

gql`
  fragment ActivityFragment on Activity {
    id
    activityTypeID
    title
    recruitCloseAt # 모집마감일
    createdAt
    status
    refusalReason # 반려 사유
    organizationName
    manager {
      username
      email
      phoneNumber
    }
    statusUpdatedAt
  }
`

gql`
  query ActivityGetOne($id: ID!) {
    data: activity(id: $id) {
      ...ActivityFragment
    }
  }
`

gql`
  query ActivityGetList(
    $filterBy: ActivityFilter
    $orderBy: ActivityOrder
    $pagination: Pagination
  ) {
    data: activities(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ActivityFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ActivityGetMany($ids: [ID!]!) {
    data: activitiesByID(ids: $ids) {
      ...ActivityFragment
    }
  }
`

gql`
  mutation ActivityUpdate($input: ActivityUpdateInput!) {
    data: activityUpdate(input: $input) {
      data: activity {
        ...ActivityFragment
      }
    }
  }
`

gql`
  mutation ActivitiesStatusUpdate($input: ActivitiesStatusUpdateInput!) {
    data: activitiesStatusUpdate(input: $input) {
      data: count
    }
  }
`

gql`
  mutation ActivityDelete($id: ID!) {
    data: activityDelete(id: $id) {
      data: activity {
        ...ActivityFragment
      }
    }
  }
`

gql`
  mutation ActivitiesDelete($ids: [ID!]!) {
    data: activitiesDelete(ids: $ids) {
      data: activities {
        id
      }
    }
  }
`
