import React, { FC } from 'react'
import { useUsersByEmailLazyQuery } from 'generated/graphql'
import { useForm } from 'react-hook-form'
import { Box, TextField, Button, makeStyles } from '@material-ui/core'
import Profiles from './Profiles'

const useStyles = makeStyles({
  submit: {
    marginLeft: 5,
  },
})

interface FormInputs {
  email: string
}

const ProfileImageEditor: FC = () => {
  const classes = useStyles()
  const [getUsers, { loading, data, refetch }] = useUsersByEmailLazyQuery()
  const { register, handleSubmit } = useForm<FormInputs>()

  const onSubmit = (formData: FormInputs) => {
    getUsers({
      variables: {
        email: formData.email,
      },
    })
  }

  const users = data?.usersByEmail

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex">
          <TextField
            name="email"
            label="email"
            variant="outlined"
            inputRef={register}
            fullWidth
          />
          <Button
            type="submit"
            className={classes.submit}
            variant="contained"
            color="secondary"
          >
            조회
          </Button>
        </Box>
      </form>
      <Profiles loading={loading} users={users} refetch={refetch} />
    </div>
  )
}

export default ProfileImageEditor
