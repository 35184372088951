import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import useGenerateCSV from 'hooks/useGenerateCSV'

interface ExportCSVButtonProps {
  title: string
  getExportData?: () => Promise<Array<object>>
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = (
  props: ExportCSVButtonProps,
) => {
  const { title, getExportData } = props
  const [loading, setLoading] = React.useState()
  const generateCSV = useGenerateCSV()

  async function handleClickDownloadCSV() {
    if (!getExportData) return
    setLoading(true)
    const data = await getExportData()
    generateCSV(data, title)
    setLoading(false)
  }

  if (!getExportData) {
    return null
  }

  return (
    <Tooltip title="CSV 파일 다운로드">
      <Button
        color="secondary"
        onClick={handleClickDownloadCSV}
        startIcon={<GetAppIcon />}
        disabled={loading}
      >
        csv
      </Button>
    </Tooltip>
  )
}

export default ExportCSVButton
