import React, { FC } from 'react'
import { Edit as EditIcon, Visibility as ShowIcon } from '@material-ui/icons'
import { Button, makeStyles, Link } from '@material-ui/core'

import { LINKAREER_NEXT_CLIENT_BASE_URL } from '../../config'

enum ActivityType {
  ACTIVITY = 1,
  CLUB = 2,
  CONTEST = 3,
  INTERN = 4,
  RECRUIT = 5,
  EDUCATION = 6,
}

const useStyles = makeStyles({
  customButton: {
    textDecoration: 'none',
  },
  cusomButtonIcon: {
    width: 20,
    marginRight: 5,
  },
})

interface CustomButtonProps {
  record?: any
  source?: string
  label?: string
  headerClassName?: string
}

export const CustomShowButton: FC<CustomButtonProps> = ({ record }) => {
  const classes = useStyles({})
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className={classes.customButton}
      href={`${LINKAREER_NEXT_CLIENT_BASE_URL}/activity/${record.id}`}
    >
      <Button color="primary">
        <ShowIcon className={classes.cusomButtonIcon} />
        보기
      </Button>
    </Link>
  )
}

export const CustomEditButton: FC<CustomButtonProps> = ({
  record: activity,
}) => {
  const ACTIVITY_TYPE_ID_NAME: {
    [key in ActivityType]: string
  } = {
    [ActivityType.ACTIVITY]: 'extra',
    [ActivityType.CLUB]: 'club',
    [ActivityType.CONTEST]: 'contest',
    [ActivityType.INTERN]: 'intern',
    [ActivityType.RECRUIT]: 'recurit',
    // TODO
    // 파이썬 레거시에 따라 extra로 설정
    [ActivityType.EDUCATION]: 'extra',
  }

  const activityType = ACTIVITY_TYPE_ID_NAME[activity.activityTypeID]

  const classes = useStyles({})

  return (
    (activityType && (
      <Link
        target="_blank"
        rel="noopener noreferrer"
        className={classes.customButton}
        href={`${LINKAREER_NEXT_CLIENT_BASE_URL}/activity/${activity.id}/edit`}
      >
        <Button color="primary">
          <EditIcon className={classes.cusomButtonIcon} />
          수정
        </Button>
      </Link>
    )) ||
    null
  )
}
