import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import PlacementListPage from './list'
import PlacementCreatePage from './create'
import PlacementEditPage from './edit'

const PlacementsRouter: FC = () => {
  return (
    <Switch>
      <Route path="/advertisement/placements/list">
        <PlacementListPage />
      </Route>
      <Route path="/advertisement/placements/create">
        <PlacementCreatePage />
      </Route>
      <Route path="/advertisement/placements/:id">
        <PlacementEditPage />
      </Route>
    </Switch>
  )
}

export default PlacementsRouter
