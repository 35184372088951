import React, { FunctionComponent } from 'react'
import KibanaField, { KibanaFieldProps } from './KibanaField'

interface SessionFieldProps extends Omit<KibanaFieldProps, 'esField'> {}

const SessionField: FunctionComponent<SessionFieldProps> = props => {
  return <KibanaField {...props} esField="sessionID" />
}

SessionField.defaultProps = {
  addLabel: true,
}

export default SessionField
