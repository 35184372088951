import React from 'react'
import { oc } from 'ts-optchain'
import { useForm } from 'react-hook-form'
import { Button, Divider, Link, Paper, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import FileInput from 'components/FileInput'
import useAttachmentFileUpload from 'hooks/useAttachmentFileUpload'
import { AWS_S3_BUCKET } from 'config'

const useStyles = makeStyles({
  root: {
    margin: '20px 0',
    maxHeight: '100vh',
    height: '100%',
    padding: 50,
  },
  divider: {
    margin: '30px 0',
  },
  fileInput: {
    marginBottom: 30,
    width: '50%',
  },
  preview: {
    marginBottom: 30,
    '& p': {
      fontSize: 15,
    },
  },
})

interface FormData {
  mediaIntroduction: any[]
}

const FILE_NAME = 'linkareer_2005.pdf'

const uploadedFileURL = `https://s3.ap-northeast-2.amazonaws.com/${AWS_S3_BUCKET}/business/${FILE_NAME}`

const MediaIntroduction = () => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { fileUpload } = useAttachmentFileUpload({
    path: 'business',
    useFileName: true,
  })

  const onSubmit = async (data: FormData) => {
    const { mediaIntroduction: files } = data

    const file = oc(files)[0](undefined) as File
    if (file) {
      const blob = file.slice(0, file.size, file.type)
      const mediaIntroduction = new File([blob], FILE_NAME, {
        type: file.type,
      })

      await fileUpload(mediaIntroduction)
    }

    enqueueSnackbar('매체소개서가 저장되었습니다.')
    window.location.reload()
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">{'광고관리 > 매체소개서'}</Typography>
      <Divider className={classes.divider} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FileInput
          className={classes.fileInput}
          id="media-introduction-edit"
          name="mediaIntroduction"
          register={register({ required: true })}
          placeholder="매체소개서 파일 업로드 *"
          inputProps={{ accept: '.pdf' }}
          error={!!errors.mediaIntroduction}
          errorText="매체소개서 파일을 올려주세요."
        />
        <div className={classes.preview}>
          <Typography>현재 매체소개서 :</Typography>
          <Link href={uploadedFileURL}>
            <Typography>{uploadedFileURL}</Typography>
          </Link>
        </div>

        <Button type="submit" color="secondary" variant="contained">
          저장
        </Button>
      </form>
    </Paper>
  )
}

export default MediaIntroduction
