import React, { FC, useState } from 'react'
import gql from 'graphql-tag'
import { Formik } from 'formik'
import { Button, Grid, makeStyles, CircularProgress } from '@material-ui/core'
import {
  List,
  Filter,
  TextInput,
  DateField,
  Datagrid,
  TextField,
  SelectField,
  SelectInput,
  useRefresh,
} from 'react-admin'

import {
  ActivityStatus,
  useActivityJudgeMutation,
  useInvalidateActivityCacheMutation,
} from 'generated/graphql'
import { FormDialog, DateDialog, BulkDeleteButton } from 'components'
import { CustomEditButton, CustomShowButton } from './CustomButtons'
import { ResourceType } from 'resources'
import { useSnackbar } from 'notistack'

export enum ActivityType {
  Activity = 'Activity',
  Club = 'Club',
  Contest = 'Contest',
  Intern = 'Intern',
  Recruit = 'Recruit',
  Education = 'Education'
}

export const activityStatusChoices = Object.keys(ActivityStatus).map(type => ({
  id: type,
  name: `enum.ActivityStatus.${type}`,
}))

export const activityTypeChoices = Object.keys(ActivityType).map(
  (type, index) => ({
    id: index + 1,
    name: `enum.ActivityType.${type}`,
  }),
)

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
})

const useListStyles = makeStyles({
  main: {
    width: '100%',
  },
  content: {
    width: 'inherit',
  },
})

const ActivitiesFilter: FC = props => {
  const classes = useStyles()

  return (
    <>
      <Filter {...props}>
        <TextInput label="msg.search" source="q" alwaysOn />
        <SelectInput
          source="status"
          label="resources.activities.fields.status"
          choices={[
            { id: '', name: '전체' },
            { id: 'TEMP', name: '임시저장' },
            { id: 'OPEN', name: '승인' },
            { id: 'PENDING', name: '승인 대기' },
            { id: 'REJECTED', name: '반려' },
            { id: 'REJECTED_ETC', name: '반려/기타' },
          ]}
          alwaysOn
        />
        <DateDialog label="등록일로 검색" source="createdAtTerm" alwaysOn />
        <SelectInput
          className={classes.hidden}
          source="activityTypeID"
          label="resources.activities.fields.type"
          choices={[
            { id: '', name: '전체' },
            { id: '1', name: '대외활동' },
            { id: '2', name: '동아리' },
            { id: '3', name: '공모전' },
            { id: '5', name: '채용' },
            {id : '6', name : '교육'},
          ]}
          alwaysOn
        />
      </Filter>
    </>
  )
}

interface ActivityBulkActionButtons {
  basePath?: string
  selectedIds?: string[]
}

interface Values {
  refusalReason?: string | null
}

const ActivityBulkActionButtons: FC<ActivityBulkActionButtons> = props => {
  const { selectedIds } = props
  const [judgeActivity] = useActivityJudgeMutation()
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClick = async (
    status: ActivityStatus,
    refusalReason?: string,
  ) => {
    if (!selectedIds || selectedIds.length === 0)
      throw Error('공고를 선택해주세요.')
    if (!status) throw Error('공고를 심사해주세요.')

    await judgeActivity({
      variables: {
        input: {
          ids: selectedIds,
          status,
          refusalReason,
        },
      },
    })

    refresh()
  }

  return (
    <>
      <Grid container>
        <Button
          color="primary"
          onClick={() => handleClick(ActivityStatus.OPEN)}
        >
          승인
        </Button>
        <Button
          color="primary"
          onClick={() => handleClick(ActivityStatus.PENDING)}
        >
          대기
        </Button>
        <Button color="primary" onClick={handleClickOpen}>
          반려
        </Button>
        {/* DB에서 row를 삭제하는 경우 useDeleteMany 훅을 사용한 커스텀 버튼을 이용 */}
        <BulkDeleteButton
          resource={ResourceType.Activities}
          selectedIds={selectedIds}
        />
      </Grid>
      <Formik<Values>
        initialValues={{ refusalReason: '' }}
        onSubmit={async (values, actions) => {
          await handleClick(ActivityStatus.REJECTED, values.refusalReason || '')
          actions.setSubmitting(false)
          handleClose()
        }}
      >
        {() => {
          return (
            <FormDialog
              disablePortal
              open={open}
              onClose={handleClose}
              title="반려 사유"
              message="반려 사유를 입력해주세요. 사유가 없다면 공란으로 남겨두고 확인 버튼을 눌러주세요."
              fieldName="refusalReason"
            />
          )
        }}
      </Formik>
    </>
  )
}

export interface ActivityListProps {
  basePath: string
  resource: string
  location: any
  options?: any
  match?: any
  record?: any
}

const activityTypeToID = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.Activity:
      return '1'
    case ActivityType.Club:
      return '2'
    case ActivityType.Contest:
      return '3'
    case ActivityType.Recruit:
      return '5'
      case ActivityType.Education:
        return '6'
    default:
      return ''
  }
}

interface InvalidateCacheProps {
  record?: any
}

const InvalidateCache: FC<InvalidateCacheProps> = props => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [invalidate, { loading }] = useInvalidateActivityCacheMutation({
    variables: {
      id: props.record.id,
    },
    onCompleted: data => {
      enqueueSnackbar(
        `/activity/${data?.invalidateActivityCache?.id} 캐시 삭제 요청 완료`,
      )
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
  })

  const handleOnClick = () => {
    invalidate()
  }

  return (
    <div className={classes.wrapper}>
      <Button variant="outlined" onClick={handleOnClick} disabled={loading}>
        캐시삭제
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

const ActivityList: FC<ActivityListProps> = props => {
  const classes = useListStyles()

  return (
    <>
      <List
        {...props}
        classes={classes}
        filter={{
          activityTypeID: activityTypeToID(props.options.activityType),
          withClosed: true, // 마감된 공고까지 포함
        }}
        filters={<ActivitiesFilter />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActionButtons={<ActivityBulkActionButtons />}
      >
        <Datagrid>
          <TextField source="id" sortable={false} />
          <SelectField
            source="activityTypeID"
            sortable={false}
            choices={activityTypeChoices}
          />
          <TextField source="title" sortable={false} />
          <CustomEditButton record={props.record} />
          <CustomShowButton record={props.record} />
          <TextField source="organizationName" sortable={false} />
          <TextField source="manager.username" sortable={false} />
          <TextField source="manager.email" sortable={false} />
          <TextField source="manager.phoneNumber" sortable={false} />
          <SelectField
            source="status"
            sortable={false}
            choices={activityStatusChoices}
          />
          <TextField source="refusalReason" sortable={false} />
          <DateField source="recruitCloseAt" />
          <DateField source="createdAt" />
          <DateField source="statusUpdatedAt" showTime />
          <InvalidateCache />
        </Datagrid>
      </List>
    </>
  )
}

export default ActivityList

gql`
  mutation ActivityJudge($input: ActivitiesStatusUpdateInput!) {
    activitiesStatusUpdate(input: $input) {
      activities {
        ...ActivityFragment
      }
      count
    }
  }

  mutation InvalidateActivityCache($id: ID!) {
    invalidateActivityCache(id: $id) {
      id
    }
  }
`
