import React, { FunctionComponent } from 'react'
import { Layout as ReactAdminLayout } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from './AppBar'
import Menu from './Menu'

const useStyles = makeStyles({
  content: {
    width: 0,
  },
})

const Layout: FunctionComponent = props => {
  const classes = useStyles()

  return (
    <ReactAdminLayout
      {...props}
      appBar={AppBar}
      menu={Menu}
      classes={classes}
    />
  )
}

export default Layout
