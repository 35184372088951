import React, { FC } from 'react'
import { SelectArrayInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { InputProps } from 'ra-core'
import { CoverLetterType } from 'generated/graphql'

const useStyles = makeStyles({
  input: {
    minWidth: 200,
    marginTop: 26,
  },
})

const coverLetterTypeChoices = Object.keys(CoverLetterType).map(type => {
  if (type !== CoverLetterType.ALL) {
    return {
      id: type,
      name: `enum.CoverLetterType.${type}`,
    }
  }
})

interface Props extends Omit<InputProps, 'source'> {
  source?: string
}

const CoverLetterTypesInput: FC<Props> = props => {
  const classes = useStyles()

  return (
    <SelectArrayInput
      {...props}
      choices={coverLetterTypeChoices}
      className={classes.input}
    />
  )
}

CoverLetterTypesInput.defaultProps = {
  source: 'types',
}

export default CoverLetterTypesInput
