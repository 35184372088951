import React, { FC, useCallback } from 'react'
import {
  UsersByEmailFragment,
  useUserProfileImageDeleteMutation,
} from 'generated/graphql'
import { makeStyles, Paper, Button } from '@material-ui/core'

const useStyles = makeStyles({
  profileImage: {
    width: '100%',
    height: '200px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  userInfo: {
    padding: 10,
    margin: 0,
    fontSize: 14,

    '& dt': {
      fontWeight: 'bold',
      minWidth: '4em',
      float: 'left',
      clear: 'both',
    },
    '& dd': {
      marginRight: 10,
      marginBottom: 5,
      overflow: 'auto',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
})

interface ProfileCardProps {
  user: UsersByEmailFragment
  refetch: any
}

const ProfileCard: FC<ProfileCardProps> = ({ user, refetch }) => {
  const classes = useStyles()
  const [userProfileImageDelete] = useUserProfileImageDeleteMutation()

  const handleDeleteImageClick = useCallback(async () => {
    const userProfileImageFileID = user.profile?.identificationImage?.id
    if (!userProfileImageFileID) {
      return
    }

    await userProfileImageDelete({
      variables: {
        id: userProfileImageFileID,
      },
    })
    await refetch()
  }, [refetch])

  return (
    <Paper>
      <div
        className={classes.profileImage}
        style={{
          backgroundImage: `url(${user.profile?.identificationImage?.url})`,
        }}
      />
      <dl className={classes.userInfo}>
        <dt>ID</dt>
        <dd>{user.id}</dd>
        <dt>이름</dt>
        <dd>{user.username}</dd>
        <dt>이메일</dt>
        <dd>{user.email}</dd>
      </dl>
      <div className={classes.actions}>
        <Button variant="contained" onClick={handleDeleteImageClick}>
          프로필 이미지 삭제
        </Button>
      </div>
    </Paper>
  )
}

export default ProfileCard
