import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import jsonExport from 'jsonexport/dist'
import omit from 'lodash/omit'
import { TIMEZONE } from 'config'

const EXPORT_BLACK_LIST = ['__typename']

function downloadCSV(csv: string, filename: string) {
  const fakeLink = document.createElement('a')
  fakeLink.style.display = 'none'
  document.body.appendChild(fakeLink)
  const blob = new Blob([csv], { type: 'text/csv' })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`)
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob))
    fakeLink.setAttribute('download', `${filename}.csv`)
    fakeLink.click()
  }
}

function useGenerateCSV() {
  async function generateCSV(data?: Array<object>, name: string = 'data') {
    await jsonExport(
      (data || []).map(item => omit(item, EXPORT_BLACK_LIST)),
      (_err, csv) => {
        const timestamp = format(
          utcToZonedTime(new Date(), TIMEZONE),
          "yyyyMMdd'T'HHmmss",
        )
        downloadCSV(csv, `${name.replace(/\s/g, '_')}_${timestamp}`)
      },
    )
  }

  return generateCSV
}

export default useGenerateCSV
