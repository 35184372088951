import React, { FC } from 'react'
import ActivityList, { ActivityType, ActivityListProps } from '../ActivityList'

const ActivityRecruitList: FC<ActivityListProps> = ({ location, match }) => {
  return (
    <ActivityList
      resource="activities"
      options={{ activityType: ActivityType.Recruit }}
      location={location}
      basePath={match.url}
    />
  )
}

export default ActivityRecruitList
