import React, {
  FC,
  SyntheticEvent,
  useState,
  useCallback,
  ReactNodeArray,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { unflatten } from 'flat'
import queryString from 'query-string'
import gql from 'graphql-tag'
import { NetworkStatus } from '@apollo/client'
import {
  CampaignList_CampaignFragment,
  useCampaignListQuery,
  CampaignOrderField,
  OrderDirection,
  CampaignListQueryVariables,
  AdStatus,
} from 'generated/graphql'
import {
  Box,
  Chip,
  Button,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Loading from 'components/Loading'
import Table, {
  FormattedDateTimeCell,
  BooleanCell,
  TableState,
} from 'components/Table'
import { createGlobalQueryField } from 'components/Table/FilterBar'
import Error from 'components/Error'
import CampaignReportDialog from './CampaignReportDialog'
import {
  useQueryStringVariables,
  paginationSchema,
  orderBySchema,
} from 'hooks/useVariablesQueryStringSync'

function useCampaignQueryStringVariables() {
  const [queryVariables, updateQueryString, validate] = useQueryStringVariables(
    {
      type: 'object',
      properties: {
        pagination: paginationSchema,
        orderBy: {
          ...orderBySchema,
          default: {
            field: CampaignOrderField.START_AT,
            direction: OrderDirection.DESC,
          },
        },

        filterBy: {
          default: {},
          type: 'object',
          properties: {
            q: { type: 'string' },
            status: { type: 'string', default: AdStatus.OPEN },
            isActive: { type: 'boolean', default: true },
          },
        },
      },
    },
  )

  return [queryVariables, updateQueryString, validate]
}

interface UseFilterUpdateHandlerProps {
  field: string
  updator: any
}

function useFilterUpdateHandler({
  field,
  updator,
}: UseFilterUpdateHandlerProps) {
  const location = useLocation()
  const prevQuery = unflatten(queryString.parse(location.search)) || {}

  return useCallback(
    (_, value) => {
      const filterBy: CampaignListQueryVariables['filterBy'] = {
        ...prevQuery.filterBy,
        [field]: value,
      }

      updator({
        ...prevQuery,
        filterBy,
      })
    },
    [field, updator, prevQuery],
  )
}

const useCampaignExtendedToolbarStyles = makeStyles({
  toolbox: {
    padding: '0 15px',
  },
})

const CampaignExtendedToolbar: FC<TableState> = () => {
  const classes = useCampaignExtendedToolbarStyles()
  const [{ filterBy }, updateQueryString] = useCampaignQueryStringVariables()
  const { q, status, isActive } = filterBy

  const { data, error, networkStatus } = useCampaignListQuery({
    variables: {
      filterBy: {
        ...(q && { q }),
        isActive,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSwitchChange = useFilterUpdateHandler({
    field: 'isActive',
    updator: updateQueryString,
  })

  const handleTabChange = useFilterUpdateHandler({
    field: 'status',
    updator: updateQueryString,
  })

  const campaignsGroupedByStatus = groupBy(
    data?.campaigns.nodes,
    campaign => campaign.status,
  )

  const tabList = [
    {
      name: `진행중(${campaignsGroupedByStatus[AdStatus.OPEN]?.length || 0})`,
      value: AdStatus.OPEN,
    },
    {
      name: `진행예정(${campaignsGroupedByStatus[AdStatus.RESERVED]?.length ||
        0})`,
      value: AdStatus.RESERVED,
    },
    {
      name: `종료(${campaignsGroupedByStatus[AdStatus.CLOSED]?.length || 0})`,
      value: AdStatus.CLOSED,
    },
  ]

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <>
      <Box className={classes.toolbox}>
        <FormControlLabel
          label={isActive ? '활성 캠페인 목록' : '비활성 캠페인 목록'}
          control={<Switch checked={isActive} name="isActive" />}
          onChange={handleSwitchChange}
        />
      </Box>
      <Tabs
        variant="fullWidth"
        centered
        value={status ?? AdStatus.OPEN}
        onChange={handleTabChange}
      >
        {tabList.map(tab => (
          <Tab key={tab.value} label={tab.name} value={tab.value} />
        ))}
      </Tabs>
    </>
  )
}

const useCampaignActionsStyles = makeStyles({
  button: {
    width: 100,
    marginBottom: 5,
    '$:last-child': {
      marginBottom: 0,
    },
  },
})

interface CampaignActionsProps {
  value: string
}

const CampaignActions: FC<CampaignActionsProps> = ({ value }) => {
  const history = useHistory()
  const classes = useCampaignActionsStyles()
  const [currentReportCampaignID, setCurrentReportCampaignID] = useState<
    string
  >()

  const isReportDialogOpen = !!currentReportCampaignID

  const handleEditClick = useCallback((campaignID: string) => {
    return (e: SyntheticEvent) => {
      e.stopPropagation()
      history.push(`/advertisement/campaigns/${campaignID}`)
    }
  }, [])

  const handleReportClick = useCallback((campaignID: string) => {
    return (e: SyntheticEvent) => {
      e.stopPropagation()
      setCurrentReportCampaignID(campaignID)
    }
  }, [])

  const handleReportDialogClose = useCallback(() => {
    setCurrentReportCampaignID(undefined)
  }, [])

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<EditIcon />}
        onClick={handleEditClick(value)}
      >
        수정
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<AssessmentIcon />}
        onClick={handleReportClick(value)}
      >
        리포트
      </Button>
      {currentReportCampaignID && (
        <CampaignReportDialog
          fullScreen
          maxWidth="lg"
          campaignID={currentReportCampaignID}
          open={isReportDialogOpen}
          onClose={handleReportDialogClose}
        />
      )}
    </>
  )
}

const useStyles = makeStyles({
  adPlacementChip: {
    margin: '0 5px 5px 0',
    '&:last-child': {
      margin: 0,
    },
  },
})

const CampaignList: FC = () => {
  // const history = useHistory()
  const classes = useStyles()
  const [queryVariables, updateQueryString] = useCampaignQueryStringVariables()

  const { data, error, variables, networkStatus } = useCampaignListQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
    },
    fetchPolicy: 'cache-and-network',
  })

  // const handleAdPlacementChipClick = useCallback((adPlacementID: string) => {
  //   return (e: SyntheticEvent) => {
  //     e.stopPropagation()
  //     history.push(`/advertisement/placements/${adPlacementID}`)
  //   }
  //
  // }, [])

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const filterFields: any = []
  if (variables.filterBy && variables.filterBy.q) {
    filterFields.push(createGlobalQueryField(variables.filterBy.q))
  }

  return (
    <Table<CampaignList_CampaignFragment>
      title="캠페인"
      hasCreate
      columns={[
        {
          Header: 'ID',
          minWidth: 40,
          maxWidth: 40,
          align: 'center',
          accessor: 'id' as const,
        },
        {
          Header: '제목',
          align: 'center',
          accessor: 'name' as const,
          sortField: CampaignOrderField.NAME,
        },
        {
          Header: '광고시작',
          maxWidth: 110,
          minWidth: 60,
          accessor: 'startAt' as const,
          Cell: FormattedDateTimeCell,
          defaultCanSort: true,
          sortField: CampaignOrderField.START_AT,
        },
        {
          Header: '광고종료',
          maxWidth: 110,
          minWidth: 60,
          accessor: 'endAt' as const,
          Cell: FormattedDateTimeCell,
          sortField: CampaignOrderField.END_AT,
        },
        {
          Header: '진행지면',
          accessor: originalRow => {
            const adPlacements = originalRow.ads.map(ad => ad.adPlacement)
            const adPlacementChips = adPlacements.reduce<ReactNodeArray>(
              (adPlacementChips, adPlacement) => {
                if (!adPlacement) return adPlacementChips
                adPlacementChips.push(
                  <Chip
                    key={adPlacement.id}
                    className={classes.adPlacementChip}
                    label={adPlacement.name}
                    // clickable
                    // onClick={handleAdPlacementChipClick(adPlacement.id)}
                  />,
                )
                return adPlacementChips
              },
              [],
            )

            return adPlacementChips
          },
        },
        {
          Header: '활성여부',
          maxWidth: 40,
          align: 'center',
          accessor: 'isActive' as const,
          Cell: BooleanCell,
        },
        {
          Header: '기능',
          maxWidth: 70,
          id: 'actions',
          align: 'center',
          accessor: 'id' as const,
          Cell: CampaignActions,
        },
      ]}
      data={get(data, 'campaigns.nodes', [])}
      pagination={queryVariables.pagination}
      orderBy={queryVariables.orderBy}
      pageCount={get(data, 'campaigns.totalCount', 0)}
      filterFields={filterFields}
      onStateChange={({ pagination, orderBy, filterFields = [] }) => {
        const filterBy: CampaignListQueryVariables['filterBy'] = {}
        filterFields.forEach(filter => {
          if (filter.filterType === 'global-query' && filter.value) {
            filterBy.q = filter.value
          }
        })

        filterBy.isActive = queryVariables?.filterBy?.isActive
        filterBy.status = queryVariables?.filterBy?.status ?? AdStatus.OPEN

        const nextVar = {
          pagination,
          orderBy,
          ...(isEmpty(filterBy) ? {} : { filterBy }),
        }

        updateQueryString(nextVar)
      }}
      loading={networkStatus === NetworkStatus.setVariables}
      extendedToolbar={CampaignExtendedToolbar}
    />
  )
}

export default CampaignList

gql`
  fragment CampaignList_campaign on Campaign {
    id
    name
    isActive
    startAt
    endAt
    status
    ads {
      id
      adPlacement {
        id
        name
      }
    }
  }

  query CampaignList(
    $filterBy: CampaignFilters
    $orderBy: CampaignOrder
    $pagination: Pagination
  ) {
    campaigns(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      nodes {
        ...CampaignList_campaign
      }
      totalCount
    }
  }
`
