import { IntlShape } from 'react-intl'
import englishMessages from 'ra-language-english'
import get from 'lodash/get'
import raMessages from './ra-messages'

export const messages: Record<string, string> = {
  ...raMessages,

  'resources.users.name': '일반회원',
  'resources.users.fields.type': '유저 타입',
  'resources.users.fields.username': '이름',
  'resources.users.fields.phoneNumber': '전화번호',
  'resources.users.fields.birthday': '생년월일',
  'resources.users.fields.timespread.id': '타임스프레드 ID',
  'resources.users.fields.timespread.cash': '타임스프레드 캐시',
  'resources.users.fields.facebook.id': '페이스북 계정 ID',
  'resources.users.fields.kakao.id': '카카오 계정 ID',
  'resources.users.fields.naver.id': '네이버 계정 ID',
  'resources.users.fields.createdAt': '가입일',
  'resources.users.fields.email': '이메일',
  'resources.users.fields.lastLoginAt': '최근 로그인',
  'resources.users.fields.lastLoginSession': '최근 로그인 세션',
  'resources.users.fields.specupJoined': '통합 회원 가입 여부',
  'resources.users.fields.specupJoinedAt': '계정 통합일',
  'resources.users.fields.specupJoinedSession': '통합 회원 가입 세션',
  'resources.users.fields.timespread.name': '이름',
  'resources.users.fields.timespread.email': '이메일',
  'resources.users.fields.timespread.birthday': '생년월일',
  'resources.users.fields.timespread.profileURL': '프로필 주소',
  'resources.users.fields.timespread.phoneNumber': '전화번호',
  'resources.users.fields.timespread.facebookID': '페이스북 계정 ID',
  'resources.users.fields.timespread.kakaoID': '카카오 계정 ID',
  'resources.users.fields.timespread.naverID': '네이버 계정 ID',
  'resources.users.fields.deactivated': '탈퇴 여부',
  'resources.users.fields.deletedAt': '탈퇴일',
  'resources.users.fields.signupSession.ipAddress': '회원가입 로그',
  'resources.signup-sessions.name': '통합회원가입 세션',
  'resources.signup-sessions.fields.user.id': '링커리어 유저',
  'resources.signup-sessions.fields.timespread.id': '타임스프레드 유저 ID',
  'resources.signup-sessions.fields.snsID': 'SNS ID',
  'resources.signup-sessions.fields.snsType': 'SNS 타입',
  'resources.signup-sessions.fields.displayName': 'SNS 이름',
  'resources.signup-sessions.fields.signup': '세션 회원 가입 여부',
  'resources.signup-sessions.fields.signupUser.id': '가입 회원',
  'resources.signup-sessions.fields.signupSession': '가입 세션',
  'resources.signup-sessions.fields.signupAt': '가입 완료 시각',
  'resources.signup-sessions.fields.createdAt': '세션 생성 시각',
  'resources.signup-sessions.fields.signupElapsedTimeInSeconds':
    '가입 소요 시간 (초)',

  'resources.organizations.name': '기업회원',
  'resources.organizations.list.name': '회원 목록',

  'resources.activities.name': '공고',
  'resources.activities.fields.id': 'ID',
  'resources.activities.fields.activityTypeID': '공고 유형',
  'resources.activities.fields.title': '공고명',
  'resources.activities.fields.recruitCloseAt': '모집 마감일',
  'resources.activities.fields.statusUpdatedAt': '최근 수정일',
  'resources.activities.fields.createdAt': '공고 등록일',
  'resources.activities.fields.status': '상태',
  'resources.activities.fields.refusalReason': '반려 사유',
  'resources.activities.fields.organizationName': '기업명',
  'resources.activities.fields.manager.username': '담당자명',
  'resources.activities.fields.manager.email': '담당자 이메일',
  'resources.activities.fields.manager.phoneNumber': '담당자 연락처',

  'resources.activities.total.name': '전체',
  'resources.activities.activity.name': '대외활동',
  'resources.activities.club.name': '동아리',
  'resources.activities.contest.name': '공모전',
  'resources.activities.recruit.name': '채용',
  'resources.activities.reply.name': '댓글',
  'resources.activities.education.name': '교육',
  'resources.replies.fields.id': 'ID',
  'resources.replies.fields.content': '댓글내용',
  'resources.replies.fields.userID': '글쓴이',
  'resources.replies.fields.pageID': '댓글의 고향',
  'resources.replies.fields.createdAt': '작성일',

  'resources.search-page.name': '통합검색 관리',
  'resources.search-page.recommend-keyword.name': '통합검색 추천검색어',
  'resources.search-page.recommend-keyword.create': '통합검색 추천검색어 추가',
  'resources.search-page.recommend-keyword.edit': '통합검색 추천검색어 수정',

  'resources.search-page.curation.name': '큐레이션',

  'resources.search-page.recommend-keyword.fields.id': 'ID',
  'resources.search-page.recommend-keyword.fields.title':
    '추천검색어(최대 10자)',
  'resources.search-page.recommend-keyword.fields.seq': '순서',
  'resources.search-page.recommend-keyword.fields.createdAt': '생성일',

  'resources.search-page.curation.fields.id': 'ID',
  'resources.search-page.curation.fields.title': '큐레이션 타이틀(최대 20자)',
  'resources.search-page.curation.fields.seq': '순서',
  'resources.search-page.curation.fields.url': '링크 주소',
  'resources.search-page.curation.fields.createdAt': '생성일',

  // 광고페이지
  'resources.advertisement.name': '광고',
  'resources.advertisement.empty': '현재 광고가 없습니다.',
  'resources.advertisement.invite': '생성 버튼을 눌러 데이터를 생성해주세요.',
  'resources.advertisement.HOTLINE_BANNER.name': '핫라인 배너',
  'resources.advertisement.RECOMMEND_S_BANNER.name': '추천활동 S배너',
  'resources.advertisement.RECOMMEND_A_BANNER.name': '추천활동 A배너',
  'resources.advertisement.POPULAR_ACTIVITY.name': '금주의 인기활동',
  'resources.advertisement.LIVE_POPULAR_RECRUIT.name': '채용 배너',
  'resources.advertisement.LIVE_ACTIVITY_INFO.name': '실시간 활동정보',
  'resources.advertisement.product-introduction.name': '매체소개서',

  'resources.linkareer-magazine.name': '합격 자료',
  'resources.linkareer-magazine.fields.title': '제목',
  'resources.linkareer-magazine.fields.content': '합격 자료',
  'resources.linkareer-magazine.fields.pcImage': 'PC이미지',
  'resources.linkareer-magazine.fields.url': '랜딩 URL',

  'resources.media-introduction.name': '매체소개서',

  // 광고 지면
  'resources.advertisement.ad-placements.name': '광고 지면',
  'resources.advertisement.ad-placements.fields.id': 'ID',
  'resources.advertisement.ad-placements.fields.code': '지면 식별코드',
  'resources.advertisement.ad-placements.fields.name': '지면 이름',
  'resources.advertisement.ad-placements.fields.isActive': '활성화 여부',
  'resources.advertisement.ad-placements.fields.width': '지면 너비',
  'resources.advertisement.ad-placements.fields.height': '지면 높이',
  'resources.advertisement.ad-placements.fields.createdAt': '생성일',

  // 직판 광고
  'resources.advertisement.campaigns.name': '직판광고',
  'resources.advertisement.campaigns.fields.id': '캠페인 ID',
  'resources.advertisement.campaigns.fields.startAt': '광고시작',
  'resources.advertisement.campaigns.fields.endAt': '광고종료',

  'resources.devtools-dashboard.name': '개발자 도구',

  // 광고 리스트
  'resources.advertisement.fields.seq': '순서',
  'resources.advertisement.fields.title': '제목',
  'resources.advertisement.fields.content': '내용',
  'resources.advertisement.fields.adType': '공고 타입',
  'resources.advertisement.fields.activityID': '공고 ID',
  'resources.advertisement.fields.startAt': '광고 시작일',
  'resources.advertisement.fields.endAt': '광고 종료일',
  'resources.advertisement.fields.outLink': '외부 랜딩 URL',
  'resources.advertisement.fields.pcImage': 'PC이미지',
  'resources.advertisement.fields.mobileImage': 'Mobile이미지',

  'enum.UserType.NORMAL': '일반 회원',
  'enum.UserType.MANAGER': '대외활동 담당자',
  'enum.UserType.WRITER': '읽을거리 페이지 작가',
  'enum.UserType.MANAGER_VERIFIED': '기업 회원 (사업자 번호 O)',
  'enum.UserType.MANAGER_UNVERIFIED': '기업 회원 (사업자 번호 X)',
  'enum.UserType.MANAGER_REJECTED': '기업 회원 (블랙리스트)',

  'enum.ActivityStatus.TEMP': '임시저장',
  'enum.ActivityStatus.OPEN': '승인',
  'enum.ActivityStatus.PENDING': '승인 대기',
  'enum.ActivityStatus.REJECTED': '반려',
  'enum.ActivityStatus.REJECTED_ETC': '반려/기타사유',

  'enum.ActivityType.Activity': '대외활동',
  'enum.ActivityType.Club': '동아리',
  'enum.ActivityType.Contest': '공모전',
  'enum.ActivityType.Intern': '인턴',
  'enum.ActivityType.Recruit': '채용',
  'enum.ActivityType.Education': '교육',

  'enum.CoverLetterType.ALL': '전체',
  'enum.CoverLetterType.MAJOR_COMPANY': '대기업',
  'enum.CoverLetterType.MEDIUM_COMPANY': '중견기업',
  'enum.CoverLetterType.SMALL_COMPANY': '중소기업/스타트업',
  'enum.CoverLetterType.PUBLIC_INSTITUTIONS': '공기업',
  'enum.CoverLetterType.BANKS': '은행권',
  'enum.CoverLetterType.ETC': '기타',
  'enum.CoverLetterType.INTERN': '인턴',
  'enum.CoverLetterType.NEWCOMER': '신입',

  'enum.CoverLetterStatus.ALL': '전체',
  'enum.CoverLetterStatus.PUBLISHED': '공개',
  'enum.CoverLetterStatus.PENDING': '대기',
  'enum.CoverLetterStatus.REJECTED': '비공개',

  'enum.CoverLetterSource.NAVER_SPECUP': '스펙업 카페',
  'enum.CoverLetterSource.OPEN_EVENT': '오픈 이벤트',
  'enum.CoverLetterSource.MANUAL_BY_ADMIN': '관리자 작성',

  'enum.SearchKeywordFieldType.OrganizationName': '기업명',
  'enum.SearchKeywordFieldType.Role': '직무',
  'enum.SearchKeywordFieldType.Keyword': '키워드',

  'msg.dashboard': '대시보드',
  'msg.users': '유저',
  'msg.summary': '요약',
  'msg.permissions': '권한',
  'msg.createdAt': '등록일',
  'msg.updatedAt': '수정일',
  'msg.search': '검색',
  'msg.filter': '필터',
  'msg.id': 'ID',
  'msg.token': '토큰',
  'msg.email': '이메일',
  'msg.timespread': '타임스프레드',

  'msg.name': '이름',
  'msg.cash': '캐시',
  'msg.birthday': '생년월일',
  'msg.profileURL': '프로필 주소',
  'msg.phoneNumber': '전화번호',
  'msg.facebookID': '페이스북 계정 ID',
  'msg.kakaoID': '카카오 계정 ID',
  'msg.naverID': '네이버 계정 ID',
  'msg.userActionLogs': '유저 활동 로그',

  'resources.signups.name': '회원가입 관련',
  'resources.signups.fields.id': 'ID',
  'resources.signups.fields.type': '유저 타입',
  'resources.signups.fields.username': '이름',
  'resources.signups.fields.phoneNumber': '전화번호',
  'resources.signups.fields.birthday': '생년월일',
  'resources.signups.fields.timespread.id': '타임스프레드 ID',
  'resources.signups.fields.timespread.cash': '타임스프레드 캐시',
  'resources.signups.fields.facebook.id': '페이스북 계정 ID',
  'resources.signups.fields.kakao.id': '카카오 계정 ID',
  'resources.signups.fields.naver.id': '네이버 계정 ID',
  'resources.signups.fields.createdAt': '가입일',
  'resources.signups.fields.email': '이메일',
  'resources.signups.fields.lastLoginAt': '최근 로그인',
  'resources.signups.fields.lastLoginSession': '최근 로그인 세션',
  'resources.signups.fields.specupJoined': '통합 여부',
  'resources.signups.fields.specupJoinedAt': '통합일',
  'resources.signups.fields.specupJoinedSession': '통합 세션',
  'resources.signups.fields.timespreadID': '연결 된 탐스 ID',
  'resources.signups.fields.timespreadAuthKey': '연결된 탐스 authKey',
  'resources.signups.fields.timespread.name': '이름',
  'resources.signups.fields.timespread.email': '이메일',
  'resources.signups.fields.timespread.birthday': '생년월일',
  'resources.signups.fields.timespread.profileURL': '프로필 주소',
  'resources.signups.fields.timespread.phoneNumber': '전화번호',
  'resources.signups.fields.timespread.facebookID': '페이스북 ID',
  'resources.signups.fields.timespread.kakaoID': '카카오 ID',
  'resources.signups.fields.timespread.naverID': '네이버 ID',
  'resources.signups.fields.deactivated': '탈퇴 여부',

  'resources.cover-letters.name': '합격자소서',
  'resources.cover-letters.fields.id': 'ID',
  'resources.cover-letters.fields.passedAt': '합격 날짜',
  'resources.cover-letters.fields.organizationName': '기업명',
  'resources.cover-letters.fields.role': '직무 분야',
  'resources.cover-letters.fields.university': '출신 대학',
  'resources.cover-letters.fields.major': '전공',
  'resources.cover-letters.fields.grades': '학점',
  'resources.cover-letters.fields.languageScore': '언어 성적',
  'resources.cover-letters.fields.career': '경력',
  'resources.cover-letters.fields.content': '자소서 본문',
  'resources.cover-letters.fields.types': '타입',
  'resources.cover-letters.fields.scrapCount': '스크랩 수',
  'resources.cover-letters.fields.createdAt': '생성시간',
  'resources.cover-letters.fields.updatedAt': '수정시간',
  'resources.cover-letters.fields.deletedAt': '삭제시간',
  'resources.cover-letters.fields.source': '출처',
  'resources.cover-letters.fields.sourceKey': '원본키',
  'resources.cover-letters.fields.status': '상태',
  'resources.cover-letters.fields.score': '점수',
  'resources.cover-letters.fields.activity': '대외활동/공모전',
  'resources.cover-letters.fields.license': '자격증',

  'resources.cover-letters.recommend-keyword.name': '합격자소서 추천검색어',
  'resources.cover-letters.recommend-keyword.create':
    '합격자소서 추천검색어 추가',
  'resources.cover-letters.recommend-keyword.edit':
    '합격자소서 추천검색어 수정',

  'resources.cover-letters.recommend-keyword.fields.id': 'ID',
  'resources.cover-letters.recommend-keyword.fields.title': '제목',
  'resources.cover-letters.recommend-keyword.fields.searchField': '검색 기준',
  'resources.cover-letters.recommend-keyword.fields.seq': '순서',
  'resources.cover-letters.update-field.name': '기업명/직무명 변경',
  'resources.cover-letters.update-field.updateForm.helperText':
    '아래 나오는 모든 데이터의 입력값이 변경됩니다.',
  'resources.cover-letters.update-field.updateForm.submit': '변경',

  'resources.user.list.name': '회원 목록',
  'resources.user.statistics.name': '일일 결과',
  'msg.signupLog': '회원가입 로그',
  'msg.dropoutLog': '탈퇴관리',
}

export function createI18nProvider(intl: IntlShape) {
  const hasWarned = new Set<string>()

  return {
    translate(messageID: string, params: any) {
      if (!messageID) {
        console.warn(`invalid messageID: "${messageID}"`)
        return null
      }

      const result = intl.formatMessage({ id: messageID }, params)

      if (result === messageID && !hasWarned.has(messageID)) {
        hasWarned.add(messageID)

        const recommendation = get(englishMessages, messageID)
        if (recommendation) {
          console.warn(
            `i18n recommendation: '${messageID}' = '${recommendation}'`,
          )
        }
      }

      return result
    },

    changeLocale() {
      throw new Error('not implemented')
    },

    getLocale() {
      return intl.locale
    },
  }
}

export const i18nProvider = {}
