import React, { FC, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import { TextField } from '@material-ui/core'

import { AdvertisementFormData } from '../types'
import { LINKAREER_DOMAIN } from 'config'
import {
  AdType,
  ActivityStatus,
  useAdvertisementForm_ActivityIdField_ActivityLazyQuery,
} from 'generated/graphql'

interface ActivityIDFieldProps {
  adType: AdType
  className?: string
  activityID?: string
}

const ActivityIDField: FC<ActivityIDFieldProps> = ({
  adType,
  activityID,
  className,
}) => {
  const { watch, register } = useFormContext<AdvertisementFormData>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const watchActivityID = watch('activityID')
  const [
    getActivity,
    { data, error, loading, called },
  ] = useAdvertisementForm_ActivityIdField_ActivityLazyQuery()

  const [getActivityCallback] = useDebouncedCallback(async () => {
    if (watchActivityID) {
      await getActivity({ variables: { id: watchActivityID } })
      if (loading || !called) {
        return
      }

      if (error || data?.activity?.status !== ActivityStatus.OPEN) {
        setErrorMessage(
          '실제로 존재하는 공고가 아니거나, 오픈된 공고가 아닙니다.',
        )
      }
    }
  }, 500)

  useEffect(() => {
    setErrorMessage('')
    getActivityCallback()
  }, [watchActivityID, data, getActivityCallback])

  return (
    <TextField
      inputRef={register}
      label="공고 ID"
      required={adType !== AdType.HOTLINE_BANNER}
      variant="outlined"
      type="number"
      defaultValue={activityID}
      name="activityID"
      error={!!errorMessage}
      helperText={
        errorMessage || `현재: ${LINKAREER_DOMAIN}/activity/${activityID}`
      }
      className={className}
    />
  )
}

export default ActivityIDField

gql`
  query AdvertisementForm_ActivityIDField_activity($id: ID!) {
    activity(id: $id) {
      status
    }
  }
`
