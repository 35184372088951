import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#6f6f6f',
    color: '#fff',

    '& th': {
      padding: '8px 0',
    },
  },
})

const CampaignReportTableHeader: FC = () => {
  const classes = useStyles()
  return (
    <>
      <colgroup>
        <col width="12%" />
        <col width="*" />
        <col width="15%" />
        <col width="15%" />
        <col width="10%" />
      </colgroup>
      <thead className={classes.tableHeader}>
        <tr>
          <th>날짜</th>
          <th>지면 이름</th>
          <th>노출</th>
          <th>클릭</th>
          <th>CTR</th>
        </tr>
      </thead>
    </>
  )
}

export default CampaignReportTableHeader
