import React, { FC, useCallback, useState } from 'react'
import { ReplyFragmentFragment } from 'generated/graphql'
import {
  useShowController,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin'
import {
  makeStyles,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'
import ReplyDeleteDialog from './ReplyDeleteDialog'

const useStyles = makeStyles({
  root: {
    width: 800,
    margin: 'auto',
    padding: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  content: {
    whiteSpace: 'normal',
  },
  actions: {
    textAlign: 'right',
  },
})

interface IFReplyShowRecordDeclareProps {
  userID: string
}
interface IFReplyShowComponentProps {
  record: ReplyFragmentFragment & IFReplyShowRecordDeclareProps
}

const ReplyShowComponent: FC<IFReplyShowComponentProps> = ({ record }) => {
  const classes = useStyles()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const handleDeleteClick = useCallback(() => {
    setIsDeleteDialogOpen(true)
  }, [])

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false)
  }, [])

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <span className={classes.label}>작성자 : </span>
              {record.userID ? (
                <ReferenceField
                  resource="replies"
                  reference="users"
                  source="userID"
                  basePath="/replies"
                  record={record}
                >
                  <TextField source="username" />
                </ReferenceField>
              ) : (
                '익명'
              )}
            </Grid>
            <Grid item xs={6}>
              <span className={classes.label}>작성일 : </span>
              <DateField record={record} source="createdAt" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <span className={classes.label}>공고명 : </span>
              <ReferenceField
                resource="replies"
                reference="activities"
                source="pageID"
                basePath="/replies"
                record={record}
              >
                <TextField source="title" />
              </ReferenceField>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <span className={classes.label}>댓글 내용</span>
              <Typography className={classes.content}>
                {record.content}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.actions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteClick}
            >
              삭제
            </Button>
          </div>
        </CardContent>
      </Card>
      <ReplyDeleteDialog
        record={record}
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
      />
    </>
  )
}

const ReplyShow: FC = props => {
  const classes = useStyles()
  const { record } = useShowController<ReplyFragmentFragment>(props)

  // eslint-disable-next-line prettier/prettier
  const replies = record?.replies || []

  return (
    <>
      <ReplyShowComponent record={record} />
      
        <Typography variant="h6" className={classes.root}>
          답글 {replies.length}개
        </Typography>
      {
        replies.map(reply => (
          <ReplyShowComponent key={reply.id} record={reply} />
        ))
      }
    </>
  )
}

export default ReplyShow
