import React, {
  cloneElement,
  FunctionComponent,
  ReactElement,
  Fragment,
  useState,
} from 'react'
import {
  Grid,
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import { useTranslate } from 'react-admin'

const useStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  parentMenu: {
    width: 'calc(100% - 2em)',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  expandIcon: {
    paddingTop: 5,
    cursor: 'pointer',
  },
  parentMenu2: {
    width: '100%',
    paddingRight: 4,
  },
  parentMenuTitle: {
    flexGrow: 1,
  },
}))

interface SubMenuProps {
  sidebarIsOpen: boolean
  name: string // primaryText
  icon: ReactElement
  dense: boolean
  onClick?: () => void
}

const SubMenu: FunctionComponent<SubMenuProps> = ({
  sidebarIsOpen,
  name,
  icon,
  children,
  dense,
  onClick,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const header = (
    <Grid container onClick={handleToggle}>
      <MenuItem
        button
        className={classes.parentMenu2}
        dense={dense}
        {...(onClick && { onClick })}
      >
        <ListItemIcon className={classes.icon}>
          {cloneElement(icon)}
        </ListItemIcon>
        <Typography
          variant="inherit"
          color="textSecondary"
          className={classes.parentMenuTitle}
        >
          {translate(name)}
        </Typography>
        <div className={classes.expandIcon}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </MenuItem>
    </Grid>
  )

  return (
    <Fragment>
      {sidebarIsOpen || open ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenu
