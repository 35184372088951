import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  regex,
  number,
  maxLength,
  minValue,
  maxValue,
  useRefresh,
  useRedirect,
} from 'react-admin'
import { Button, makeStyles } from '@material-ui/core'
import { mapStateToProps } from './CurationList'
import DeleteIcon from '@material-ui/icons/Delete'

import { useCurationDeleteMutation } from 'generated/graphql'
import { urlRegex } from 'utils'

export const useStyles = makeStyles({
  toolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
})

interface CurationEditToolbarProps {
  basePath?: string
  record?: any
  redirect?: any
}

export const CurationEditToolbar: FunctionComponent<CurationEditToolbarProps> = props => {
  const classes = useStyles()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { basePath, redirect: redirectTo = 'list' } = props

  const [curationDelete] = useCurationDeleteMutation({
    variables: {
      id: props.record.id,
    },
  })

  const handleOnClick = async () => {
    await curationDelete()
    redirect(redirectTo || '', basePath)
    refresh()
  }

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <Button
        color="primary"
        startIcon={<DeleteIcon />}
        onClick={handleOnClick}
      >
        삭제
      </Button>
    </Toolbar>
  )
}

export interface CurationEditProps {
  location: any
  match?: any
  options?: any
}

const CurationEdit: FunctionComponent<CurationEditProps> = props => {
  return (
    <Edit title="큐레이션 수정" id={props.match.params.id} {...props}>
      <SimpleForm toolbar={<CurationEditToolbar />}>
        <TextInput
          source="title"
          validate={maxLength(20, '최대 20자까지 입력할 수 있습니다.')}
        />
        <TextInput
          source="url"
          validate={regex(urlRegex, 'url 형식에 맞게 입력해주세요.')}
        />
        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 3까지 입력가능합니다.'),
            maxValue(3, '1부터 3까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}

export default connect(mapStateToProps)(CurationEdit)

gql`
  mutation CurationDelete($id: ID!) {
    curationDelete(id: $id) {
      curation {
        id
      }
    }
  }
`
