import isEqual from 'lodash/isEqual'
import Ajv from 'ajv'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import flatten, { unflatten } from 'flat'
import { OrderDirection } from 'generated/graphql'

const ajv = new Ajv({ useDefaults: true, coerceTypes: 'array' })

export const paginationSchema = {
  default: {},
  type: 'object',
  properties: {
    page: { type: 'number', default: 1 },
    pageSize: { type: 'number', default: 10 },
  },
}

export const orderBySchema = {
  default: undefined,
  type: 'object',
  properties: {
    field: { type: 'string' },
    filterType: { type: 'string', enum: Object.values(OrderDirection) },
  },
}

export function useQueryStringVariables(schema: object) {
  const location = useLocation()
  const history = useHistory()

  const validate = ajv.compile(schema)

  const state: any = unflatten(queryString.parse(location.search))
  validate(state)

  function updateQueryString(variables: any) {
    validate(variables)

    if (
      isEqual(variables.filterBy, state.filterBy) &&
      isEqual(variables.orderBy, state.orderBy) &&
      isEqual(variables.pagination, state.pagination)
    ) {
      return
    }

    history.push({
      ...history.location,
      search: `?${queryString.stringify(flatten(variables), {
        skipNull: true,
        skipEmptyString: true,
      })}`,
    })
  }

  return [state, updateQueryString, validate]
}

export default useQueryStringVariables
