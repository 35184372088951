import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

interface CampaignShowPageParams {
  id: string
}

const CampaignShowPage: FC = () => {
  const { id: campaignID } = useParams<CampaignShowPageParams>()
  return <p>campaign show page : {campaignID}</p>
}

export default CampaignShowPage
