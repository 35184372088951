import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin'

export interface CurationShowProps {
  location: any
  match?: any
  options?: any
}

const CurationShow: FunctionComponent<CurationShowProps> = props => {
  return (
    <Show id={props.match.params.id} {...props}>
      <TabbedShowLayout>
        <Tab label="msg.summary">
          <TextField source="id" label="msg.id" />
          <TextField source="title" />
          <TextField source="url" />
          <TextField source="seq" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const mapStateToProps = () => ({
  basePath: '/search-page/curation',
  resource: 'search-page.curation',
})

export default connect(mapStateToProps)(CurationShow)
