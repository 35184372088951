/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import clsx from 'clsx'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import {
  useTable,
  usePagination,
  useFlexLayout,
  useSortBy,
  TableOptions,
  Row,
  SortingRule,
} from 'react-table'
import { Pagination, OrderDirection } from 'generated/graphql'
import TableToolbar from './TableToolbar'
import FilterBar, { FilterField, FilterBarProps } from './FilterBar'
import './react-table-config.d.ts'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    position: 'relative',
  },
  headerTableCell: {
    paddingTop: 8,
    paddingBottom: 8,
    background: 'white',
    textAlign: 'center',
  },
  refetchWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255 ,255,0.5)',
    display: 'grid',
    placeItems: 'center',
  },
  thead: {
    display: 'block',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  tbody: {
    display: 'block',
  },
  tableCell: {
    display: 'grid',
    alignItems: 'center',
    whiteSpace: 'normal',
  },
  tableContainer: {
    display: 'block',
    overflow: 'auto',
    position: 'relative',
    maxHeight: 'calc(100vh - 250px)',
  },
  table: {
    overflowX: 'initial',
  },
  tableRowHover: {
    cursor: 'pointer',
  },
  emptyData: {
    minHeight: 300,
    display: 'grid',
    placeItems: 'center',
  },
  sortFiield: {
    color: theme.palette.secondary.main,
  },
}))

export type GraphqlOrderBy = {
  field: any
  direction: OrderDirection
}

// graphql -> react-table
function paginationToState(pagination?: Pagination) {
  if (!pagination) {
    return {
      pageIndex: 0,
      pageSize: 20,
    }
  }
  return {
    pageSize: pagination.pageSize,
    pageIndex: pagination.page - 1,
  }
}

function stateToPagination(state: {
  pageSize: number
  pageIndex: number
}): Pagination {
  return {
    page: state.pageIndex + 1,
    pageSize: state.pageSize,
  }
}

function orderByToState(columns: Array<any>, orderBy?: GraphqlOrderBy) {
  if (!orderBy) {
    return []
  }

  const sortColumn = columns.find(
    column => get(column, 'sortField') === orderBy.field,
  )

  const id = get(sortColumn, 'id') || get(sortColumn, 'accessor')

  if (!id) {
    return []
  }

  return [
    {
      id,
      desc: orderBy.direction === OrderDirection.DESC,
    },
  ]
}

function sortByToOrderBy<D>(
  sortBy: SortingRule<D>[],
  columns: Array<any>,
): GraphqlOrderBy | undefined {
  const firstSortBy = first(sortBy)

  if (!firstSortBy) {
    return undefined
  }
  const column = columns.find(
    column =>
      get(column, 'id') === firstSortBy.id ||
      get(column, 'accessor') === firstSortBy.id,
  )

  const sortField = get(column, 'sortField')

  if (!sortField) {
    return undefined
  }

  return {
    field: sortField,
    direction: firstSortBy.desc ? OrderDirection.DESC : OrderDirection.ASC,
  }
}

export interface TableState {
  pagination: Pagination
  orderBy?: GraphqlOrderBy
  filterFields?: FilterField[]
}

interface TableProps<D extends object> extends TableOptions<D> {
  pageCount?: number
  orderBy?: GraphqlOrderBy
  pagination?: Pagination
  onStateChange?: (state: TableState) => void
  loading?: boolean
  title: string
  getExportData?: () => Promise<Array<object>>
  onRowClick?: (row: Row<D>) => void
  filterFields?: Array<FilterField>
  onFilterUpdate?: (filters: Array<FilterField>) => void
  disableFilter?: boolean
  extendedToolbar?: FC<TableState>
  hasCreate?: boolean
}

function Table<D extends object>({
  columns,
  data,
  onStateChange,
  pageCount = 0,
  pagination: paginationProp = {
    page: 1,
    pageSize: 20,
  },
  orderBy: orderByProp,
  loading = false,
  title,
  getExportData,
  onFilterUpdate,
  filterFields,
  onRowClick,
  disableFilter = false,
  extendedToolbar,
  hasCreate = false,
  ...tableProps
}: TableProps<D>) {
  const defaultSortBy = orderByToState(columns, orderByProp)
  const defaultPagination = paginationToState(paginationProp)

  const classes = useStyles()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    [],
  )

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    toggleSortBy,
    state: { pageIndex, pageSize, sortBy },
  } = useTable<D>(
    {
      columns,
      data,
      defaultColumn,
      pageCount,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        ...defaultPagination,
        sortBy: defaultSortBy,
      },
      ...tableProps,
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  )

  useDeepCompareEffect(() => {
    defaultSortBy.forEach(item => {
      toggleSortBy(item.id, item.desc, false)
    })
  }, [defaultSortBy])

  React.useEffect(() => {
    gotoPage(defaultPagination.pageIndex)
  }, [defaultPagination.pageIndex, gotoPage])
  React.useEffect(() => {
    setPageSize(defaultPagination.pageSize)
  }, [defaultPagination.pageSize, setPageSize])

  function handleStateChange(nextState: TableState) {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    if (
      isEqual(
        {
          pagination: paginationProp,
          orderBy: orderByProp,
          filterFields,
        },
        nextState,
      )
    ) {
      return
    }

    if (onStateChange) {
      onStateChange(nextState)
    }
  }

  const isMounted = React.useRef(false)
  const firstSortBy = first(sortBy)

  React.useEffect(() => {
    handleStateChange({
      pagination: stateToPagination({
        pageIndex,
        pageSize,
      }),
      orderBy: sortByToOrderBy(sortBy, columns),
      filterFields: filterFields,
    })
  }, [pageIndex, pageSize])

  React.useEffect(() => {
    handleStateChange({
      pagination: stateToPagination({
        pageIndex,
        pageSize,
      }),
      orderBy: sortByToOrderBy(sortBy, columns),
      filterFields: filterFields,
    })
  }, [firstSortBy])

  const handleFilterUpdate = (nextFilterFields: FilterField[]) => {
    handleStateChange({
      pagination: stateToPagination({
        pageIndex: 0,
        pageSize,
      }),
      orderBy: sortByToOrderBy(sortBy, columns),
      filterFields: nextFilterFields,
    })
    gotoPage(0)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    gotoPage(0)
  }

  return (
    <Paper className={classes.root}>
      <div>
        <TableToolbar
          title={title}
          getExportData={getExportData}
          hasCreate={hasCreate}
        />
        {extendedToolbar?.({
          pagination: stateToPagination({
            pageIndex: 0,
            pageSize,
          }),
          orderBy: sortByToOrderBy(sortBy, columns),
          filterFields,
        })}
        {!disableFilter && (
          <FilterBar
            onFilterUpdate={handleFilterUpdate}
            filterFields={filterFields}
          />
        )}
      </div>

      <TableContainer className={classes.tableContainer}>
        <MaUTable
          {...getTableProps()}
          className={classes.table}
          component="div"
        >
          <TableHead component="div" className={classes.thead}>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} component="div">
                {headerGroup.headers.map(column => {
                  const sortProps: any = column.getSortByToggleProps()
                  return (
                    <TableCell
                      {...column.getHeaderProps({
                        className: classes.headerTableCell,
                      })}
                      component="div"
                    >
                      {column.sortField ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          onClick={sortProps && sortProps.onClick}
                          className={classes.sortFiield}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      ) : (
                        column.render('Header')
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          {data.length ? (
            <TableBody component="div" className={classes.tbody} id="header">
              {rows.map(row => {
                prepareRow(row)
                return (
                  <TableRow
                    {...row.getRowProps()}
                    className={clsx({ [classes.tableRowHover]: !!onRowClick })}
                    hover={!!onRowClick}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row)
                      }
                    }}
                    component="div"
                  >
                    {row.cells.map(cell => (
                      <TableCell
                        {...cell.getCellProps({
                          className: clsx(
                            classes.tableCell,
                            // @ts-ignore
                            cell.column.className,
                          ),
                        })}
                        align={cell.column.align ?? 'left'}
                        component="div"
                      >
                        <span>{cell.render('Cell')}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          ) : (
            <div className={classes.emptyData}>
              <Typography>데이터가 없습니다.</Typography>
            </div>
          )}
        </MaUTable>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="페이지 수"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={pageCount}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {loading && (
        <div className={classes.refetchWrapper}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </Paper>
  )
}

export default Table
