import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import gql from 'graphql-tag'
import { NetworkStatus } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import {
  useUserTableQuery,
  UserTableQueryVariables,
  UserTableDocument,
  UserTable_UserFragment,
  UserOrderField,
  OrderDirection,
} from 'generated/graphql'
import Loading from 'components/Loading'
import Table, {
  ImageCell,
  UserTypeCell,
  LinkareerTimespreadCell,
  FormattedDateTimeCell,
  style,
} from 'components/Table'
import { createGlobalQueryField } from 'components/Table/FilterBar'
import Error from 'components/Error'
import {
  useQueryStringVariables,
  paginationSchema,
  orderBySchema,
} from 'hooks/useVariablesQueryStringSync'
import useApolloExcute from 'hooks/useApolloExcute'
import useUserLogin from 'hooks/useUserLogin'

interface UserLoginCellProps {
  value: string
}

export const UserLoginCell: React.FC<UserLoginCellProps> = props => {
  const userLogin = useUserLogin()

  return (
    <Button
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        userLogin(props.value)
      }}
      color="secondary"
      variant="contained"
    >
      로그인
    </Button>
  )
}

const UserTable: React.FC = () => {
  const history = useHistory()
  const apolloExcute = useApolloExcute()
  const [queryVariables, updateQueryString] = useQueryStringVariables({
    type: 'object',
    properties: {
      pagination: paginationSchema,
      orderBy: {
        ...orderBySchema,
        default: {
          field: UserOrderField.CREATED_AT,
          direction: OrderDirection.DESC,
        },
      },
      filterBy: {
        type: 'object',
        default: {
          isOrganization: false,
        },
        properties: {
          isOrganization: {
            type: 'boolean',
          },
          q: { type: 'string' },
        },
      },
    },
  })

  const { data, error, variables, networkStatus } = useUserTableQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
    },
  })

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const filterFields: any = []
  if (variables.filterBy && variables.filterBy.q) {
    filterFields.push(createGlobalQueryField(variables.filterBy.q))
  }

  return (
    <>
      <Table<UserTable_UserFragment>
        title="유저"
        columns={[
          {
            Header: 'ID',
            accessor: 'id' as const,
            minWidth: 80,
            maxWidth: 80,
          },
          {
            Header: '프로필 사진',
            // @ts-ignore
            accessor: 'profile.identificationImage.url' as const,
            className: style.fullSizeCell,
            minWidth: 100,
            width: 100,
            Cell: ImageCell,
          },
          {
            Header: '유저 타입',
            accessor: 'type' as const,
            // minWidth: 100,
            Cell: UserTypeCell,
          },
          {
            Header: '이름',
            minWidth: 250,
            id: 'username',
            accessor: originalRow => {
              return {
                linkareer: get(originalRow, 'username'),
                timespread: get(originalRow, 'timespread.name'),
              }
            },
            Cell: LinkareerTimespreadCell,
          },
          {
            Header: '전화번호',
            id: 'phoneNumber',
            accessor: originalRow => {
              return {
                linkareer: get(originalRow, 'phoneNumber'),
                timespread: get(originalRow, 'timespread.phoneNumber'),
              }
            },
            Cell: LinkareerTimespreadCell,
          },
          {
            minWidth: 250,
            Header: '이메일',
            id: 'email',
            accessor: originalRow => {
              return {
                linkareer: get(originalRow, 'email'),
                timespread: get(originalRow, 'timespread.email'),
              }
            },
            Cell: LinkareerTimespreadCell,
          },
          {
            minWidth: 260,
            Header: '최근 로그인',
            accessor: 'lastLoginAt' as const,
            Cell: FormattedDateTimeCell,
          },
          {
            minWidth: 260,
            Header: '가입일',
            accessor: 'createdAt' as const,
            Cell: FormattedDateTimeCell,
            defaultCanSort: true,
            sortField: UserOrderField.CREATED_AT,
          },
          {
            minWidth: 260,
            Header: '탈퇴일',
            accessor: 'deletedAt' as const,
            Cell: FormattedDateTimeCell,
          },
          {
            Header: '로그인',
            minWidth: 100,
            id: 'login',
            accessor: 'id' as const,
            Cell: UserLoginCell,
          },
        ]}
        data={get(data, 'users.nodes', [])}
        pagination={queryVariables.pagination}
        orderBy={queryVariables.orderBy}
        pageCount={get(data, 'users.totalCount', 0)}
        filterFields={filterFields}
        onStateChange={({ pagination, orderBy, filterFields = [] }) => {
          const filterBy: UserTableQueryVariables['filterBy'] = {}
          filterFields.forEach(filter => {
            if (filter.filterType === 'global-query' && filter.value) {
              filterBy.q = filter.value
            }
          })

          const nextVar = {
            pagination,
            orderBy,
            ...(isEmpty(filterBy)
              ? {}
              : {
                  filterBy: {
                    ...filterBy,
                    isOrganization: false,
                  },
                }),
          }

          updateQueryString(nextVar)
        }}
        loading={networkStatus === NetworkStatus.setVariables}
        getExportData={async () => {
          const result = await apolloExcute({
            query: UserTableDocument,
            variables: {
              ...queryVariables,
              pagination: { page: 1, pageSize: 1000 },
            },
          })

          return get(result, 'data.users.nodes', [])
        }}
        onRowClick={row => {
          history.push(`/users/${row.values.id}/show`)
        }}
      />
    </>
  )
}

export default UserTable

gql`
  fragment UserTable_user on User {
    id
    type
    createdAt
    username
    email
    birthday
    phoneNumber
    username
    phoneNumber
    email
    lastLoginAt
    deactivated
    profile {
      id
      identificationImage {
        id
        url
      }
    }
    timespread {
      id
      name
      phoneNumber
      email
      profileURL
    }
    deletedAt
  }
  fragment UserTable_userConnection on UserConnection {
    nodes {
      ...UserTable_user
    }
    totalCount
  }
  query UserTable(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      ...UserTable_userConnection
    }
  }
`
