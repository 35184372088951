import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { List, Datagrid, TextField, SelectField } from 'react-admin'
import { SearchKeywordFieldType } from 'generated/graphql'
import { makeStyles } from '@material-ui/core'

const useListStyles = makeStyles({
  main: {
    width: '100%',
  },
  content: {
    width: 'inherit',
  },
})

const CoverLetterRecommendKeywordList: FunctionComponent = props => {
  const classes = useListStyles()

  return (
    <List classes={classes} {...props} hasCreate bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="title" sortable={false} />
        <SelectField
          source="searchField"
          choices={[
            {
              id: SearchKeywordFieldType.ORGANIZATION_NAME,
              name: 'enum.SearchKeywordFieldType.OrganizationName',
            },
            {
              id: SearchKeywordFieldType.ROLE,
              name: 'enum.SearchKeywordFieldType.Role',
            },
            {
              id: SearchKeywordFieldType.KEYWORD,
              name: 'enum.SearchKeywordFieldType.Keyword',
            },
          ]}
        />
        <TextField source="seq" sortable={false} />
      </Datagrid>
    </List>
  )
}

export const mapStateToProps = () => ({
  basePath: '/cover-letters/recommend-keyword',
  resource: 'cover-letters.recommend-keyword',
})

export default connect(mapStateToProps)(CoverLetterRecommendKeywordList)
