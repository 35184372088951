import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import OrganizationDetail from 'resources/organizations/OrganizationDetail'

interface OrganizationListDetailParams {
  id: string
}

const OrganizationListDetailPage: FC = () => {
  const params = useParams<OrganizationListDetailParams>()
  return <OrganizationDetail id={params?.id} />
}

export default OrganizationListDetailPage
