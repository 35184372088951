import get from 'lodash/get'
import { ApolloError } from '@apollo/client'

export function getErrorCode(error: ApolloError) {
  return get(error, 'graphQLErrors[0].extensions.code', 'UNKNOWN_ERROR')
}

export function getErrorMessage(error?: ApolloError) {
  return get(error, 'graphQLErrors[0].extensions.message', '에러')
}
