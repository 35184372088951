import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ResponsiveContainer,
  LineChart,
  LineChartProps,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts'
import { format } from 'date-fns'
import orderBy from 'lodash/orderBy'
import union from 'lodash/union'
import groupBy from 'lodash/groupBy'
import { buildIndex } from 'utils'
import { CampaignStatisticsByDate, CampaignStatistic } from '../CampaignReport'

const LABELS = {
  impressionCount: '노출 수',
  clickCount: '클릭 수',
}

const useCustomTooltopStyles = makeStyles({
  root: {
    border: '1px solid #c9c9c9',
    padding: 15,
    backgroundColor: '#fff',
  },
  info: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
})

interface CustomTooltopProps {
  label?: any
  payload?: any
  active?: any
}

const CustomTooltip: FC<CustomTooltopProps> = ({ label, payload }) => {
  const classes = useCustomTooltopStyles()
  return (
    <div className={classes.root}>
      <p>{label && format(label, 'yyyy-MM-dd')}</p>
      <ul className={classes.info}>
        {payload?.map(({ name, value, color }) => (
          <li key={name} style={{ color }}>{`${LABELS[name]} : ${value}`}</li>
        ))}
      </ul>
    </div>
  )
}

const usePlacementChartStyles = makeStyles({
  caption: {
    marginBottom: 10,
    textAlign: 'center',
  },
})

interface PlacementChartProps extends LineChartProps {
  subject: string
  statistics: CampaignStatistic[]
}

const PlacemenChart: FC<PlacementChartProps> = ({
  subject,
  statistics,
  ...other
}) => {
  const classes = usePlacementChartStyles()

  const formatXAxis = (tickItem: Date) => {
    return format(tickItem, 'yyyy-MM-dd')
  }

  return (
    <>
      <Typography className={classes.caption} variant="subtitle1" component="p">
        {subject}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart {...other} data={statistics}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="date" tickFormatter={formatXAxis} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend formatter={value => LABELS[value]} />
          <Line type="monotone" dataKey="impressionCount" stroke="#8884d8" />
          <Line type="monotone" dataKey="clickCount" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

interface ImpressionAndClickByPlacementChartProps {
  data: CampaignStatisticsByDate[]
}

const ImpressionAndClickByPlacementChart: FC<ImpressionAndClickByPlacementChartProps> = ({
  data,
}) => {
  const campaignStatistics = data.reduce<CampaignStatistic[]>(
    (campaignStatistics, item) => {
      campaignStatistics.push(...item.campaignStatistics)
      return campaignStatistics
    },
    [],
  )

  const orderedPlacementsByDate = orderBy(campaignStatistics, 'date', 'asc')

  const placementIDs = union(
    campaignStatistics.map(
      campaignStatistic => campaignStatistic.adPlacementID,
    ),
  )

  const groupedDataByPlacementID = groupBy(
    orderedPlacementsByDate,
    placement => placement.adPlacementID,
  )

  const indexedDataByPlacementID = buildIndex(
    orderedPlacementsByDate,
    placement => placement.adPlacementID,
  )

  return (
    <Grid container spacing={2}>
      {placementIDs.map(placementID => {
        const placement = indexedDataByPlacementID.get(placementID)
        const statisticsByPlacementID = groupedDataByPlacementID[placementID]

        if (!statisticsByPlacementID) {
          return null
        }

        return (
          <Grid key={placementID} item xs={6}>
            <PlacemenChart
              syncId="impression-and-click-by-placement-chart"
              subject={placement?.adPlacementName || ''}
              statistics={statisticsByPlacementID}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ImpressionAndClickByPlacementChart
