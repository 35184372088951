import React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import ExportCSVButton from './ExportCSVButton'

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
)

interface TableToolbarProps {
  numSelected?: number
  title: string
  getExportData?: () => Promise<Array<object>>
  hasCreate?: boolean
}

const TableToolbar = (props: TableToolbarProps) => {
  const history = useHistory()
  const classes = useToolbarStyles()
  const { numSelected = 0, title, getExportData, hasCreate } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {hasCreate && (
        <Tooltip title="Create">
          <IconButton
            onClick={() => {
              history.push('create')
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <ExportCSVButton getExportData={getExportData} title={title} />
        </>
      )}
    </Toolbar>
  )
}

export default TableToolbar
