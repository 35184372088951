import React, { FC } from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  RichTextField,
  ReferenceField,
  Filter,
  TextInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ReplyShow from './ReplyShow'

const useStyles = makeStyles({
  content: {
    minWidth: 800,
    maxWidth: 800,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

const ReplyFilter = props => (
  <Filter {...props}>
    <TextInput label="공고 ID" source="pageID" alwaysOn />
  </Filter>
)

const ReplyList: FC = props => {
  const classes = useStyles()
  return (
    <List
      {...props}
      filters={<ReplyFilter />}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <Datagrid rowClick="expand" expand={<ReplyShow />}>
        <TextField source="id" />
        <RichTextField source="content" cellClassName={classes.content} />
        <ReferenceField source="userID" reference="users">
          <TextField source="username" />
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}

export default ReplyList
