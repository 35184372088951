export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://gateway-api.lnkr.cc'
export const KIBANA_BASE_URL = process.env.REACT_APP_KIBANA_BASE_URL
export const LINKAREER_NEXT_CLIENT_BASE_URL =
  process.env.REACT_APP_LINKAREER_NEXT_CLIENT_BASE_URL ||
  'https://local.lnkr.cc:7000'
export const LINKAREER_DOMAIN =
  process.env.REACT_APP_LINKAREER_DOMAIN || 'https://local.lnkr.cc:7000'
export const AWS_S3_BUCKET =
  process.env.REACT_APP_AWS_S3_BUCKET || 'media.lnkr.cc'
export const LINKAREER_CMS_DOMAIN =
  process.env.LINKAREER_CMS_DOMAIN || 'https://cms.lnkr.cc'

export const TIMEZONE = 'Asia/Seoul'
