import gql from 'graphql-tag'
import React, { FC } from 'react'
import { Button, useRefresh, useUnselectAll } from 'react-admin'

import {
  CoverLetterType,
  useBulkCoverLetterTypeAddMutation,
} from 'generated/graphql'

const BulkCoverLetterTypeAddButtons: FC<any> = ({ selectedIds }) => {
  const [bulkCoverLetterTypeAdd] = useBulkCoverLetterTypeAddMutation()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()

  const handleClick = async (type: CoverLetterType) => {
    await bulkCoverLetterTypeAdd({
      variables: {
        input: {
          ids: selectedIds,
          type,
        },
      },
    })

    refresh()
    unselectAll('cover-letters')
  }

  return (
    <>
      <Button
        label="enum.CoverLetterType.MAJOR_COMPANY"
        onClick={() => handleClick(CoverLetterType.MAJOR_COMPANY)}
      />
      <Button
        label="enum.CoverLetterType.PUBLIC_INSTITUTIONS"
        onClick={() => handleClick(CoverLetterType.PUBLIC_INSTITUTIONS)}
      />
      <Button
        label="enum.CoverLetterType.BANKS"
        onClick={() => handleClick(CoverLetterType.BANKS)}
      />
      <Button
        label="enum.CoverLetterType.INTERN"
        onClick={() => handleClick(CoverLetterType.INTERN)}
      />
    </>
  )
}

export default BulkCoverLetterTypeAddButtons

gql`
  mutation BulkCoverLetterTypeAdd($input: CoverLetterManyAddTypeInput!) {
    coverLetterManyAddType(input: $input) {
      updatedCoverLetters {
        ...CoverLetterFragment
      }
    }
  }
`
