import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  Create,
  SimpleForm,
  TextInput,
  number,
  minValue,
  maxValue,
  SelectInput,
} from 'react-admin'

import { SearchKeywordFieldType } from 'generated/graphql'
import { mapStateToProps } from './CoverLetterRecommendKeywordList'
import {
  RecommendKeywordCreateToolbar,
  RecommendKeywordCreateProps,
} from 'resources/search-page/recommendKeyword/RecommendKeywordCreate'

const CoverLetterRecommendKeywordCreate: FunctionComponent<RecommendKeywordCreateProps> = props => {
  return (
    <Create
      title="resources.cover-letters.recommend-keyword.create"
      id={props.match.params.id}
      {...props}
    >
      <SimpleForm toolbar={<RecommendKeywordCreateToolbar {...props} />}>
        <TextInput source="title" />
        <SelectInput
          source="searchField"
          choices={[
            {
              id: SearchKeywordFieldType.ORGANIZATION_NAME,
              name: 'enum.SearchKeywordFieldType.OrganizationName',
            },
            {
              id: SearchKeywordFieldType.ROLE,
              name: 'enum.SearchKeywordFieldType.Role',
            },
            {
              id: SearchKeywordFieldType.KEYWORD,
              name: 'enum.SearchKeywordFieldType.Keyword',
            },
          ]}
        />

        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 5까지 입력가능합니다.'),
            maxValue(5, '1부터 5까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

export default connect(mapStateToProps)(CoverLetterRecommendKeywordCreate)
