import React, { FunctionComponent, useState } from 'react'
import { MenuItemLink, useTranslate, usePermissions } from 'react-admin'
import {
  Assignment as ListIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  LooksOne as FirstIcon,
  LooksTwo as SecondIcon,
  Looks3 as ThirdIcon,
  Looks4 as FourthIcon,
  Looks5 as FifthIcon,
  Looks6 as SixthIcon,
  Comment,
  ThumbUp as RecommendIcon,
  FilterList as CurationIcon,
  Business as BusinessIcon,
  MonetizationOn as AdvertisementIcon,
  InsertDriveFile as MediaIntroductionIcon,
  Equalizer as EqualizerIcon,
  DeveloperMode as DeveloperModeIcon,
  DeveloperBoard as DeveloperBoardIcon,
  LocationCity as LocationCityIcon,
  FormatListNumbered as FormatListNumberedIcon,
  ShowChart as ShowChartIcon,
  Assessment as AssessmentIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

import SubMenu from './SubMenu'
import { AdType } from 'generated/graphql'

interface ReactAdminState {
  admin: any
}

interface MenuProps {
  dense: boolean
}

const useStyles = makeStyles({
  deActive: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
})

const Menu: FunctionComponent<MenuProps> = ({ dense }) => {
  const translate = useTranslate()
  const { loaded } = usePermissions()
  const classes = useStyles()
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null)

  if (!loaded) {
    return null
  }

  return (
    <div>
      <MenuItemLink
        to={`/`}
        leftIcon={<DashboardIcon />}
        primaryText={translate(`msg.dashboard`)}
        dense={dense}
      />
      {/* <MenuItemLink
        to={`/users`}
        leftIcon={<GroupIcon />}
        primaryText={translate(`resources.users.name`)}
        dense={dense}
      /> */}

      {/* User Page */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="resources.users.name"
        icon={<GroupIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('user')}
          className={selectedMenu !== 'user' && classes.deActive}
          to={`/users2/list`}
          leftIcon={<FormatListNumberedIcon />}
          primaryText={translate(`resources.user.list.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('statistics')}
          className={selectedMenu !== 'statistics' && classes.deActive}
          to={`/users2/statistics`}
          leftIcon={<EqualizerIcon />}
          primaryText={translate(`resources.user.statistics.name`)}
          dense={dense}
        />
      </SubMenu>

      {/* Organization Page */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="resources.organizations.name"
        icon={<LocationCityIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('organizationsList')}
          className={selectedMenu !== 'organizationsList' && classes.deActive}
          to={`/organizations/list`}
          leftIcon={<FormatListNumberedIcon />}
          primaryText={translate(`resources.organizations.list.name`)}
          dense={dense}
        />
      </SubMenu>

      {/* <MenuItemLink
        to={`/signup-sessions`}
        leftIcon={<PersonAddIcon />}
        primaryText={translate(`resources.signup-sessions.name`)}
        dense={dense}
      /> */}

      {/* Activity */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="공고 관리"
        icon={<ListIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('activity')}
          className={selectedMenu !== 'activity' && classes.deActive}
          to={'/activities'}
          leftIcon={<FirstIcon />}
          primaryText={translate(`resources.activities.total.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('activity')}
          className={selectedMenu !== 'activity' && classes.deActive}
          to={`/activities/activity`}
          leftIcon={<SecondIcon />}
          primaryText={translate(`resources.activities.activity.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('club')}
          className={selectedMenu !== 'club' && classes.deActive}
          to={`/activities/club`}
          leftIcon={<ThirdIcon />}
          primaryText={translate(`resources.activities.club.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('contest')}
          className={selectedMenu !== 'contest' && classes.deActive}
          to={`/activities/contest`}
          leftIcon={<FourthIcon />}
          primaryText={translate(`resources.activities.contest.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('recruit')}
          className={selectedMenu !== 'recruit' && classes.deActive}
          to={`/activities/recruit`}
          leftIcon={<FifthIcon />}
          primaryText={translate(`resources.activities.recruit.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('education')}
          className={selectedMenu !== 'education' && classes.deActive}
          to={`/activities/education`}
          leftIcon={<SixthIcon />}
          primaryText={translate(`resources.activities.education.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('replies')}
          className={selectedMenu !== 'replies' && classes.deActive}
          to={`/replies`}
          leftIcon={<Comment />}
          primaryText={translate(`resources.activities.reply.name`)}
          dense={dense}
        />
      </SubMenu>

      {/* Search Page */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="resources.search-page.name"
        icon={<ListIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('recommendKeyword')}
          className={selectedMenu !== 'recommendKeyword' && classes.deActive}
          to={`/search-page/recommend-keyword`}
          leftIcon={<RecommendIcon />}
          primaryText={translate(
            `resources.search-page.recommend-keyword.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('curation')}
          className={selectedMenu !== 'curation' && classes.deActive}
          to={`/search-page/curation`}
          leftIcon={<CurationIcon />}
          primaryText={translate(`resources.search-page.curation.name`)}
          dense={dense}
        />
      </SubMenu>

      {/* Coverletter Page */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="합격자소서 관리"
        icon={<ListIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('coverLetterRecommendKeyword')}
          className={
            selectedMenu !== 'coverLetterRecommendKeyword' && classes.deActive
          }
          to={`/cover-letters/recommend-keyword`}
          leftIcon={<RecommendIcon />}
          primaryText={translate(
            `resources.cover-letters.recommend-keyword.name`,
          )}
          dense={dense}
        />

        <MenuItemLink
          onClick={() => setSelectedMenu('update-field')}
          className={selectedMenu !== 'update-field' && classes.deActive}
          to={`/cover-letters/update-field`}
          leftIcon={<BusinessIcon />}
          primaryText={translate(`resources.cover-letters.update-field.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('all')}
          className={selectedMenu !== 'all' && classes.deActive}
          to={`/cover-letters`}
          leftIcon={<CurationIcon />}
          primaryText={translate(`resources.cover-letters.name`)}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to={`/signups`}
        leftIcon={<GroupIcon />}
        primaryText={translate(`resources.signups.name`)}
        dense={dense}
      />

      {/* Advertisements Page */}
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="(구)광고 관리"
        icon={<AdvertisementIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('hotLineBanner')}
          className={selectedMenu !== 'hotLineBanner' && classes.deActive}
          to={`/advertisement/${AdType.HOTLINE_BANNER.toLowerCase()}`}
          leftIcon={<FirstIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.HOTLINE_BANNER}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('linkareerMagazine')}
          className={selectedMenu !== 'linkareerMagazine' && classes.deActive}
          to={`/linkareer-magazine`}
          leftIcon={<SecondIcon />}
          primaryText={translate(`resources.linkareer-magazine.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('recommendActivities')}
          className={selectedMenu !== 'recommendActivities' && classes.deActive}
          to={`/advertisement/${AdType.RECOMMEND_S_BANNER.toLowerCase()}`}
          leftIcon={<ThirdIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.RECOMMEND_S_BANNER}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('recommendActivities')}
          className={selectedMenu !== 'recommendActivities' && classes.deActive}
          to={`/advertisement/${AdType.RECOMMEND_A_BANNER.toLowerCase()}`}
          leftIcon={<ThirdIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.RECOMMEND_A_BANNER}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('popularActivities')}
          className={selectedMenu !== 'popularActivities' && classes.deActive}
          to={`/advertisement/${AdType.POPULAR_ACTIVITY.toLowerCase()}`}
          leftIcon={<FourthIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.POPULAR_ACTIVITY}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('popularRecruits')}
          className={selectedMenu !== 'popularRecruits' && classes.deActive}
          to={`/advertisement/${AdType.LIVE_POPULAR_RECRUIT.toLowerCase()}`}
          leftIcon={<FifthIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.LIVE_POPULAR_RECRUIT}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('liveActivityInfos')}
          className={selectedMenu !== 'liveActivityInfos' && classes.deActive}
          to={`/advertisement/${AdType.LIVE_ACTIVITY_INFO.toLowerCase()}`}
          leftIcon={<SixthIcon />}
          primaryText={translate(
            `resources.advertisement.${AdType.LIVE_ACTIVITY_INFO}.name`,
          )}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('mediaIntroduction')}
          className={selectedMenu !== 'mediaIntroduction' && classes.deActive}
          to={`/media-introduction`}
          leftIcon={<MediaIntroductionIcon />}
          primaryText={translate(`resources.media-introduction.name`)}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="광고 관리"
        icon={<AdvertisementIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('adPlacement')}
          className={selectedMenu !== 'adPlacement' && classes.deActive}
          to={`/advertisement/placements/list`}
          leftIcon={<FirstIcon />}
          primaryText={translate(`resources.advertisement.ad-placements.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('campaign')}
          className={selectedMenu !== 'campaign' && classes.deActive}
          to={`/advertisement/campaigns/list`}
          leftIcon={<SecondIcon />}
          primaryText={translate(`resources.advertisement.campaigns.name`)}
        />
      </SubMenu>
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="개발자 메뉴"
        icon={<DeveloperModeIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('devtoolsDashboard')}
          className={selectedMenu !== 'devtoolsDashboard' && classes.deActive}
          to={`/devtools/dashboard`}
          leftIcon={<DeveloperBoardIcon />}
          primaryText={translate(`resources.devtools-dashboard.name`)}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        onClick={() => setSelectedMenu(null)}
        sidebarIsOpen={true}
        name="msg.dropoutLog"
        icon={<AssessmentIcon />}
        dense={dense}
      >
        <MenuItemLink
          onClick={() => setSelectedMenu('organizationsList')}
          className={selectedMenu !== 'dropoutlogs/user' && classes.deActive}
          to={`/dropoutlogs/user`}
          leftIcon={<ShowChartIcon />}
          primaryText={translate(`resources.users.name`)}
          dense={dense}
        />
        <MenuItemLink
          onClick={() => setSelectedMenu('dropoutlog')}
          className={
            selectedMenu !== 'dropoutlogs/organization' && classes.deActive
          }
          to={`/dropoutlogs/organization`}
          leftIcon={<ShowChartIcon />}
          primaryText={translate(`resources.organizations.name`)}
          dense={dense}
        />
      </SubMenu>
    </div>
  )
}

export default Menu
