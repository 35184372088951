import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

import { CoverLetterStatus } from 'generated/graphql'

const rowStyle = (selectedRow, theme) => (record, index, defaultStyle = {}) => {
  let style = defaultStyle
  if (selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    }
  }
  if (record.status === CoverLetterStatus.PUBLISHED) {
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  }
  if (record.status === CoverLetterStatus.REJECTED) {
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  }
  return style
}

export default rowStyle
