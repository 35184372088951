import gql from 'graphql-tag'
import {
  CurationGetDocument,
  CurationGetListDocument,
  CurationCreateDocument,
  CurationUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'
import { prepareInput } from './recommend-keyword.query'

export const queryBuilder: QueryBuilder = {
  getList: {
    query: CurationGetListDocument,
  },
  getOne: {
    query: CurationGetDocument,
  },
  create: {
    query: CurationCreateDocument,
  },
  update: {
    query: CurationUpdateDocument,
  },
  prepareInput,
}

gql`
  fragment CurationFragment on Curation {
    id
    title
    url
    seq
  }
`

gql`
  query CurationGetList {
    data: curationList {
      data: curations {
        ...CurationFragment
      }
      total: totalCount
    }
  }
`

gql`
  query CurationGet($id: ID!) {
    data: curation(id: $id) {
      ...CurationFragment
    }
  }
`

gql`
  mutation CurationCreate($input: CurationCreateInput!) {
    data: curationCreate(input: $input) {
      data: curation {
        ...CurationFragment
      }
    }
  }
`

gql`
  mutation CurationUpdate($id: ID!, $input: CurationUpdateInput!) {
    data: curationUpdate(id: $id, input: $input) {
      data: curation {
        ...CurationFragment
      }
    }
  }
`
