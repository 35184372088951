import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserListPage from './list'
import UserStatisticsPage from './statistics'

export default function UsersRouter() {
  return (
    <>
      <Switch>
        <Route path="/users2/list">
          <UserListPage />
        </Route>
        <Route path="/users2/statistics">
          <UserStatisticsPage />
        </Route>
      </Switch>
    </>
  )
}
