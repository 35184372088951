import React, { FunctionComponent } from 'react'
import {
  TextField,
  RichTextField,
  DateField,
  Show,
  SimpleShowLayout,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { CoverLetterTypesField } from '.'

const useStyles = makeStyles({
  cell: {
    display: 'inline-block',
    minWidth: 70,
    margin: '0 10px',
  },
})

const CoverLetterShow: FunctionComponent = props => {
  const classes = useStyles()
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField className={classes.cell} source="id" />
        <TextField className={classes.cell} source="status" />
        <TextField className={classes.cell} source="score" />
        <br />
        <TextField source="sourceKey" />
        <CoverLetterTypesField />
        <TextField className={classes.cell} source="organizationName" />
        <TextField className={classes.cell} source="role" />
        <DateField className={classes.cell} source="passedAt" />
        <br />
        <TextField className={classes.cell} source="university" />
        <TextField className={classes.cell} source="major" />
        <TextField className={classes.cell} source="grades" />
        <TextField className={classes.cell} source="languageScore" />
        <br />
        <TextField className={classes.cell} source="career" />
        <br />
        <TextField className={classes.cell} source="scrapCount" />
        <DateField className={classes.cell} source="createdAt" showTime />
        <DateField className={classes.cell} source="updatedAt" showTime />
        <DateField className={classes.cell} source="deletedAt" showTime />
        <br />
        <RichTextField source="content" />
      </SimpleShowLayout>
    </Show>
  )
}

export default CoverLetterShow
