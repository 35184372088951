import React, { FC } from 'react'
import { UsersByEmailFragment } from 'generated/graphql'
import { makeStyles, Grid } from '@material-ui/core'
import ProfileCard from './ProfileCard'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginTop: 10,
    padding: 10,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0',
    color: '#a6a6a6',
  },
})

interface ProfilesProps {
  loading: boolean
  users?: UsersByEmailFragment[]
  refetch: any
}

const Profiles: FC<ProfilesProps> = ({ loading, users = [], refetch }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {loading || users.length === 0 ? (
          <Grid item xs={12}>
            <div className={classes.noData}>검색 결과를 찾을 수 없습니다.</div>
          </Grid>
        ) : (
          users.map(user => (
            <Grid key={user.id} item xs={4}>
              <ProfileCard user={user} refetch={refetch} />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  )
}

export default Profiles
