import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Box } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  page: {
    minHeight: '100vh',
  },
})

interface LoadingProps {
  variant?: 'default' | 'page'
  className?: string
  CircularProgressProps?: CircularProgressProps
}

const Loading: FunctionComponent<LoadingProps & BoxProps> = ({
  variant = 'default',
  className,
  CircularProgressProps = {},
  ...Props
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(
        classes.root,
        { [classes.page]: variant === 'page' },
        className,
      )}
      {...Props}
    >
      <CircularProgress
        size={variant === 'page' ? 52 : 40}
        {...CircularProgressProps}
      />
    </Box>
  )
}

export default Loading
