import React, { FC } from 'react'
import {
  TextField,
  TextInput,
  SelectInput,
  DateField,
  DateInput,
  UrlField,
  SimpleForm,
  useEditController,
  SaveButton,
  Toolbar,
} from 'react-admin'
import { Button, makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'

import { CoverLetterTypesInput } from '.'
import { CoverLetterStatus } from 'generated/graphql'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 50,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
    marginTop: 98,
  },
  form: {
    marginTop: 100,
    [theme.breakpoints.up('xs')]: {
      width: 900,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    minWidth: 280,
  },
  inlineSecondField: {
    display: 'inline-block',
    minWidth: 400,
    marginRight: 30,
  },
  selectInputField: {
    width: 200,
    minWidth: 200,
    margin: '0 50px',
    marginTop: 25,
  },
  toolbar: {
    position: 'fixed',
    top: 48,
    margin: 0,
    width: '100%',
    height: 100,
  },
  closeButton: {
    right: 15,
    position: 'fixed',
  },
  plagiarismButton: {
    marginLeft: 16,
    paddingRight: 10,
    backgroundColor: '#d6d8ff',
  },
  openInBrowserIcon: {
    transform: 'rotate(270deg)',
    fontSize: '1.2rem',
    marginLeft: 4,
  },
}))

export const coverLetterStatusChoices = Object.keys(CoverLetterStatus).map(
  status => ({
    id: status,
    name: `enum.CoverLetterStatus.${status}`,
  }),
)

const CoverLetterEditToolbar: FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()

  const onClickPlagiarismCheckButton = () => {
    history.push(`/cover-letters/${props.record.id}?plagiarism=true`)
  }
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton />
      <SelectInput
        source="status"
        choices={coverLetterStatusChoices}
        className={classes.selectInputField}
      />
      <CoverLetterTypesInput variant="standard" />
      <Button
        variant="contained"
        onClick={onClickPlagiarismCheckButton}
        className={classes.plagiarismButton}
      >
        표절률 검사 패널
        <OpenInBrowserIcon className={classes.openInBrowserIcon} />
      </Button>
      <IconButton onClick={props.onCancel} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
  )
}
const CoverLetterEdit: FC<any> = props => {
  const { onCancel, ...others } = props

  const classes = useStyles()
  const controllerProps = useEditController({ ...others, undoable: false })

  if (!controllerProps.record) {
    return null
  }

  return (
    <div className={classes.root}>
      <SimpleForm
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        redirect={false}
        resource="cover-letters"
        toolbar={<CoverLetterEditToolbar onCancel={onCancel} />}
      >
        <TextField source="id" formClassName={classes.inlineField} />
        <TextField source="score" formClassName={classes.inlineField} />
        <UrlField
          source="sourceKey"
          formClassName={classes.inlineField}
          target="_blank"
          fullWidth
        />
        <br />
        <DateInput source="passedAt" formClassName={classes.inlineField} />
        <TextInput
          source="organizationName"
          formClassName={classes.inlineField}
        />
        <TextInput source="role" formClassName={classes.inlineField} />
        <TextInput source="university" formClassName={classes.inlineField} />
        <TextInput source="major" formClassName={classes.inlineField} />
        <TextInput source="grades" formClassName={classes.inlineField} />
        <TextInput
          source="languageScore"
          formClassName={classes.inlineSecondField}
          fullWidth
        />
        <TextInput
          source="career"
          formClassName={classes.inlineSecondField}
          fullWidth
        />
        <br />
        <TextInput
          source="activity"
          formClassName={classes.inlineSecondField}
          fullWidth
        />
        <TextInput
          source="license"
          formClassName={classes.inlineSecondField}
          fullWidth
        />
        <br />
        <TextInput source="content" variant="standard" multiline fullWidth />
        <br />
        <TextField source="scrapCount" formClassName={classes.inlineField} />
        <DateField
          source="createdAt"
          showTime
          formClassName={classes.inlineField}
        />
        <DateField
          source="updatedAt"
          showTime
          formClassName={classes.inlineField}
        />
        <br />
        <br />
      </SimpleForm>
    </div>
  )
}

export default CoverLetterEdit
