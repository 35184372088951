import clsx from 'clsx'
import React, {
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  ImgHTMLAttributes,
} from 'react'
import { Button, Typography, Tooltip, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  inputWrapper: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    height: 60,
    '&:hover': {
      border: '1px solid rgba(0,0,0,0.87) !important',
    },
  },
  buttonWrapper: {
    width: '100%',
    height: '100%',
  },
  button: {
    width: '100%',
    height: '100%',
    color: '#757575',
  },
  img: {
    borderRadius: 5,
    border: '1px solid #e8e8e8',
    maxWidth: 300,
    maxHeight: 150,
    transition: '.3s ease',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  previewWrapper: {
    paddingTop: 20,
    display: 'flex',
  },
  iconButton: {
    color: '#f50057',
    alignItems: 'flex-start',
    height: 'fit-content',
  },
  error: {
    border: '1px solid red !important',
  },
  errorText: {
    color: 'red',
  },
  helperText: {
    fontSize: '0.75rem',
    marginTop: 3,
    margin: '0 14px',
  },
})

interface FileInputProps {
  id: string
  name: string
  register: any
  error?: boolean
  helperText?: string
  errorText?: string
  inputProps?:
    | HTMLAttributes<HTMLInputElement>
    | InputHTMLAttributes<HTMLInputElement>
  className?: string
  placeholder?: string
  imgProps?: ImgHTMLAttributes<HTMLImageElement>
  handleDelete?: () => void
}

/**
 * Use in react-hook form
 */
const FileInput: FC<FileInputProps> = ({
  id,
  name,
  register,
  error,
  helperText,
  errorText,
  inputProps,
  className,
  placeholder,
  imgProps,
  handleDelete,
}) => {
  const classes = useStyles()
  const { src } = imgProps || {}

  return (
    <div className={clsx(classes.root, className)}>
      <input
        ref={register}
        name={name}
        style={{ display: 'none' }}
        id={id}
        type="file"
        {...inputProps}
      />

      <div className={clsx(classes.inputWrapper, { [classes.error]: error })}>
        <label htmlFor={id} className={classes.buttonWrapper}>
          <Button className={classes.button} component="span">
            <Typography>{placeholder || '업로드'}</Typography>
          </Button>
        </label>
      </div>
      {(helperText || errorText) && (
        <Typography
          className={clsx(classes.helperText, { [classes.errorText]: error })}
        >
          {(error && errorText) || helperText}
        </Typography>
      )}

      {src && (
        <div className={classes.previewWrapper}>
          <IconButton className={classes.iconButton} onClick={handleDelete}>
            <CancelIcon />
          </IconButton>
          <Tooltip title="이미지 미리보기" arrow>
            <img
              className={classes.img}
              onClick={() => window.open(src)}
              {...imgProps}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default FileInput
