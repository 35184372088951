import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import {
  TextField,
  DateField,
  SelectField,
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import KibanaField, { KibanaFieldProps } from 'components/fields/KibanaField'
import { userTypeChoices } from './UserList'
import { Typography } from '@material-ui/core'

interface SignupSessionFieldProps extends Omit<KibanaFieldProps, 'esField'> {}

const SignupSessionField: FunctionComponent<SignupSessionFieldProps> = props => {
  const { record } = props
  const ipAddress = get(record, 'signupSession.ipAddress')
  const source = ipAddress ? 'signupSession.ipAddress' : 'specupJoinedSession'

  return (
    <KibanaField
      {...props}
      source={source}
      esField={ipAddress ? 'ip' : 'sessionID'}
    />
  )
}

interface DeletedAtFieldProps {
  record?: any
}

const DeletedAtField: FunctionComponent<DeletedAtFieldProps> = props => {
  const { record } = props
  if (record?.deletedAt) {
    return <Typography variant="h4">탈퇴한 회원입니다</Typography>
  }
  return <></>
}
// @ts-ignore
SignupSessionField.defaultProps = {
  addLabel: true,
}
interface UserShowProps {
  record?: any
}

const UserShow: FunctionComponent<UserShowProps> = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="msg.summary">
          <DeletedAtField />
          <TextField source="id" label="msg.id" />
          <SelectField
            source="type"
            sortable={false}
            choices={userTypeChoices}
          />
          <TextField source="username" />
          <TextField source="facebook.id" />
          <TextField source="kakao.id" />
          <TextField source="naver.id" />

          <TextField source="email" />
          <DateField source="birthday" />
          <TextField source="phoneNumber" />
          <DateField source="lastLoginAt" showTime />
          {/* <SessionField source="lastLoginSession" dateSource="lastLoginAt" />
          <BooleanField source="specupJoined" /> */}
          <DateField source="specupJoinedAt" showTime />
          <DateField source="createdAt" showTime />
          <DateField source="deletedAt" showTime />
          <SignupSessionField
            source="signupSession.ipAddress"
            dateSource="specupJoinedAt"
            label="msg.signupLog"
          />
        </Tab>
        <Tab label="msg.timespread">
          <DeletedAtField />
          <TextField source="timespread.id" />
          <TextField source="timespread.name" />
          <TextField source="timespread.email" />
          <DateField source="timespread.birthday" />
          <TextField source="timespread.profileURL" />
          <TextField source="timespread.phoneNumber" />
          <TextField source="timespread.cash" />
          <TextField source="timespread.facebookID" />
          <TextField source="timespread.kakaoID" />
          <TextField source="timespread.naverID" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default UserShow
