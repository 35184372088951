import React, { FC /* useState, MouseEvent, useCallback */ } from 'react'
// import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
// import { makeStyles } from '@material-ui/core/styles'
import { CampaignStatisticsByDate } from '../CampaignReport'
import ImpressionAndClickByPlacementChart from './ImpressionAndClickByPlacementChart'
import PlacementByImpressionAndClickChart from './PlacementByImpressionAndClickChart'

enum VIEWS {
  IMPRESSION_AND_CLICK_BY_PLACEMENT,
  PLACEMENT_BY_IMPRESSION_AND_CLICK,
}

const ChartComponent: FC<{
  view: VIEWS
  data: CampaignStatisticsByDate[]
}> = ({ view, data }) => {
  switch (view) {
    case VIEWS.IMPRESSION_AND_CLICK_BY_PLACEMENT:
      return <ImpressionAndClickByPlacementChart data={data} />
    case VIEWS.PLACEMENT_BY_IMPRESSION_AND_CLICK:
      return <PlacementByImpressionAndClickChart data={data} />
    default:
      return null
  }
}

// const useStyles = makeStyles({
//   viewButtonGroup: {
//     display: 'flex',
//     marginBottom: 25,
//   },
//   viewButton: {
//     flex: 1,
//   },
// })

interface CampaignReportChartProps {
  campaignStatisticsByDateArray: CampaignStatisticsByDate[]
}

const CampaignReportChart: FC<CampaignReportChartProps> = ({
  campaignStatisticsByDateArray,
}) => {
  // const classes = useStyles()
  // const [view, setView] = useState<VIEWS>(
  //   VIEWS.IMPRESSION_AND_CLICK_BY_PLACEMENT,
  // )

  // const handleViewChange = useCallback(
  //   (e: MouseEvent<HTMLElement>, newView: VIEWS) => {
  //     if (newView !== null) {
  //       setView(newView)
  //     }
  //   },
  //   [],
  // )

  return (
    <div>
      {/* <ToggleButtonGroup
        className={classes.viewButtonGroup}
        value={view}
        onChange={handleViewChange}
        exclusive
      >
        <ToggleButton
          className={classes.viewButton}
          value={VIEWS.IMPRESSION_AND_CLICK_BY_PLACEMENT}
        >
          지면별 노출/클릭 그래프
        </ToggleButton>
        <ToggleButton
          className={classes.viewButton}
          value={VIEWS.PLACEMENT_BY_IMPRESSION_AND_CLICK}
        >
          노출,클릭별 지면 그래프
        </ToggleButton>
      </ToggleButtonGroup> */}
      <ChartComponent
        view={VIEWS.IMPRESSION_AND_CLICK_BY_PLACEMENT}
        data={campaignStatisticsByDateArray}
      />
    </div>
  )
}

export default CampaignReportChart
