import React, {
  useState,
  useCallback,
  RefForwardingComponent,
  forwardRef,
  ImgHTMLAttributes,
  HTMLAttributes,
  ChangeEvent,
  InputHTMLAttributes,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Typography, Button, IconButton, Tooltip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { createPreviewURL } from 'utils/fileUpload'

const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.8rem',
  },
  input: {
    display: 'none',
  },
  inputWrapper: {
    display: 'block',
    textAlign: 'center',
    borderRadius: 4,
    border: '1px solid #c4c4c4',
    textTransform: 'none',
    padding: 20,
  },
  guideText: {
    fontSize: '0.75rem',
  },
  helperText: {
    color: '#000',
  },
  errorText: {
    color: '#f50067',
  },
  thumbnailWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  thumbnail: {
    maxWidth: 300,
    cursor: 'pointer',
    transition: '.3s ease',
    '&:hover': {
      opacity: 0.5,
    },
  },
  thumbnailDeleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#f50067',
  },
})

interface ImageInputProps {
  label?: string
  name?: string
  helperText?: string
  errorText?: string
  onImageChange?(imageFiles?: FileList | null | undefined): void
  onImageDelete?(imageFiles?: FileList | null | undefined): void
  inputProps?:
    | HTMLAttributes<HTMLInputElement>
    | InputHTMLAttributes<HTMLInputElement>
  thumbnailProps?: ImgHTMLAttributes<HTMLImageElement>
}

const ImageInput: RefForwardingComponent<HTMLInputElement, ImageInputProps> = (
  {
    label,
    name = '',
    helperText,
    errorText,
    onImageChange,
    onImageDelete,
    inputProps = {},
    thumbnailProps = {},
  },
  ref,
) => {
  const { src: thumbnailDefaultSrc } = thumbnailProps
  const { placeholder } = inputProps

  const classes = useStyles()
  const [imageFileList, setImageFileList] = useState<
    FileList | null | undefined
  >()
  const [imageURL, setImageURL] = useState<string | undefined>(
    thumbnailDefaultSrc,
  )
  const { setValue } = useFormContext()

  const imageFileURL = createPreviewURL(Array.from(imageFileList || []))

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newImageFileList = e.target.files
      const newImageFileURL = createPreviewURL(
        Array.from(newImageFileList || []),
      )
      setValue(name, newImageFileList)
      setImageFileList(newImageFileList)
      setImageURL(newImageFileURL)
      onImageChange?.(newImageFileList)
      window.URL.revokeObjectURL(imageFileURL || '')
    },

    [name, onImageChange],
  )

  const handleImageDelete = useCallback(() => {
    setValue(name, undefined)
    setImageFileList(undefined)
    setImageURL(undefined)
    onImageDelete?.(imageFileList)
    window.URL.revokeObjectURL(imageFileURL || '')
  }, [name, onImageDelete])

  return (
    <Box>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Button className={classes.inputWrapper} component="label">
        {placeholder}
        <input
          {...inputProps}
          className={classes.input}
          type="file"
          ref={ref}
          name={name}
          accept="image/*"
          onChange={handleImageChange}
        />
      </Button>
      {(helperText || errorText) && (
        <Typography
          className={clsx(
            classes.guideText,
            errorText ? classes.errorText : classes.helperText,
          )}
        >
          {errorText || helperText}
        </Typography>
      )}
      {imageURL && (
        <div className={classes.thumbnailWrapper}>
          <Tooltip title="이미지 미리보기" arrow>
            <img
              {...thumbnailProps}
              src={imageURL}
              className={classes.thumbnail}
              onClick={() => window.open(imageURL)}
            />
          </Tooltip>
          <IconButton
            className={classes.thumbnailDeleteButton}
            onClick={handleImageDelete}
          >
            <CancelIcon />
          </IconButton>
        </div>
      )}
    </Box>
  )
}

export default forwardRef(ImageInput)
