import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { calculateCTR } from '../CampaignReport'

const useStyles = makeStyles({
  tableFooter: {
    backgroundColor: '#eaeaea',

    '& th': {
      padding: '8px 5px',
    },
  },
})

interface CampaignReportTableFooterProps {
  totalImpressionCount: number
  totalClickCount: number
}

const CampaignReportTableFooter: FC<CampaignReportTableFooterProps> = ({
  totalImpressionCount,
  totalClickCount,
}) => {
  const classes = useStyles()

  const totalCTR =
    calculateCTR({
      impressionCount: totalImpressionCount,
      clickCount: totalClickCount,
    }) || 0

  return (
    <tfoot className={classes.tableFooter}>
      <tr>
        <th colSpan={2}>total</th>
        <th align="right">{totalImpressionCount}</th>
        <th align="right">{totalClickCount}</th>
        <th align="right">{totalCTR.toFixed(3)}%</th>
      </tr>
    </tfoot>
  )
}

export default CampaignReportTableFooter
