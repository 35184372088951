import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useReplyDeleteMutation } from 'generated/graphql'
import { DateField, ReferenceField, TextField } from 'react-admin'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  replyRegistrationInfo: {
    '& dt': {
      fontWeight: 'bold',
      minWidth: '4em',
      float: 'left',
      clear: 'both',
    },

    '& dd': {
      marginRight: 10,
      marginBottom: 10,
      overflow: 'auto',
    },
  },
})

interface ReplyDeleteDialogProps {
  open: boolean
  onClose?: () => void
  record: any
}

const ReplyDeleteDialog: FC<ReplyDeleteDialogProps> = ({
  open,
  onClose: handleClose,
  record,
}) => {
  const classes = useStyles()
  const replyID = record.id

  const history = useHistory()
  const [replyDelete] = useReplyDeleteMutation()
  const handleDeleteClick = useCallback(async () => {
    await replyDelete({
      variables: {
        id: replyID,
      },
    })
    history.go(0)
  }, [history, replyDelete, replyID])

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>댓글을 삭제하시겠습니까?</DialogTitle>
      <DialogContent>
        <dl className={classes.replyRegistrationInfo}>
          <dt>작성자</dt>
          <dd>
            {record.userID ? (
              <ReferenceField
                resource="replies"
                reference="users"
                source="userID"
                basePath="/replies"
                record={record}
              >
                <TextField source="username" />
              </ReferenceField>
            ) : (
              '익명'
            )}
          </dd>
          <dt>작성일</dt>
          <dd>
            <DateField record={record} source="createdAt" />
          </dd>
          <dt>공고명</dt>
          <dd>
            <ReferenceField
              resource="replies"
              reference="activities"
              source="pageID"
              basePath="/replies"
              record={record}
            >
              <TextField source="title" />
            </ReferenceField>
          </dd>
          <dt>내용</dt>
          <dd>{record.content}</dd>
        </dl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          취소
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteClick}
        >
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReplyDeleteDialog
