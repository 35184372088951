import React, { FC, ChangeEvent, useState } from 'react'
import { Placement_AdPlacementFragment } from 'generated/graphql'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ImageInput from 'components/ImageInput'
import { urlRegexIncludingProtocol } from 'utils'
import { getImageURL } from 'utils/fileUpload'
import { ADVERTISEMENT } from 'hooks/useAttachmentFileUpload'

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
})

export interface PlacementFormData {
  id?: string
  code: string
  name: string
  width: string
  height: string
  isActive: boolean
  defaultImageFiles: FileList
  defaultLandingURL: string
  defaultImageID: string
}

interface PlacementFormProps {
  defaultValues?: Placement_AdPlacementFragment | null | undefined
  onSubmit(data: PlacementFormData): void
}

const PlacementForm: FC<PlacementFormProps> = ({ defaultValues, onSubmit }) => {
  const classes = useStyles()
  const {
    id,
    code,
    name,
    width,
    height,
    isActive,
    defaultLandingURL,
    defaultImage,
  } = defaultValues || {}
  const [defaultImageID, setDefaultImageID] = useState(() => {
    if (!defaultImage) {
      return
    }

    return defaultImage.id
  })

  const methods = useForm<PlacementFormData>()
  const { handleSubmit, register, setValue, errors } = methods

  const isEdit = !!id
  const isPlacementActive = isEdit ? !!isActive : true

  const handleIsActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('isActive', e.target.checked)
  }

  const handleImageDelete = () => {
    setDefaultImageID(undefined)
  }

  const onSubmitMiddelware = data => {
    // react-hook-form에서 regist 되지 않은 데이터는 어찌 injection 해야하는가..
    onSubmit({ ...data, defaultImageID })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitMiddelware)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isActive"
                  defaultChecked={isPlacementActive}
                  inputRef={register}
                  onChange={handleIsActiveChange}
                />
              }
              label="지면 활성화 여부"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="name"
              label="지면 이름"
              variant="outlined"
              fullWidth
              required
              defaultValue={name}
              inputRef={register({ maxLength: 128 })}
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="code"
              label="코드"
              variant="outlined"
              fullWidth
              required
              disabled={isEdit}
              defaultValue={code}
              inputRef={register({ maxLength: 65 })}
              error={!!errors.code}
            />
            {errors.code && (
              <Typography variant="caption" color="error">
                {errors.code.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="width"
              type="number"
              label="너비"
              variant="outlined"
              fullWidth
              required
              disabled={isEdit}
              defaultValue={width}
              inputRef={register}
              error={!!errors.width}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="height"
              type="number"
              label="높이"
              variant="outlined"
              fullWidth
              required
              disabled={isEdit}
              defaultValue={height}
              inputRef={register}
              error={!!errors.height}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="defaultLandingURL"
              label="하우스 배너 랜딩 URL"
              variant="outlined"
              fullWidth
              defaultValue={defaultLandingURL}
              inputRef={register({ pattern: urlRegexIncludingProtocol })}
              error={!!errors.defaultLandingURL}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageInput
              name="defaultImageFiles"
              label="하우스 배너 이미지"
              ref={register}
              inputProps={{
                placeholder: isEdit
                  ? `size: ${width} x ${height}`
                  : `입력하신 너비, 높이에 맞는 이미지를 첨부해주세요.`,
              }}
              thumbnailProps={{
                src: getImageURL(ADVERTISEMENT, defaultImage?.id ?? ''),
              }}
              onImageDelete={handleImageDelete}
            />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button type="submit" color="secondary" variant="contained">
              저장
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default PlacementForm
