import React, { FC } from 'react'
// import gql from 'graphql-tag'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  TopToolbar,
  ShowButton,
} from 'react-admin'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { activityStatusChoices } from './ActivityList'

const useStyles = makeStyles({
  editFieldsTitle: {
    marginTop: 20,
    fontWeight: 500,
  },
  topToolbar: {
    justifyContent: 'flex-end',
  },
})
interface ActivityEditActionsProps {
  basePath?: string
  data?: any
  resource?: any
}

const ActivityEdit: FC = props => {
  const classes = useStyles()

  return (
    <Edit actions={<ActivityEditActions />} {...props}>
      <SimpleForm {...props}>
        <TextInput source="id" label="msg.id" disabled />
        <TextInput source="title" disabled />
        <TextInput source="organizationName" disabled />
        <TextInput source="manager.username" disabled />
        <TextInput source="manager.email" disabled />
        <TextInput source="manager.phoneNumber" disabled />

        <DateInput source="recruitCloseAt" disabled />
        <DateInput source="createdAt" disabled />

        <Typography className={classes.editFieldsTitle}>수정 가능</Typography>
        <SelectInput source="status" choices={activityStatusChoices} />
        <TextInput multiline source="refusalReason" />
      </SimpleForm>
    </Edit>
  )
}

const ActivityEditActions: FC<ActivityEditActionsProps> = ({
  basePath,
  data,
}) => {
  const classes = useStyles()

  return (
    <TopToolbar>
      <Grid container className={classes.topToolbar}>
        <ShowButton basePath={basePath} record={data} />
      </Grid>
    </TopToolbar>
  )
}

export default ActivityEdit
