import React, { FC } from 'react'

const OrganizationDropoutLog: FC = () => {
  return (
    <>
      <iframe
        src="https://redash.linkareer.com/public/dashboards/PjrvFyavxs3OoMdqt6jwJwqmGX3ZBUxrH4Q7oq1d?org_slug=default"
        height="1000"
      ></iframe>
    </>
  )
}

export default OrganizationDropoutLog
