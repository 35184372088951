import gql from 'graphql-tag'
import {
  SignupUserGetDocument,
  SignupUserGetListDocument,
  SignupUserGetManyDocument,
  SignupUserUpdateDocument,
  SignupUserDeleteDocument,
  SignupUserDeleteManyDocument,
  UserInput,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface SignupUserInputInEditor extends UserInput {
  lastLoginSession: string
  timespread: any
}

// SignupUserDetailFragment 안에 들어 있지만 SignupUserFragment에 없는 데이터를 항상 diff에서 추출함
// ex. previousData.lastLoginSession == null, data.lastLoginSession = ''
// 더 나은 해결책이 있을 것 같다
function prepareInput(input: SignupUserInputInEditor): UserInput {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lastLoginSession, timespread, ...other } = input
  return other
}

export const queryBuilder: QueryBuilder = {
  getList: {
    query: SignupUserGetListDocument,
  },
  getOne: {
    query: SignupUserGetDocument,
  },
  getMany: {
    query: SignupUserGetManyDocument,
  },
  update: {
    query: SignupUserUpdateDocument,
  },
  delete: {
    query: SignupUserDeleteDocument,
  },
  deleteMany: {
    query: SignupUserDeleteManyDocument,
  },
  prepareInput,
}

gql`
  fragment SignupUserFragment on User {
    id
    type
    username
    email
    birthday
    phoneNumber
    timespread {
      id
      name
      email
      birthday
      profileURL
      phoneNumber
      cash
      facebookID
      kakaoID
      naverID
    }
    facebook {
      id
    }
    kakao {
      id
    }
    naver {
      id
    }
    createdAt
    lastLoginAt

    specupJoined
    specupJoinedAt
    specupJoinedSession
    deactivated
  }
`

gql`
  fragment SignupUserDetailFragment on User {
    ...SignupUserFragment
    lastLoginSession
    timespreadID
    timespreadAuthKey
  }
`

gql`
  query SignupUserGetList(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    data: users(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...SignupUserFragment
      }
      total: totalCount
    }
  }
`

gql`
  query SignupUserGet($id: ID!) {
    data: user(id: $id) {
      ...SignupUserDetailFragment
    }
  }
`

gql`
  query SignupUserGetMany($ids: [ID!]!) {
    data: usersByID(ids: $ids) {
      ...SignupUserFragment
    }
  }
`

gql`
  mutation SignupUserUpdate($id: ID!, $input: UserInput!) {
    data: userUpdate(id: $id, input: $input) {
      data: user {
        ...SignupUserDetailFragment
      }
    }
  }
`

gql`
  mutation SignupUserDelete($id: ID!) {
    data: userDelete(id: $id) {
      data: user {
        ...SignupUserFragment
      }
    }
  }
`

gql`
  mutation SignupUserDeleteMany($ids: [ID!]!) {
    data: userDeleteMany(ids: $ids) {
      data: users {
        ...SignupUserFragment
      }
    }
  }
`
