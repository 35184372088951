import React from 'react'
import { Button, Divider, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LINKAREER_CMS_DOMAIN } from 'config'

const useStyles = makeStyles({
  root: {
    margin: '20px 0',
    maxHeight: '100vh',
    height: '100%',
    padding: 50,
  },
  divider: {
    margin: '30px 0',
  },
  fileInput: {
    marginBottom: 30,
    width: '50%',
  },
  preview: {
    marginBottom: 30,
    '& p': {
      fontSize: 15,
    },
  },
  textInput: {
    display: 'flex',
    width: '50%',
    marginBottom: 40,
  },
  url: {
    '& p': {
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
  },
  button: {
    marginTop: 30,
  },
})

const LinkareerMagazine = () => {
  const classes = useStyles()

  const handleOnClick = () => {
    console.log('click')
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">
        {'광고 관리 > 합격 자료 > 소재 관리'}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="h5">
        해당 기능은 더이상 어드민에서 지원하지 않습니다. <br />
        신규 CMS의 합격자료를 이용해주세요.
      </Typography>
      <Link href={`${LINKAREER_CMS_DOMAIN}`}>
        <Button
          className={classes.button}
          color="secondary"
          variant="contained"
        >
          신규 CMS로 이동하기
        </Button>
      </Link>
    </Paper>
  )
}

export default LinkareerMagazine
