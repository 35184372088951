import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import {
  usePlacementGetOneQuery,
  usePlacementEdit_AdPlacementUpdateMutation,
  AdPlacementUpdateInput,
} from 'generated/graphql'
import { NetworkStatus } from '@apollo/client'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { useSnackbar } from 'notistack'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import { BaseLayout } from 'components/layout/content'
import PlacementForm, { PlacementFormData } from './PlacementForm'
import useAttachmentFileUpload, {
  ADVERTISEMENT,
} from 'hooks/useAttachmentFileUpload'
import useAttachmentFileDelete from 'hooks/useAttachmentFileDelete'

interface PlacementEditProps {
  id: string
}

const PlacementEdit: FC<PlacementEditProps> = ({ id }) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueApolloError } = useErrorSnackbar()
  const { data, error, networkStatus } = usePlacementGetOneQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })
  const { fileUpload } = useAttachmentFileUpload({
    path: ADVERTISEMENT,
  })
  const { fileDelete } = useAttachmentFileDelete()
  const [updatePlacement] = usePlacementEdit_AdPlacementUpdateMutation({
    onCompleted: () => {
      enqueueSnackbar('광고 지면이 수정되었습니다.', { variant: 'success' })
      history.push('/advertisement/placements/list')
    },
    onError: enqueueApolloError,
  })

  const placement = data?.adPlacement

  if (!placement) {
    return null
  }

  const handleSubmit = async ({
    name,
    isActive,
    defaultImageFiles,
    defaultLandingURL,
    defaultImageID,
  }: PlacementFormData) => {
    const newDefaultImageFile = defaultImageFiles[0]

    if (newDefaultImageFile || !defaultImageID) {
      await fileDelete(placement.defaultImage?.id ?? '')
    }

    const uploadedImage = newDefaultImageFile
      ? await fileUpload(newDefaultImageFile)
      : undefined

    const placementInput: AdPlacementUpdateInput = {
      name,
      isActive,
      defaultImageID: uploadedImage?.id ?? defaultImageID,
      defaultLandingURL,
    }

    await updatePlacement({
      variables: {
        id,
        input: placementInput,
      },
    })
  }

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <BaseLayout title={`광고 지면 ${placement.name} 수정`}>
      <PlacementForm defaultValues={placement} onSubmit={handleSubmit} />
    </BaseLayout>
  )
}

export default PlacementEdit

gql`
  mutation PlacementEdit_adPlacementUpdate(
    $id: ID!
    $input: AdPlacementUpdateInput!
  ) {
    adPlacementUpdate(id: $id, input: $input) {
      adPlacement {
        id
      }
    }
  }
`
