import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { PlacementEdit } from 'resources/advertisement'

interface PlacementEditPageParams {
  id: string
}

const PlacementEditPage: FC = () => {
  const { id: placementID } = useParams<PlacementEditPageParams>()
  return <PlacementEdit id={placementID} />
}

export default PlacementEditPage
