import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignEdit } from 'resources/advertisement/campaigns'

interface CampaignEditPageParams {
  id: string
}

const CampaignEditPage: FC = () => {
  const { id: campaignID } = useParams<CampaignEditPageParams>()
  return <CampaignEdit id={campaignID} />
}

export default CampaignEditPage
