import React from 'react'
import { Route } from 'react-router-dom'
import Users from './users'
import Campaigns from './advertisement/campaigns'
import Placements from './advertisement/placements'
import DevTools from './devtools'
import Organizations from './organizations'
import DropoutLogs from './dropoutlogs'

export default [
  <Route key="/users2" path="/users2" component={Users} />,
  <Route
    key="/organizations"
    path="/organizations"
    component={Organizations}
  />,
  <Route
    key="/advertisement/campaigns"
    path="/advertisement/campaigns"
    component={Campaigns}
  />,
  <Route
    key="/advertisement/placements"
    path="/advertisement/placements"
    component={Placements}
  />,
  <Route key="/devtools" path="/devtools" component={DevTools} />,
  <Route key="/dropoutLogs" path="/dropoutLogs" component={DropoutLogs} />,
]
