import gql from 'graphql-tag'
import {
  ReplyFragmentFragment,
  ReplyGetOneDocument,
  ReplyGetListDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface Reply extends Omit<ReplyFragmentFragment, 'activity' | 'user'> {
  pageID?: string
  userID?: string
}

function parseRecord(options: ReplyFragmentFragment): Reply {
  const { activity, user, ...other } = options
  return {
    ...other,
    pageID: activity?.id,
    userID: user?.id,
  }
}

export const queryBuilder: QueryBuilder = {
  getOne: {
    query: ReplyGetOneDocument,
  },
  getList: {
    query: ReplyGetListDocument,
  },
  parseRecord,
}

gql`
  fragment ReplyFragment on Reply {
    id
    content
    activity {
      id
    }
    user {
      id
    }
    createdAt

    replies {
      id
      content
      activity {
        id
      }
      user {
        id
      }
      createdAt
    }
  }
`

gql`
  query ReplyGetOne($id: ID!) {
    data: reply(id: $id) {
      ...ReplyFragment
    }
  }
`

gql`
  query ReplyGetList(
    $filterBy: ReplyFilters
    $orderBy: ReplyOrder
    $pagination: Pagination
  ) {
    data: replies(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ReplyFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation ReplyDelete($id: ID!) {
    data: replyDeleteByAdministrator(id: $id) {
      data: reply {
        ...ReplyFragment
      }
    }
  }
`
