import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  Edit,
  SimpleForm,
  TextInput,
  number,
  minValue,
  maxValue,
  SelectInput,
} from 'react-admin'
import { mapStateToProps } from './CoverLetterRecommendKeywordList'

import { SearchKeywordFieldType } from 'generated/graphql'
import {
  RecommendKeywordEditToolbar,
  RecommendKeywordEditProps,
} from 'resources/search-page/recommendKeyword/RecommendKeywordEdit'

const CoverLetterRecommendKeywordEdit: FunctionComponent<RecommendKeywordEditProps> = props => {
  return (
    <Edit
      title="resources.cover-letters.recommend-keyword.edit"
      id={props.match.params.id}
      {...props}
    >
      <SimpleForm toolbar={<RecommendKeywordEditToolbar />}>
        <TextInput source="title" />
        <SelectInput
          source="searchField"
          choices={[
            {
              id: SearchKeywordFieldType.ORGANIZATION_NAME,
              name: 'enum.SearchKeywordFieldType.OrganizationName',
            },
            {
              id: SearchKeywordFieldType.ROLE,
              name: 'enum.SearchKeywordFieldType.Role',
            },
            {
              id: SearchKeywordFieldType.KEYWORD,
              name: 'enum.SearchKeywordFieldType.Keyword',
            },
          ]}
        />
        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 5까지 입력가능합니다.'),
            maxValue(5, '1부터 5까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}

export default connect(mapStateToProps)(CoverLetterRecommendKeywordEdit)
