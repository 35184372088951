import gql from 'graphql-tag'
import {
  AdvertisementGetDocument,
  AdvertisementGetListDocument,
  AdvertisementDeleteDocument,
  AdvertisementDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
  getOne: { query: AdvertisementGetDocument },
  getList: { query: AdvertisementGetListDocument },
  delete: {
    query: AdvertisementDeleteDocument,
  },
  deleteMany: {
    query: AdvertisementDeleteManyDocument,
  },
}

gql`
  fragment Advertisement on Advertisement {
    id
    adType
    activityID
    outLink
    title
    content
    pcImage
    mobileImage
    startAt
    endAt
    status
    seq

    activity {
      id
      title
    }
  }
`

gql`
  query AdvertisementGet($id: ID!) {
    data: advertisement(id: $id) {
      ...Advertisement
    }
  }
`

gql`
  query AdvertisementGetList(
    $filterBy: AdvertisementFilter!
    $orderBy: AdvertisementOrder
    $pagination: Pagination
  ) {
    data: advertisementList(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: advertisements {
        ...Advertisement
      }
      total: totalCount
    }
  }
`

gql`
  mutation AdvertisementDelete($id: ID!) {
    data: advertisementDelete(id: $id) {
      data: advertisement {
        ...Advertisement
      }
    }
  }
`

gql`
  mutation AdvertisementDeleteMany($ids: [ID!]!) {
    data: advertisementBulkDelete(ids: $ids) {
      data: advertisements {
        ...Advertisement
      }
    }
  }
`
