import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'

interface BooleanCell {
  value?: boolean
}

const BooleanCell: React.FC<BooleanCell> = ({ value }) => {
  return value ? <DoneIcon /> : <CloseIcon />
}

export default BooleanCell
