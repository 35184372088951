import React, {
  RefForwardingComponent,
  useImperativeHandle,
  useState,
  useEffect,
  forwardRef,
} from 'react'
import { useFormContext } from 'react-hook-form'

import { AdvertisementFormData, ImageSizeByType } from '../types'
import { createPreviewURL } from 'utils/fileUpload'
import FileInput from 'components/FileInput'
import { AdType } from 'generated/graphql'

interface ImageFieldProps {
  adType: AdType
  fieldName: 'pcImage' | 'mobileImage'
  className?: string
  imageURLFromData?: string // from query result data
}

export interface ImageFieldHandle {
  isError: () => boolean
}

const ImageField: RefForwardingComponent<ImageFieldHandle, ImageFieldProps> = (
  props,
  ref,
) => {
  const { className, adType, imageURLFromData, fieldName } = props
  const [previewURL, setPreviewURL] = useState<string>()
  const [imageURL, setImageURL] = useState<string>()

  useImperativeHandle(
    ref,
    () => ({
      isError: () => {
        return !previewURL && !imageURL
      },
    }),
    [previewURL, imageURL],
  )

  const { watch, setValue, register } = useFormContext<AdvertisementFormData>()
  const watchImage = watch(fieldName)

  const handleDelete = () => {
    setValue(fieldName, undefined)
    setImageURL(undefined)
    window.URL.revokeObjectURL(previewURL || '')
  }

  useEffect(() => {
    if (imageURLFromData) {
      setImageURL(imageURLFromData)
    }
  }, [imageURLFromData])

  useEffect(() => {
    if (watchImage) {
      const previewURL = createPreviewURL(watchImage)
      if (previewURL) {
        setPreviewURL(prevPCImagePreviewURL => {
          if (prevPCImagePreviewURL) {
            window.URL.revokeObjectURL(prevPCImagePreviewURL)
          }
          return previewURL
        })
      }
    }
  }, [watchImage])

  const placeholder = () => {
    if (fieldName === 'pcImage') {
      return `PC 이미지 업로드 * (사이즈:${ImageSizeByType[adType].PC})`
    }

    return `모바일 이미지 업로드 * (사이즈:${ImageSizeByType[adType].MO})`
  }

  return (
    <FileInput
      id={`advertisement-edit-${fieldName}`}
      name={fieldName}
      register={register}
      className={className}
      placeholder={placeholder()}
      inputProps={{ accept: 'image/*' }}
      imgProps={{
        src: previewURL || imageURL || undefined,
      }}
      error={!previewURL && !imageURL}
      errorText="이미지를 업로드해주세요"
      handleDelete={handleDelete}
    />
  )
}

export default forwardRef(ImageField)
