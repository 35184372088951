import { useLoginByAdminMutation } from 'generated/graphql'
import { LINKAREER_NEXT_CLIENT_BASE_URL } from 'config'
import { getErrorCode } from 'utils/errorParser'

function useUserLogin(userId?: string) {
  const [loginByAdmin] = useLoginByAdminMutation()

  const userLogin = async (userIdArgs?: string) => {
    try {
      await loginByAdmin({
        variables: {
          id: (userId || userIdArgs) as string,
        },
      })
      window.location.href = LINKAREER_NEXT_CLIENT_BASE_URL
    } catch (error) {
      // TODO: use snackbar
      alert(`로그인 할 수 없습니다 errorCode:${getErrorCode(error)}`)
      return false
    }
  }

  return userLogin
}

export default useUserLogin
