import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { CoverLetter } from 'generated/graphql'

const useStyles = makeStyles({
  main: {
    minWidth: 50,
  },
})

interface FieldProps<T> {
  record?: T
  source?: string
}

const CoverLetterStatusField: FC<FieldProps<CoverLetter>> = ({ record }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return record ? (
    <span className={classes.main}>
      {record?.status && (
        <div>{translate(`enum.CoverLetterStatus.${record.status}`)}</div>
      )}
    </span>
  ) : null
}

CoverLetterStatusField.defaultProps = {
  source: 'status',
}

export default CoverLetterStatusField
