import React, { FunctionComponent } from 'react'
import {
  List,
  Filter,
  TextInput,
  DateField,
  Datagrid,
  ReferenceField,
  BooleanField,
  TextField,
} from 'react-admin'
import { UserType } from 'generated/graphql'

export const userTypeChoices = Object.keys(UserType).map(type => ({
  id: type,
  name: `enum.UserType.${type}`,
}))

const SignupSessionFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="msg.search" source="q" alwaysOn />
    </Filter>
  )
}

const SignupSessionList: FunctionComponent = props => {
  return (
    <List
      {...props}
      filters={<SignupSessionFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="snsID" sortable={false} />
        <TextField source="snsType" sortable={false} />
        <TextField source="displayName" sortable={false} />
        <TextField source="email" sortable={false} label="msg.email" />
        <ReferenceField
          source="user.id"
          reference="users"
          linkType="show"
          sortable={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <TextField source="timespread.id" sortable={false} />
        <BooleanField source="signup" sortable={false} />
        <ReferenceField
          source="signupUser.id"
          reference="users"
          linkType="show"
          sortable={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <DateField source="signupAt" sortable={false} showTime />
        <DateField source="createdAt" sortable={false} showTime />
        <TextField source="signupElapsedTimeInSeconds" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default SignupSessionList
