import React, { FC } from 'react'
import { CampaignStatisticsByDate, CampaignStatistic } from '../CampaignReport'

interface PlacementByImpressionAndClick {
  date: Date
  adPlacementName: string
  count: number
}

interface ImpressionAndClickData {
  impression: PlacementByImpressionAndClick[]
  click: PlacementByImpressionAndClick[]
}

interface PlacementByImpressionAndClickChartProps {
  data: CampaignStatisticsByDate[]
}

const PlacementByImpressionAndClickChart: FC<PlacementByImpressionAndClickChartProps> = ({
  data,
}) => {
  const campaignStatistics = data.reduce<CampaignStatistic[]>(
    (campaignStatistics, item) => {
      campaignStatistics.push(...item.campaignStatistics)
      return campaignStatistics
    },
    [],
  )

  // const orderedPlacementsByDate = orderBy(campaignStatistics, 'date', 'asc')

  const impressionAndClickData = campaignStatistics.reduce<
    ImpressionAndClickData
  >(
    (impressionAndClickData, campaignStatistic) => {
      const {
        date,
        adPlacementName,
        impressionCount,
        clickCount,
      } = campaignStatistic
      impressionAndClickData.impression?.push({
        date,
        adPlacementName,
        count: impressionCount,
      })
      impressionAndClickData.click?.push({
        date,
        adPlacementName,
        count: clickCount,
      })
      return impressionAndClickData
    },
    { impression: [], click: [] } as ImpressionAndClickData,
  )

  console.log(impressionAndClickData)

  return <p>hoho</p>
}

export default PlacementByImpressionAndClickChart
