import React, { FC, useState, useCallback, ChangeEvent, useEffect } from 'react'
import { Typography, TableContainer, Paper, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import padStart from 'lodash/padStart'
import differenceBy from 'lodash/differenceBy'
import flattenDeep from 'lodash/flattenDeep'
import range from 'lodash/range'

export interface TimetableFormData {
  dayOfWeek: string
  hour: string
}

interface TimetableRow {
  key: string
  value: string
}

const DAY_OF_WEEK = ['일', '월', '화', '수', '목', '금', '토']

const DAY_OF_WEEK_COUNT = 7
const MAX_HOUR = 24

const UNCHECKED_COLOUR = '#eaeaea'
const CHECKED_COLOUR = '#f50057'

function paddedHourFormat(hour: number | string): string {
  return padStart(hour.toString(), 2, '0')
}

function paddedHoursMap<T>(callbackfn: (paddedHour: string) => T) {
  return range(0, MAX_HOUR).map(hour => {
    const paddedHour = paddedHourFormat(hour)
    return callbackfn(paddedHour)
  })
}

function dayOfWeekMap<T>(callbackfn: (dayOfWeek: string) => T) {
  return range(0, DAY_OF_WEEK_COUNT).map(dayOfWeek => {
    return callbackfn(dayOfWeek.toString())
  })
}

function buildTimetableColumns() {
  const blankColumn = ''
  const hourColumns = paddedHoursMap(paddedHour => paddedHour)
  const checkAllColumn = '전체'

  return [blankColumn, ...hourColumns, checkAllColumn]
}

function buildTimetableRows(): TimetableRow[][] {
  const data = dayOfWeekMap(dayOfWeek => {
    const dayOfWeekCell = {
      key: 'dayOfWeek',
      value: dayOfWeek.toString(),
    }
    const hours = paddedHoursMap(paddedHour => ({
      key: `${dayOfWeek}-${paddedHour}`,
      value: `${dayOfWeek}-${paddedHour}`,
    }))
    const checkAllCell = {
      key: 'checkAll',
      value: 'checkAll',
    }
    return [dayOfWeekCell, ...hours, checkAllCell]
  })
  return data
}

const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.8rem',
  },
  tableContainer: {},
  table: {
    width: '100%',
    textAlign: 'center',

    '& th, td': {
      minWidth: 45,
      height: 40,
    },
  },
  cell: {
    display: 'block',
    height: 45,
    backgroundColor: UNCHECKED_COLOUR,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#CC0047',
    },
  },

  isChecked: {
    backgroundColor: CHECKED_COLOUR,
  },

  emphasizeText: {
    color: CHECKED_COLOUR,
    fontSize: 20,
  },
})

interface TimetableInputProps {
  label?: string
  defaultValue?: TimetableFormData[]
  fullChecked?: boolean
  onChange?(schedules: TimetableFormData[]): void
}

const TimetableInput: FC<TimetableInputProps> = ({
  label,
  defaultValue: defaultSchedules,
  fullChecked = false,
  onChange,
}) => {
  const classes = useStyles()
  const [checkedCells, setCheckedCells] = useState<string[]>(() => {
    if (defaultSchedules) {
      return defaultSchedules?.map(
        ({ dayOfWeek, hour }) => `${dayOfWeek}-${paddedHourFormat(hour)}`,
      )
    }

    if (fullChecked) {
      const selectedCells = flattenDeep(
        dayOfWeekMap(dayOfWeek => {
          return paddedHoursMap(paddedHour => `${dayOfWeek}-${paddedHour}`)
        }),
      )
      return selectedCells
    }

    return []
  })

  // TODO: 나중에 CSS 로 변경
  const [hoveredCell, setHoverdCell] = useState<TimetableFormData>()
  const columns = buildTimetableColumns()
  const rows = buildTimetableRows()

  const handleMouseOverCell = useCallback((hoveredCell: string) => {
    return () => {
      const dayOfWeekAndHour = hoveredCell.split('-')
      setHoverdCell({
        dayOfWeek: dayOfWeekAndHour[0],
        hour: dayOfWeekAndHour[1],
      })
    }
  }, [])

  const handleMouseLeaveCell = () => {
    setHoverdCell(undefined)
  }

  const handleCellClick = useCallback(newCheckedCell => {
    return () => {
      setCheckedCells(prevCheckedCells => {
        if (prevCheckedCells.includes(newCheckedCell)) {
          return prevCheckedCells.filter(
            prevSelectedCell => prevSelectedCell !== newCheckedCell,
          )
        }

        return [...prevCheckedCells, newCheckedCell]
      })
    }
  }, [])

  const handleCheckAllChange = useCallback((dayOfWeek: string) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked

      setCheckedCells(prevCheckedCells => {
        if (!isChecked) {
          return [
            ...prevCheckedCells.filter(prevCheckedCell => {
              const prevDayOfWeek = prevCheckedCell.split('-')[0]
              return prevDayOfWeek !== dayOfWeek
            }),
          ]
        }

        const newSelectedCells = paddedHoursMap(
          paddedHour => `${dayOfWeek}-${paddedHour}`,
        )

        return [
          ...prevCheckedCells,
          ...differenceBy(newSelectedCells, prevCheckedCells),
        ]
      })
    }
  }, [])

  useEffect(() => {
    onChange?.(
      checkedCells.map(checkedCell => {
        const dayOfWeekAndHour = checkedCell.split('-')
        return {
          dayOfWeek: dayOfWeekAndHour[0],
          hour: dayOfWeekAndHour[1],
        }
      }),
    )
  }, [checkedCells])

  return (
    <>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <TableContainer component={Paper}>
        <table className={classes.table}>
          <thead>
            <tr>
              {columns.map(column => {
                let isEmphasizeText = false
                if (hoveredCell) {
                  isEmphasizeText = Number(column) === Number(hoveredCell.hour)
                }
                return (
                  <th key={column}>
                    <span
                      className={clsx({
                        [classes.emphasizeText]: isEmphasizeText,
                      })}
                    >
                      {column}
                    </span>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => {
              let isCheckedAll = true
              return (
                <tr key={rowIndex}>
                  {row.map(cell => {
                    if (cell.key === 'dayOfWeek') {
                      let isEmphasizeText = false
                      if (hoveredCell) {
                        isEmphasizeText =
                          Number(cell.value) === Number(hoveredCell.dayOfWeek)
                      }
                      return (
                        <th key={cell.value}>
                          <span
                            className={clsx({
                              [classes.emphasizeText]: isEmphasizeText,
                            })}
                          >
                            {DAY_OF_WEEK[cell.value]}
                          </span>
                        </th>
                      )
                    }

                    if (cell.key === 'checkAll') {
                      const dayOfWeek = row[0].value
                      return (
                        <td key={cell.value}>
                          <Checkbox
                            checked={isCheckedAll}
                            onChange={handleCheckAllChange(dayOfWeek)}
                          />
                        </td>
                      )
                    }

                    const isCellChecked = checkedCells.includes(cell.value)

                    if (!isCellChecked) {
                      isCheckedAll = false
                    }

                    return (
                      <td key={cell.key}>
                        <div
                          id={`${cell.value}`}
                          className={clsx(classes.cell, {
                            [classes.isChecked]: isCellChecked,
                          })}
                          onClick={handleCellClick(cell.value)}
                          onMouseEnter={handleMouseOverCell(cell.value)}
                          onMouseLeave={handleMouseLeaveCell}
                        />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </TableContainer>
    </>
  )
}

export default TimetableInput
