import { useSnackbar } from 'notistack'
import { ApolloError } from '@apollo/client'
import { getErrorMessage, getErrorCode } from '../utils/errorParser'

interface UseErrorSnakbarReturn {
  enqueueApolloError: (error?: ApolloError) => void
  enqueueErrorMessage: (message: string) => void
}

function useErrorSnakbar(): UseErrorSnakbarReturn {
  const { enqueueSnackbar } = useSnackbar()

  function enqueueApolloError(error?: ApolloError) {
    enqueueSnackbar(getErrorMessage(error), {
      // @ts-ignore
      'data-testid': `snackbar-apollo-error`,
      // @ts-ignore
      'data-error-code': getErrorCode(error),
    })
  }
  function enqueueErrorMessage(message: string) {
    enqueueSnackbar(message, {
      // @ts-ignore
      'data-testid': 'snackbar-message-error',
    })
  }

  return {
    enqueueApolloError,
    enqueueErrorMessage,
  }
}

export default useErrorSnakbar
