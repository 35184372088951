import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'

import {
  List,
  Filter,
  TextInput,
  TextField,
  SelectField,
  BooleanField,
  DateField,
  Datagrid,
  EditButton,
  BulkDeleteButton,
} from 'react-admin'

import { FormattedDate } from 'react-intl'
import get from 'lodash/get'
import { UserType, useLoginByAdminMutation } from 'generated/graphql'
import { makeStyles, Button } from '@material-ui/core'
import { LINKAREER_NEXT_CLIENT_BASE_URL } from 'config'

interface LoginByAdminProps {
  basePath?: string
  record?: any
  redirect?: any
}

export const userTypeChoices = Object.keys(UserType).map(type => ({
  id: type,
  name: `enum.UserType.${type}`,
}))

const UserFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="msg.search" source="q" alwaysOn />
    </Filter>
  )
}

const LoginByUser: FunctionComponent<LoginByAdminProps> = props => {
  const [loginByAdmin] = useLoginByAdminMutation({
    variables: {
      id: props.record.id,
    },
  })

  const handleOnClick = async () => {
    try {
      await loginByAdmin()
      window.location.href = LINKAREER_NEXT_CLIENT_BASE_URL
    } catch (error) {
      alert('로그인 할 수 없습니다')
      console.log(error)
      return false
    }
  }
  return <Button onClick={handleOnClick}>이 아이디로 로그인하기</Button>
}

const UserBulkActionButtons = props => (
  <BulkDeleteButton {...props} undoable={false} />
)

const useListStyles = makeStyles({
  main: {
    width: '100%',
  },
  content: {
    width: 'inherit',
  },
})

const UserList: FunctionComponent = props => {
  const classes = useListStyles()

  return (
    <List
      {...props}
      classes={classes}
      filters={<UserFilter />}
      bulkActionButtons={<UserBulkActionButtons />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" label="msg.id" />
        <SelectField source="type" sortable={false} choices={userTypeChoices} />
        <PairField
          source="username"
          timespreadSource="timespread.name"
          sortable={false}
        />
        <PairField
          source="phoneNumber"
          timespreadSource="timespread.phoneNumber"
          sortable={false}
        />
        <PairField
          source="email"
          timespreadSource="timespread.email"
          sortable={false}
        />
        <EditButton />
        <PairField
          source="birthday"
          timespreadSource="timespread.birthday"
          format="date"
          sortable={false}
        />
        <PairField
          source="facebook.id"
          timespreadSource="timespread.facebookID"
          sortable={false}
        />
        <PairField
          source="kakao.id"
          timespreadSource="timespread.kakaoID"
          sortable={false}
        />
        <PairField
          source="naver.id"
          timespreadSource="timespread.naverID"
          sortable={false}
        />
        <BooleanField source="specupJoined" />
        <TextField source="timespread.id" sortable={false} />
        <TextField source="timespread.cash" sortable={false} />
        <DateField source="createdAt" showTime />
        <DateField source="lastLoginAt" showTime />
        <LoginByUser />
      </Datagrid>
    </List>
  )
}

export default UserList

interface PairFieldProps {
  record?: any
  addLabel?: boolean
  sortable?: boolean
  source: string
  timespreadSource: string
  format?: 'text' | 'date'
}

function formatDate(value: any) {
  if (typeof value === 'number') {
    return <FormattedDate value={value} />
  }
  return value
}

const PairField: FunctionComponent<PairFieldProps> = ({
  record = {},
  source,
  timespreadSource,
  format = 'text',
}) => {
  let linkareerValue = get(record, source)
  let timespreadValue = get(record, timespreadSource)

  switch (format) {
    case 'date': {
      linkareerValue = formatDate(linkareerValue)
      timespreadValue = formatDate(timespreadValue)
      break
    }
  }

  return (
    <div>
      {linkareerValue && <div>LK: {linkareerValue}</div>}
      {timespreadValue && (
        <div style={{ color: '#888' }}>TS: {timespreadValue}</div>
      )}
    </div>
  )
}

PairField.defaultProps = {
  addLabel: true,
}

gql`
  mutation LoginByAdmin($id: ID!) {
    loginByAdmin(id: $id) {
      user {
        id
      }
    }
  }
`
