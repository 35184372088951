import React, { FC } from 'react'

const UserDropoutLog: FC = () => {
  return (
    <>
      <iframe
        src="https://redash.linkareer.com/public/dashboards/LuYYFHWxGWhVYk70VhD1Beuvad3b4BHjnNPdHgQX?org_slug=default"
        height="1000"
      ></iframe>
    </>
  )
}

export default UserDropoutLog
