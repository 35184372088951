import React, { FunctionComponent } from 'react'
import {
  TextField,
  BooleanField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
} from 'react-admin'
import KibanaField from 'components/fields/KibanaField'
import SessionField from 'components/fields/SessionField'

const SignupSessionShow: FunctionComponent = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="msg.summary">
          <TextField source="id" label="msg.token" />
          <TextField source="snsID" />
          <TextField source="snsType" />
          <TextField source="displayName" />
          <TextField source="email" label="msg.email" />
          <ReferenceField source="user.id" reference="users" linkType="show">
            <TextField source="username" />
          </ReferenceField>
          <TextField source="timespread.id" />
          <BooleanField source="signup" />
          <ReferenceField
            source="signupUser.id"
            reference="users"
            linkType="show"
          >
            <TextField source="username" />
          </ReferenceField>
          <KibanaField
            label="msg.userActionLogs"
            source="id"
            dateSource="createdAt"
            esField="signupToken"
            dateOffsetInMinutes={30} // 회원가입을 30분 이상 하지는 않을 것이라 가정한다
          >
            로그 뷰어 열기
          </KibanaField>
          <SessionField source="signupSession" dateSource="signupAt" />
          <DateField source="signupAt" showTime />
          <DateField source="createdAt" showTime />
          <TextField source="signupElapsedTimeInSeconds" />
        </Tab>
        <Tab label="msg.timespread">
          <TextField source="timespread.id" label="msg.id" />
          <TextField source="timespread.name" label="msg.name" />
          <TextField source="timespread.email" label="msg.email" />
          <DateField source="timespread.birthday" label="msg.birthday" />
          <TextField source="timespread.profileURL" label="msg.profileURL" />
          <TextField source="timespread.phoneNumber" label="msg.phoneNumber" />
          <TextField source="timespread.cash" label="msg.cash" />
          <TextField source="timespread.facebookID" label="msg.facebookID" />
          <TextField source="timespread.kakaoID" label="msg.kakaoID" />
          <TextField source="timespread.naverID" label="msg.naverID" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default SignupSessionShow
