import React from 'react'
import clsx from 'clsx'
import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
} from 'react-admin'

import { CoverLetterTypesInput } from '.'
import { coverLetterStatusChoices } from './CoverLetterEdit'
import { CoverLetterStatus } from 'generated/graphql'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1148,
  },
  title: {
    marginBottom: '1rem',
  },
  divider: {
    width: '100%',
    marginBottom: 20,
  },
  inline: {
    display: 'inline-flex',
    marginRight: '2rem',
  },
  cloneButton: {
    marginRight: '2rem',
    '& a': {
      width: 50,
    },
  },
  sourceKey: {
    width: '100%',
  },
  longInput: {
    maxWidth: 400,
    width: '100%',
  },
}))

const CoverLetterCreateToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="list" />
    </Toolbar>
  )
}

const LinkBreak = () => <Box width="100%" />

const CoverLetterCreate = props => {
  const classes = useStyles()

  return (
    <Create {...props} className={classes.root}>
      <SimpleForm toolbar={<CoverLetterCreateToolbar />}>
        <Typography variant="h5" className={classes.title}>
          자소서 상태
        </Typography>
        <SelectInput
          source="status"
          choices={coverLetterStatusChoices}
          defaultValue={CoverLetterStatus.PENDING}
          formClassName={classes.inline}
        />
        <CoverLetterTypesInput
          variant="standard"
          formClassName={classes.inline}
        />
        <LinkBreak />

        <TextInput
          source="sourceKey"
          formClassName={clsx(classes.inline, classes.sourceKey)}
          fullWidth
        />
        <Divider className={classes.divider} />

        <Typography variant="h5" className={classes.title}>
          자소서 정보
        </Typography>
        <TextInput source="organizationName" formClassName={classes.inline} />
        <TextInput source="role" formClassName={classes.inline} />
        <DateInput source="passedAt" formClassName={classes.inline} />
        <LinkBreak />

        <TextInput source="university" formClassName={classes.inline} />
        <TextInput source="major" formClassName={classes.inline} />
        <TextInput source="grades" formClassName={classes.inline} />
        <LinkBreak />

        <TextInput
          source="languageScore"
          formClassName={clsx(classes.inline, classes.longInput)}
          fullWidth
        />
        <TextInput
          source="career"
          formClassName={clsx(classes.inline, classes.longInput)}
          fullWidth
        />
        <LinkBreak />
        <TextInput
          source="activity"
          formClassName={clsx(classes.inline, classes.longInput)}
          fullWidth
        />
        <TextInput
          source="license"
          formClassName={clsx(classes.inline, classes.longInput)}
          fullWidth
        />
        <LinkBreak />

        <TextInput
          source="content"
          label="자소서 본문 (필수)"
          variant="standard"
          required
          multiline
          fullWidth
        />
        <LinkBreak />
      </SimpleForm>
    </Create>
  )
}

export default CoverLetterCreate
