import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { List, Datagrid, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'

const useListStyles = makeStyles({
  main: {
    width: '100%',
  },
  content: {
    width: 'inherit',
  },
})

const RecommendKeywordList: FunctionComponent = props => {
  const classes = useListStyles()
  return (
    <List classes={classes} {...props} hasCreate bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="seq" sortable={false} />
      </Datagrid>
    </List>
  )
}

export const mapStateToProps = () => ({
  basePath: '/search-page/recommend-keyword',
  resource: 'search-page.recommend-keyword',
})

export default connect(mapStateToProps)(RecommendKeywordList)
