import React, { FC } from 'react'
import gql from 'graphql-tag'
import groupBy from 'lodash/groupBy'
import { Divider, Paper, Tabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  Datagrid,
  EditButton,
  TextField,
  DateField,
  FunctionField,
} from 'react-admin'
import {
  AdType,
  AdStatus,
  AdvertisementOrderField,
  OrderDirection,
  useAdvertisementList_AdvertisementsQuery,
} from 'generated/graphql'
import { BulkDeleteButton } from 'components'
import { TitleByType } from './types'

const useStyles = makeStyles({
  root: {
    display: 'table',
    '& th, td': {
      textAlign: 'center',
    },
  },
  title: {
    flexGrow: 1,
  },
})

interface TabbedDataGridProps {
  filterValues?: any
  setFilters?: any
  adType?: AdType
}

const TabbedDataGrid: FC<TabbedDataGridProps> = props => {
  const classes = useStyles()
  const { filterValues, setFilters, adType } = props

  React.useEffect(() => {
    setFilters({ adType, status: AdStatus.OPEN })
  }, [adType])

  const { data, loading, error } = useAdvertisementList_AdvertisementsQuery({
    variables: { filterBy: { adType: adType || AdType.HOTLINE_BANNER } },
  })

  if (loading || error) return null

  const adsGroup = groupBy(
    data?.advertisementList?.advertisements,
    ad => ad?.status,
  )

  const handleTabChange = (_, value) => {
    setFilters({ adType, status: value })
  }

  const basePath = (adType || '').toLowerCase()

  const tabs = [
    {
      name: `진행중(${adsGroup[AdStatus.OPEN]?.length || 0})`,
      value: AdStatus.OPEN,
    },
    {
      name: `진행예정(${adsGroup[AdStatus.RESERVED]?.length || 0})`,
      value: AdStatus.RESERVED,
    },
    {
      name: `종료(${adsGroup[AdStatus.CLOSED]?.length || 0})`,
      value: AdStatus.CLOSED,
    },
  ]
  return (
    <Paper>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleTabChange}
      >
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.name} value={tab.value} />
        ))}
      </Tabs>

      <Divider />

      <>
        <Datagrid
          {...props}
          rowClick="edit"
          classes={{ table: classes.root }}
          basePath={basePath}
        >
          <TextField source="seq" />
          <TextField source="activityID" sortable={false} />
          <FunctionField
            label="제목"
            sortable={false}
            className={classes.title}
            render={record => `${record?.title || record.activity?.title}`}
          />
          <DateField source="startAt" sortable={false} showTime />
          <DateField source="endAt" sortable={false} showTime />
          <EditButton basePath={basePath} />
        </Datagrid>
      </>
    </Paper>
  )
}

interface AdvertisementListProps {
  match?: any
  location?: any
  basePath?: string
  resource?: string
}

const AdvertisementList: FC<AdvertisementListProps> = props => {
  const adTypeSlug = props.match.params.adType
  const adType = adTypeSlug.toUpperCase()

  return (
    <List
      basePath={adTypeSlug}
      resource="advertisement"
      title={`광고 관리 > ${TitleByType[adType]}`}
      hasCreate
      hasList
      hasEdit
      hasShow={false}
      bulkActionButtons={
        <BulkDeleteButton resource="advertisement" {...props} />
      }
      filter={{ adType }}
      filterDefaultValues={{
        adType: AdType.HOTLINE_BANNER,
        status: AdStatus.OPEN,
      }}
      sort={{ field: AdvertisementOrderField.SEQ, order: OrderDirection.ASC }}
      {...props}
    >
      <TabbedDataGrid adType={adType} {...props} />
    </List>
  )
}

export default AdvertisementList

gql`
  query AdvertisementList_advertisements($filterBy: AdvertisementFilter!) {
    advertisementList(filterBy: $filterBy) {
      advertisements {
        status
      }
    }
  }
`
