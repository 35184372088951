import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  DateInput,
  TopToolbar,
  ShowButton,
  useRefresh,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { Button, makeStyles } from '@material-ui/core'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import {
  useUserUnlinkTimespreadMutation,
  useUserDeactivateMutation,
} from 'generated/graphql'
import { userTypeChoices } from './UserList'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'

interface UserEditActionsProps {
  basePath?: string
  data?: any
}

const UserEditActions: FunctionComponent<UserEditActionsProps> = ({
  basePath,
  data,
}) => {
  const refresh = useRefresh()
  const [userUnlinkTimespread] = useUserUnlinkTimespreadMutation()

  const handleClick = async () => {
    await userUnlinkTimespread({ variables: { userID: data.id } })
    refresh()
  }

  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
      <Button color="primary" onClick={handleClick} startIcon={<LinkOffIcon />}>
        타임스프레드 계정 연동 초기화
      </Button>
    </TopToolbar>
  )
}

const useStyles = makeStyles({
  toolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
})

interface UserEditToolbarProps {
  basePath?: string
  record?: any
  redirect?: any
}

const UserEditToolbar: FunctionComponent<UserEditToolbarProps> = props => {
  const { record } = props
  const history = useHistory()
  const refresh = useRefresh()
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()
  const [userDeactivate] = useUserDeactivateMutation({
    onCompleted: () => {
      history.push('/users2/list')
      refresh()
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
  })

  const handleClick = async () => {
    await userDeactivate({ variables: { userID: record.id } })
  }
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <Button
        color="secondary"
        onClick={handleClick}
        disabled={!!props?.record?.deletedAt}
        startIcon={<LinkOffIcon />}
      >
        유저 탈퇴
      </Button>
    </Toolbar>
  )
}

const UserEdit: FunctionComponent = props => {
  return (
    <Edit actions={<UserEditActions />} {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="id" label="msg.id" disabled />
        <SelectInput source="type" choices={userTypeChoices} />
        <TextInput source="username" />
        <BooleanInput source="specupJoined" />
        <TextInput source="timespread.id" disabled />
        <TextInput source="timespread.cash" disabled />
        <DateInput source="birthday" disabled />
        <TextInput source="phoneNumber" disabled />
        <DateInput source="createdAt" disabled />
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit

gql`
  mutation UserUnlinkTimespread($userID: ID!) {
    userUnlinkTimespread(userID: $userID) {
      user {
        id
      }
    }
  }
`

gql`
  mutation UserDeactivate($userID: ID!) {
    deleteUserAccount(id: $userID) {
      user {
        id
      }
    }
  }
`
