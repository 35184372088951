import React from 'react'
import { FormattedRelativeTime } from 'react-intl'
import { selectUnit } from '@formatjs/intl-utils'

interface FormattedDateTimeCell {
  value?: any
}
type FormattedRelativeTimeProps = typeof FormattedRelativeTime.defaultProps

const FormattedDateTimeCell: React.FC<FormattedDateTimeCell &
  FormattedRelativeTimeProps> = ({ value, ...props }) => {
  // https://github.com/formatjs/formatjs/pull/1374
  const selectedUnit = selectUnit(new Date(value) || Date.now())
  return (
    // @ts-ignore
    <FormattedRelativeTime
      {...props}
      value={selectedUnit.value}
      unit={selectedUnit.unit}
    />
  )
}

export default FormattedDateTimeCell
