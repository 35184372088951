import 'date-fns'
import React, {
  RefForwardingComponent,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useTranslate, useInput } from 'react-admin'
import DateFnsUtils from '@date-io/date-fns' // https://github.com/mui-org/material-ui-pickers/issues/1440
import koLocale from 'date-fns/locale/ko'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

interface DateInputProps {
  source: string
  alwaysOn: boolean
  label?: string | null
}

export interface DateInputAPI {
  init: () => void
}

const DateInput: RefForwardingComponent<DateInputAPI, DateInputProps> = (
  props,
  ref,
) => {
  const translate = useTranslate()

  const handleDateChange = (date: Date | null) => {
    input.onChange(date)
    input.onBlur()
  }

  const {
    input,
    meta: { touched, error },
  } = useInput(props)

  useImperativeHandle(ref, () => ({
    init: () => {
      input.onChange(null)
    },
  }))

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
      <KeyboardDatePicker
        autoOk
        error={!!(touched && error)}
        helperText={touched && error}
        disableToolbar
        variant="inline"
        format="yyyy-MM-dd"
        label={translate(props.label)}
        value={input.value || new Date()}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'changeDate',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default forwardRef(DateInput)
