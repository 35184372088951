import React, { FC } from 'react'
import { Paper, Box, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {},
  toolbar: {
    padding: '0 16px',
  },
  title: {},
  content: {
    padding: '0 16px 20px',
  },
})

interface BaseLayoutProps {
  title: string
}

const BaseLayout: FC<BaseLayoutProps> = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6" component="div">
          {title}
        </Typography>
      </Toolbar>
      <Box className={classes.content}>{children}</Box>
    </Paper>
  )
}

export default BaseLayout
