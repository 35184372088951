import gql from 'graphql-tag'
import {
  SignupSessionGetDocument,
  SignupSessionGetListDocument,
  SignupSessionGetManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
  getList: {
    query: SignupSessionGetListDocument,
  },
  getOne: {
    query: SignupSessionGetDocument,
  },
  getMany: {
    query: SignupSessionGetManyDocument,
  },
}

gql`
  fragment SignupSessionFragment on SignupSession {
    id: token
    snsID
    snsType
    displayName
    email
    ipAddress
    user {
      id
    }
    timespread {
      id
      name
      email
      birthday
      profileURL
      phoneNumber
      cash
      facebookID
      kakaoID
      naverID
    }
    signup
    signupUser {
      id
    }
    signupSession
    signupAt
    signupElapsedTimeInSeconds
    createdAt
  }
`

gql`
  fragment SignupSessionDetailFragment on SignupSession {
    ...SignupSessionFragment
  }
`

gql`
  query SignupSessionGetList(
    $filterBy: SignupSessionFilters
    $orderBy: SignupSessionOrder
    $pagination: Pagination
  ) {
    data: signupSessions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...SignupSessionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query SignupSessionGet($id: ID!) {
    data: signupSession(token: $id) {
      ...SignupSessionDetailFragment
    }
  }
`

gql`
  query SignupSessionGetMany($ids: [ID!]!) {
    data: signupSessionsByToken(tokens: $ids) {
      ...SignupSessionFragment
    }
  }
`
