import React from 'react'
import { SimpleImg, Props } from 'react-simple-img'

interface ImageCellProps {
  value?: string
}

const ImageCell: React.FC<ImageCellProps & Partial<Props>> = ({ value }) => {
  return (
    <SimpleImg
      key={value}
      src={value || ''}
      height={75}
      width="auto"
      importance="high"
    />
  )
}

export default ImageCell
