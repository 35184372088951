import React from 'react'
import { Switch, Route } from 'react-router-dom'
import OrganizationsListPage from './list'
import OrganizationListDetailPage from './list/Detail'

const OrganizationsRouter = () => {
  return (
    <>
      <Switch>
        <Route path="/organizations/list/:id">
          <OrganizationListDetailPage />
        </Route>
        <Route path="/organizations/list">
          <OrganizationsListPage />
        </Route>
      </Switch>
    </>
  )
}

export default OrganizationsRouter
