import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { getFilterForm } from './utils'
import { FilterField } from './fieldType'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 200,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
    space: {
      flexGrow: 1,
    },
    fieldset: {
      padding: theme.spacing(2),
    },
  }),
)

export interface BaseFormProps {
  onSubmit: (value: any) => void
  onClickClose?: () => void
}

const BaseForm: React.FC<BaseFormProps & FilterField> = props => {
  const { label, onSubmit, filterType, value, onClickClose } = props
  const classes = useStyles()
  const methods = useForm({
    defaultValues: {
      value: '',
    },
  })

  const fieldComponent = getFilterForm(filterType)

  return (
    <FormProvider {...methods}>
      <form className={classes.root} onSubmit={methods.handleSubmit(onSubmit)}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar variant="dense" disableGutters>
            <Typography variant="h6" className={classes.title}>
              {label}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClickClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.fieldset}>
          {fieldComponent && React.cloneElement(fieldComponent, { value })}
        </div>

        <CardActions>
          <div className={classes.space} />
          <Button color="secondary" type="submit">
            적용
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  )
}

export default BaseForm
