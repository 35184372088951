import {
  RecommendKeywordGetDocument,
  RecommendKeywordGetListDocument,
  RecommendKeywordCreateDocument,
  RecommendKeywordUpdateDocument,
  RecommendKeywordGetListQueryVariables,
  RecommendKeywordCreateMutationVariables,
  RecommendKeywordUpdateMutationVariables,
  RecommendSearchKeywordType,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'
import { prepareInput } from './recommend-keyword.query'

export const queryBuilder: QueryBuilder = {
  getList: {
    query: RecommendKeywordGetListDocument,
    variables(): RecommendKeywordGetListQueryVariables {
      return {
        type: RecommendSearchKeywordType.COVER_LETTER,
      }
    },
  },
  getOne: {
    query: RecommendKeywordGetDocument,
  },
  create: {
    query: RecommendKeywordCreateDocument,
    variables(args): RecommendKeywordCreateMutationVariables {
      return {
        input: {
          type: RecommendSearchKeywordType.COVER_LETTER,
          ...args,
        },
      }
    },
  },
  update: {
    query: RecommendKeywordUpdateDocument,
    variables(id, args): RecommendKeywordUpdateMutationVariables {
      return {
        id,
        input: {
          type: RecommendSearchKeywordType.COVER_LETTER,
          ...args,
        },
      }
    },
  },

  prepareInput,
}
