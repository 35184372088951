import React, {
  FC,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Route, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Tabs,
  Tab,
  Divider,
  Drawer,
  useTheme,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
// import DeleteIcon from '@material-ui/icons/Delete'
import {
  CloneButton,
  List,
  DateField,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  SelectInput,
  // useDeleteMany,
  // useRefresh,
  // useNotify,
  // useUnselectAll,
} from 'react-admin'
import {
  CoverLetterType,
  useCoverLetterSyncWithElasticMutation,
} from 'generated/graphql'
import {
  CoverLetterTypesField,
  CoverLetterEdit,
  BulkCoverLetterTypeAddButtons,
  CoverLetterStatusField,
} from '.'
import rowStyle from './rowStyle'
import BulkDeleteButton from 'components/BulkDeleteButton'
import { ResourceType } from 'resources'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import CoverLetterPlagiarismCheck from './CoverLetterPlagiarismCheck'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    width: '100%',
  },
  drawerPaper: {
    zIndex: 100,
  },
  leftDrawerRoot: {
    marginLeft: 240,
  },
  listWrapper: {
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#3af2f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const useListStyles = makeStyles(() => ({
  main: {
    width: '100%',
  },
  content: {
    width: 'inherit',
  },
}))

const CoverLetterTypeFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <SelectInput
        source="status"
        label="resources.cover-letters.fields.status"
        choices={[
          { id: '', name: 'enum.CoverLetterStatus.ALL' },
          { id: 'PUBLISHED', name: 'enum.CoverLetterStatus.PUBLISHED' },
          { id: 'PENDING', name: 'enum.CoverLetterStatus.PENDING' },
          { id: 'REJECTED', name: 'enum.CoverLetterStatus.REJECTED' },
        ]}
        alwaysOn
      />
      <TextInput
        label="enum.SearchKeywordFieldType.OrganizationName"
        source="organizationName"
        alwaysOn
        resettable
      />
      <TextInput
        label="enum.SearchKeywordFieldType.Role"
        source="role"
        alwaysOn
        resettable
      />
      <TextInput
        label="enum.SearchKeywordFieldType.Keyword"
        source="keyword"
        alwaysOn
        resettable
      />
    </Filter>
  )
}

interface TabbedDataGridProps {
  filterValues?: any
  setFilters?: any
  ids?: string[]
  selectedRow?: any
}

const TabbedDataGrid: FunctionComponent<TabbedDataGridProps> = props => {
  const { filterValues, setFilters, ids, selectedRow } = props

  const tabs = [
    { id: 'all', name: '전체', value: CoverLetterType.ALL },
    {
      id: 'majorCompany',
      name: '대기업',
      value: CoverLetterType.MAJOR_COMPANY,
    },
    {
      id: 'mediumCompany',
      name: '중견기업',
      value: CoverLetterType.MEDIUM_COMPANY,
    },
    {
      id: 'smallCompany',
      name: '중소기업/스타트업',
      value: CoverLetterType.SMALL_COMPANY,
    },
    {
      id: 'publicInstitutions',
      name: '공기업',
      value: CoverLetterType.PUBLIC_INSTITUTIONS,
    },
    { id: 'banks', name: '은행권', value: CoverLetterType.BANKS },
    {
      id: 'etc',
      name: '기타',
      value: CoverLetterType.ETC,
    },
    { id: 'intern', name: '인턴', value: CoverLetterType.INTERN },
    { id: 'newcomer', name: '신입', value: CoverLetterType.NEWCOMER },
  ]

  const theme = useTheme()
  const [coverLetterIDs, setCoverLetterIDs] = useState(ids)

  useEffect(() => {
    setCoverLetterIDs(ids)
  }, [ids])

  const handleTabChange = (event, value) => {
    setFilters({ ...filterValues, types: value })
  }

  return (
    <Paper>
      <Tabs
        value={filterValues.types}
        indicatorColor="primary"
        onChange={handleTabChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs.map(type => (
          <Tab key={type.id} label={type.name} value={type.value} />
        ))}
      </Tabs>

      <Divider />

      <Datagrid
        {...props}
        ids={coverLetterIDs}
        rowClick="edit"
        rowStyle={rowStyle(selectedRow, theme)}
      >
        <TextField source="id" />
        <CloneButton />
        <TextField source="score" />
        <DateField source="passedAt" />
        <CoverLetterTypesField />
        <CoverLetterStatusField />
        <TextField source="organizationName" />
        <TextField source="role" />
        <TextField source="university" />
        <TextField source="major" />
        <TextField source="grades" />
        <TextField source="languageScore" />
        <TextField source="career" />
        <TextField source="activity" />
        <TextField source="license" />
        <TextField source="scrapCount" sortable={false} />
      </Datagrid>
    </Paper>
  )
}

const CoverLetterBulkActionButtons = props => {
  const { selectedIds } = props

  return (
    <Box alignItems="center">
      <BulkCoverLetterTypeAddButtons />
      <BulkDeleteButton
        selectedIds={selectedIds}
        resource={ResourceType.CoverLetters}
      />
    </Box>
  )
}
export interface CoverLetterListProps {
  basePath: string
  resource: string
  location: any
  options?: any
  match?: any
  record?: any
}
interface TerminalProps {
  logList: string[]
}

const CoverLetterList: FC<CoverLetterListProps> = props => {
  const classes = useStyles()
  const listClasses = useListStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [
    coverLetterSyncWithElasticMutation,
    { loading },
  ] = useCoverLetterSyncWithElasticMutation({
    onCompleted: data => {
      enqueueSnackbar(
        `동기화 완료: ${data.syncCoverLetterWithElastic}개 업데이트`,
      )
      setOpen(false)
    },
  })

  const handleOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleSync = async () => {
    await coverLetterSyncWithElasticMutation()
  }

  const handleClose = useCallback(() => {
    history.push('/cover-letters')
  }, [history])

  return (
    <div className={classes.root}>
      <Route path="/cover-letters/:id">
        {({ match, location }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          )
          const routerQuery = queryString.parse(location.search)
          const isPlagiarismCheck = routerQuery?.plagiarism === 'true'

          const handleClosePlagiarism = () => {
            if (isMatch) {
              history.push(`/cover-letters/${match?.params?.id}`)
            }
          }

          return (
            <>
              <div className={classes.listWrapper}>
                <List
                  {...props}
                  classes={listClasses}
                  className={classes.list}
                  filters={<CoverLetterTypeFilter />}
                  filterDefaultValues={{ types: CoverLetterType.ALL }}
                  bulkActionButtons={
                    <CoverLetterBulkActionButtons {...props} />
                  }
                >
                  <TabbedDataGrid {...props} />
                </List>
                <Button
                  key="sync-button"
                  color="primary"
                  onClick={handleOpen}
                  disabled={loading}
                >
                  동기화
                </Button>
              </div>

              <Drawer
                variant="persistent"
                open={isPlagiarismCheck}
                anchor="left"
                onClose={handleClosePlagiarism}
                classes={{
                  paper: classNames(
                    classes.drawerPaper,
                    classes.leftDrawerRoot,
                  ),
                }}
                SlideProps={{
                  direction: 'left',
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch && isPlagiarismCheck ? (
                  <CoverLetterPlagiarismCheck
                    coverLetterID={match && match.params.id}
                    onCancel={handleClosePlagiarism}
                    {...props}
                  />
                ) : null}
              </Drawer>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <CoverLetterEdit
                    id={match && match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography>
            동기화시 10분 이상의 시간이 소요될 수 있으며, 접속자가 많은 시간
            대엔 서버에 부하가 갈 수 있습니다. 진행하시겠습니까?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose()}>취소</Button>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              onClick={() => handleSync()}
              title="모든 데이터를 엘라스틱 서치에 업로드 합니다."
            >
              동기화
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CoverLetterList
