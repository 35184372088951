import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import {
  useCampaignGetOneQuery,
  useCampaignEdit_CampaignUpdateMutation,
  useCampaignEdit_CampaignDeleteMutation,
  CampaignUpdateInput,
  AdUpsertInput,
} from 'generated/graphql'
import useAttachmentFileUpload, {
  ADVERTISEMENT,
} from 'hooks/useAttachmentFileUpload'
import useAttachmentFileDelete from 'hooks/useAttachmentFileDelete'
import { useSnackbar } from 'notistack'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import { NetworkStatus } from '@apollo/client'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { BaseLayout } from 'components/layout/content'
import CampaignForm, {
  CampaignFormData,
  convertTimetableDataToCampaignSchedule,
} from './CampaignForm'

interface CampaignEditProps {
  id: string
}

const CampaignEdit: FC<CampaignEditProps> = ({ id }) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueApolloError } = useErrorSnackbar()
  const { data, error, networkStatus } = useCampaignGetOneQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  const { fileUpload } = useAttachmentFileUpload({
    path: ADVERTISEMENT,
  })
  const { fileDelete } = useAttachmentFileDelete()

  const [updateCampaign] = useCampaignEdit_CampaignUpdateMutation({
    onCompleted: () => {
      enqueueSnackbar('캠페인이 수정되었습니다.', { variant: 'success' })
      history.push('/advertisement/campaigns/list')
    },
    onError: enqueueApolloError,
  })

  const [deleteCampaign] = useCampaignEdit_CampaignDeleteMutation({
    onCompleted: () => {
      enqueueSnackbar('캠페인이 삭제되었습니다.', { variant: 'success' })
      history.push('/advertisement/campaigns/list')
    },
    onError: enqueueApolloError,
  })

  const campaign = data?.campaign

  if (!campaign) {
    return null
  }
  const handleSubmit = async ({
    ads,
    schedules: timetableData,
    startAt,
    endAt,
    ...other
  }: CampaignFormData) => {
    const deleteAdIDs = ads.deleteAds.reduce<string[]>((deleteAdIDs, ad) => {
      if (!ad.id) {
        return deleteAdIDs
      }
      deleteAdIDs.push(ad.id)
      return deleteAdIDs
    }, [])

    const upsertAds: AdUpsertInput[] = []
    for (const upsertAd of ads.upsertAds) {
      const prevImageID = upsertAd.imageID
      const newImageFile = upsertAd.imageFiles?.[0]

      if (!prevImageID && !newImageFile) {
        continue
      }

      if (!newImageFile) {
        continue
      }

      const uploadedNewImage = await fileUpload(newImageFile)

      if (prevImageID) {
        await fileDelete(prevImageID)
      }

      const imageID = uploadedNewImage ? uploadedNewImage.id : prevImageID

      if (!imageID) {
        continue
      }

      upsertAds.push({
        ...(upsertAd.id && { id: upsertAd.id }),
        campaignID: campaign.id,
        adPlacementID: upsertAd.adPlacementID,
        imageID,
      })
    }

    const schedules = convertTimetableDataToCampaignSchedule(timetableData)

    const campaignInput: CampaignUpdateInput = {
      ...other,
      upsertAds,
      deleteAdIDs,
      schedules: schedules.map(schedule => ({
        ...schedule,
        campaignID: campaign.id,
      })),
      startAt: new Date(startAt),
      endAt: new Date(endAt),
    }

    await updateCampaign({
      variables: {
        id,
        input: campaignInput,
      },
    })
  }

  const handleDelete = async () => {
    await deleteCampaign({
      variables: {
        id,
      },
    })
  }

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <BaseLayout title="캠페인 수정">
      <CampaignForm
        defaultValues={campaign}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </BaseLayout>
  )
}

export default CampaignEdit

gql`
  mutation CampaignEdit_campaignUpdate($id: ID!, $input: CampaignUpdateInput!) {
    campaignUpdate(id: $id, input: $input) {
      campaign {
        id
      }
    }
  }
  mutation CampaignEdit_campaignDelete($id: ID!) {
    campaignDelete(id: $id) {
      campaign {
        id
      }
    }
  }
`
