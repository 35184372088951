import { API_BASE_URL } from './config'
import { GraphQLClient } from './data-provider'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, ApolloLink, concat, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const uploadLink = (createUploadLink({
  uri: `${API_BASE_URL}/graphql`,
  credentials: 'include',
}) as unknown) as ApolloLink

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: concat(errorLink, uploadLink),
  cache: new InMemoryCache().restore({}),
})

export const graphqlClient: GraphQLClient = {
  query: async (query: any, variables: any) => {
    const result = await apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables,
    })

    if (result.errors && result.errors.length > 0) {
      throw result.errors
    }

    return result
  },
  mutate: async (mutation: any, variables: any) => {
    const result = await apolloClient.mutate({
      mutation,
      variables,
    })

    // TODO: 기존 에러 핸들링 영향 파악
    // docs상에선 위의 onError로 충분..? https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
    // if (result?.errors && result.errors.length > 0) {
    //   throw result.errors
    // }

    return result
  },
}
