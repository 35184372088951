import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        overflowX: 'auto',
        display: 'block',
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
})
