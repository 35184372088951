export { default as CampaignList } from './CampaignList'
export { default as CampaignCreate } from './CampaignCreate'
export { default as CampaignEdit } from './CampaignEdit'

import gql from 'graphql-tag'

gql`
  fragment Campaign_campaign on Campaign {
    id
    name
    content
    user {
      id
      username
    }
    isActive
    landingURL
    startAt
    endAt
    status
    ads {
      id
      adPlacement {
        id
        name
      }
      image {
        id
      }
    }
    schedules {
      dayOfWeek
      startTime
      endTime
    }
    createdAt
    updatedAt
  }

  query CampaignGetOne($id: ID!) {
    campaign(id: $id) {
      ...Campaign_campaign
    }
  }
`
