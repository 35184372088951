import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  regex,
  number,
  maxLength,
  minValue,
  maxValue,
  // useRedirect,
} from 'react-admin'
// import { useNotify } from 'ra-core'
import { mapStateToProps } from './CurationList'
import { useStyles } from './CurationEdit'
import { urlRegex } from 'utils'

interface CurationCreateToolbarProps {
  basePath?: string
  record?: any
  redirect?: any
}

export const CurationCreateToolbar: FunctionComponent<CurationCreateToolbarProps> = props => {
  const classes = useStyles()
  // const redirect = useRedirect()
  // const { basePath, redirect: redirectTo = 'list' } = props
  // const notify = useNotify()

  // const handleSubmit = props => {
  //   notify('큐레이션을 3개 이상 만들 수 없습니다.', 'warning')
  //   redirect(redirectTo || '', basePath)
  // }

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton
      // handleSubmitWithRedirect={handleSubmit}
      />
    </Toolbar>
  )
}

export interface CurationCreateProps {
  location: any
  match?: any
  options?: any
}

const CurationCreate: FunctionComponent<CurationCreateProps> = props => {
  return (
    <Create title="큐레이션 생성" id={props.match.params.id} {...props}>
      <SimpleForm toolbar={<CurationCreateToolbar />}>
        <TextInput
          source="title"
          validate={maxLength(20, '최대 20자까지 입력할 수 있습니다.')}
        />
        <TextInput
          source="url"
          validate={regex(urlRegex, 'url 형식에 맞게 입력해주세요.')}
        />
        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 3까지 입력가능합니다.'),
            maxValue(3, '1부터 3까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

export default connect(mapStateToProps)(CurationCreate)
