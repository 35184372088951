import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  number,
  maxLength,
  minValue,
  maxValue,
  useRefresh,
  useRedirect,
} from 'react-admin'
import { Button, makeStyles } from '@material-ui/core'
import { mapStateToProps } from './RecommendKeywordList'
import DeleteIcon from '@material-ui/icons/Delete'

import { useRecommendKeywordDeleteMutation } from 'generated/graphql'

const useStyles = makeStyles({
  toolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
})

interface RecommendKeywordEditToolbarProps {
  basePath?: string
  record?: any
  redirect?: any
}

export const RecommendKeywordEditToolbar: FunctionComponent<RecommendKeywordEditToolbarProps> = props => {
  const classes = useStyles()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { basePath, redirect: redirectTo = 'list' } = props

  const [recommendSearchKeywordDelete] = useRecommendKeywordDeleteMutation({
    variables: {
      id: props.record.id,
    },
  })

  const handleOnClick = async () => {
    await recommendSearchKeywordDelete()
    redirect(redirectTo || '', basePath)
    refresh()
  }

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <Button
        color="primary"
        startIcon={<DeleteIcon />}
        onClick={handleOnClick}
      >
        삭제
      </Button>
    </Toolbar>
  )
}

export interface RecommendKeywordEditProps {
  location: any
  match?: any
  options?: any
}

const RecommendKeywordEdit: FunctionComponent<RecommendKeywordEditProps> = props => {
  return (
    <Edit
      title="resources.search-page.recommend-keyword.edit"
      id={props.match.params.id}
      {...props}
    >
      <SimpleForm toolbar={<RecommendKeywordEditToolbar />}>
        <TextInput
          source="title"
          validate={maxLength(10, '최대 10자까지 입력할 수 있습니다.')}
        />
        <TextInput
          source="seq"
          validate={[
            number('숫자만 입력가능합니다.'),
            minValue(1, '1부터 3까지 입력가능합니다.'),
            maxValue(3, '1부터 3까지 입력가능합니다.'),
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}

export default connect(mapStateToProps)(RecommendKeywordEdit)

gql`
  mutation RecommendKeywordDelete($id: ID!) {
    recommendSearchKeywordDelete(id: $id) {
      recommendSearchKeyword {
        id
      }
    }
  }
`
