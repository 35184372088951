export enum ResourceType {
  SignupSessions = 'signup-sessions',
  Users = 'users',
  Activities = 'activities',
  Replies = 'replies',
  SearchPage = 'search-page',
  Signup = 'signup',
  CoverLetters = 'cover-letters',
  Advertisement = 'advertisement',
}

export * from './signup-sessions'
export * from './users'
export * from './activities'
export * from './replies'
export * from './search-page'
export * from './cover-letters'
export * from './advertisement'
export * from './linkareer-magazine'
export * from './media-introduction'
