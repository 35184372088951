import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import {
  usePlacementCreate_AdPlacementCreateMutation,
  AdPlacementCreateInput,
} from 'generated/graphql'
import useAttachmentFileUpload, {
  ADVERTISEMENT,
} from 'hooks/useAttachmentFileUpload'
import { getApolloErrorCode } from 'utils'
import { ALREADY_EXISTING_CODE } from 'constants/errorCodes'
import { useSnackbar } from 'notistack'
import { BaseLayout } from 'components/layout/content'
import PlacementForm, { PlacementFormData } from './PlacementForm'

const PlacementCreate: FC = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { fileUpload } = useAttachmentFileUpload({
    path: ADVERTISEMENT,
  })
  const [createPlacement] = usePlacementCreate_AdPlacementCreateMutation({
    onCompleted: () => {
      enqueueSnackbar('광고 지면이 생성되었습니다.', { variant: 'success' })
      history.push('/advertisement/placements/list')
    },
    onError: error => {
      const errorCode = getApolloErrorCode(error)

      let errorMessage
      switch (errorCode) {
        case ALREADY_EXISTING_CODE: {
          errorMessage = '코드가 이미 존재합니다.'
          break
        }
        default: {
          errorMessage = '알 수 없는 오류가 발생하였습니다.'
        }
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
  })

  const handleSubmit = async ({
    code,
    name,
    width,
    height,
    isActive,
    defaultImageFiles,
    defaultLandingURL,
  }: PlacementFormData) => {
    const defaultImageFile = defaultImageFiles[0]
    const uploadedImage = await fileUpload(defaultImageFile)

    const placementInput: AdPlacementCreateInput = {
      name,
      code,
      width: Number(width),
      height: Number(height),
      isActive,
      defaultImageID: uploadedImage?.id,
      defaultLandingURL,
    }

    await createPlacement({
      variables: {
        input: placementInput,
      },
    })
  }

  return (
    <BaseLayout title="광고 지면 생성">
      <PlacementForm onSubmit={handleSubmit} />
    </BaseLayout>
  )
}

export default PlacementCreate

gql`
  mutation PlacementCreate_adPlacementCreate($input: AdPlacementCreateInput!) {
    adPlacementCreate(input: $input) {
      adPlacement {
        id
      }
    }
  }
`
