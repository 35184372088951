import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { List, Datagrid, EditButton, TextField } from 'react-admin'

const CurationList: FunctionComponent = props => {
  return (
    <List {...props} hasCreate bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="url" sortable={false} />
        <TextField source="seq" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const mapStateToProps = () => ({
  basePath: '/search-page/curation',
  resource: 'search-page.curation',
})

export default connect(mapStateToProps)(CurationList)
