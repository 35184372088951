import React, { FC, useState } from 'react'
import { useSendJobPostingDataToNaverMutation } from 'generated/graphql'
import { Button, makeStyles } from '@material-ui/core'

const getJsonIndented = obj => JSON.stringify(obj, null, 2)

const JSONViewer = ({ children }) => <pre>{getJsonIndented(children)}</pre>

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
})

const SendJobPostingDataToNaver: FC = () => {
  const classes = useStyles()
  const [res, setRes] = useState<{ data: JSON; totalCount: number }>()
  const [sendJobPostingDataToNaver] = useSendJobPostingDataToNaverMutation()

  const onSendClick = async () => {
    const result = await sendJobPostingDataToNaver()
    console.log(result)
    const res = result.data?.sendJobPostingDataToNaver
    setRes({
      data: JSON.parse(res?.data ?? ''),
      totalCount: res?.totalCount ?? 0,
    })
  }

  return (
    <>
      <div className={classes.actions}>
        <Button variant="contained" color="secondary" onClick={onSendClick}>
          Send!
        </Button>
      </div>
      {res && (
        <>
          <p>total : {res.totalCount}</p>
          <JSONViewer>{res?.data ?? ''}</JSONViewer>
        </>
      )}
    </>
  )
}

export default SendJobPostingDataToNaver
