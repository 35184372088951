import React, { FC, MouseEvent, ReactNode } from 'react'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(() => {
  return {
    root: {
      borderBottom: '1px solid #F2F2F2',
    },
    header: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '160px auto 30px',
      columnGap: 4,
      minHeight: 30,
      '&:hover': {
        cursor: 'pointer',
      },
      padding: '6px 10px',
      backgroundColor: '#E2E2E2',
      borderBottom: '1px solid #F2F2F2',
    },
    headerTitle: {
      fontWeight: 500,
    },
    headerDesc: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    headerEndAdornment: {
      textAlign: 'right',
    },
    expandMoreIconOpen: {
      transform: 'rotate(180deg)',
    },
    detail: {
      backgroundColor: '#F2F2F2',
    },
    detailClose: {
      height: 0,
      overflow: 'hidden',
    },
    detailOpen: {
      height: 'auto',
      padding: '6px 10px',
    },
  }
})

interface CoverLetterPlagiarismCheckItemProps {
  name: string
  onChange: (name: string) => void
  headerTitle: ReactNode
  headerDesc: ReactNode
  children: ReactNode
  open?: boolean
}

const CoverLetterPlagiarismCheckItem: FC<CoverLetterPlagiarismCheckItemProps> = ({
  name,
  onChange,
  headerTitle,
  headerDesc,
  open = false,
  children,
}) => {
  const classes = useStyles()
  const onClickHeader = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onChange(name)
  }
  return (
    <div className={classes.root}>
      <div
        aria-controls={`${name}-control`}
        id={`${name}-header`}
        onClick={onClickHeader}
        className={classes.header}
      >
        <div className={classes.headerTitle}>{headerTitle}</div>
        <div className={classes.headerDesc}>{headerDesc}</div>
        <div className={classes.headerEndAdornment}>
          <ExpandMore
            className={classNames({
              [classes.expandMoreIconOpen]: open,
            })}
          />
        </div>
      </div>
      <div
        className={classNames(classes.detail, {
          [classes.detailClose]: !open,
          [classes.detailOpen]: open,
        })}
      >
        <>{children}</>
      </div>
    </div>
  )
}

export default CoverLetterPlagiarismCheckItem
