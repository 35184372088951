/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import last from 'lodash/last'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import Popover from '@material-ui/core/Popover'
import FilterListIcon from '@material-ui/icons/FilterList'
import BaseForm from './fields/FilterForms'
import { renderValue } from './fields/utils'
import { FilterField } from './fields/fieldType'

const filter = createFilterOptions<FilterField>()

export function createGlobalQueryField(inputValue: string): FilterField {
  return {
    label: (
      <>
        {'"'}
        <b>{inputValue}</b>
        {'"'}으로 검색하기
      </>
    ) as any,
    field: 'q',
    value: inputValue,
    filterType: 'global-query',
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
)

export interface FilterBarProps {
  filterFields?: Array<FilterField>
  onFilterUpdate?: (filters: Array<FilterField>) => void
}

const FilterBar: React.FC<FilterBarProps> = props => {
  const {
    filterFields: filterFieldsProp = [
      // {
      //   label: '닉네임',
      //   field: 'username',
      //   filterType: 'string-match',
      // },
      // {
      //   label: '전화번호',
      //   field: 'username',
      //   filterType: 'string-match',
      // },
      // {
      //   label: '이메일',
      //   field: 'email',
      //   filterType: 'string-match',
      // },
    ],
    onFilterUpdate,
  } = props
  const classes = useStyles()

  const [selectedFields, setSelectedFields] = React.useState<FilterField[]>(
    filterFieldsProp as any,
  )

  useDeepCompareEffect(() => {
    setSelectedFields(filterFieldsProp)
  }, [filterFieldsProp])

  const [activeField, setActiveField] = React.useState<FilterField | null>(null)

  const inputStartEl = React.useRef(null)
  const updateSelectedFields = (filterFields: FilterField[]) => {
    setSelectedFields(filterFields)
    if (onFilterUpdate) {
      onFilterUpdate(filterFields)
    }
  }
  const handleClose = () => {
    setActiveField(null)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    // Delete
    if (selectedFields.length > get(newValue, 'length')) {
      updateSelectedFields(newValue)
    } else {
      const newFilterField = last(newValue) as FilterField
      // Create global query
      if (typeof newFilterField === 'string') {
        const globalQueryField = selectedFields.filter(
          item => item.filterType === 'global-query',
        )

        if (!globalQueryField.length) {
          updateSelectedFields([
            ...selectedFields,
            createGlobalQueryField(newFilterField),
          ])
        }
        // Insert global query
      } else if (newFilterField.filterType === 'global-query') {
        updateSelectedFields(newValue)
      } else {
        setActiveField(last(newValue) as any)
      }
    }
  }

  const handleSubmit = (result: any) => {
    updateSelectedFields([
      ...selectedFields,
      {
        ...(activeField as FilterField),
        value: result.value,
      },
    ])
    setActiveField(null)
  }

  const open = !!activeField
  const id = open ? 'filter-bar-form' : undefined

  return (
    <>
      <Divider />
      <Toolbar variant="dense">
        <InputAdornment position="start">
          <FilterListIcon />
        </InputAdornment>
        <Autocomplete
          multiple
          value={selectedFields}
          onChange={handleChange}
          className={classes.root}
          options={filterFieldsProp}
          filterSelectedOptions
          freeSolo
          filterOptions={(options, params) => {
            let filtered = filter(options, params) as any[]

            if (params.inputValue !== '') {
              filtered.push(createGlobalQueryField(params.inputValue))
            }

            filtered = filtered.filter(item =>
              findIndex(selectedFields, field => field.field === item.field),
            )

            return filtered
          }}
          renderTags={(value: any[], getTagProps) => {
            return value.map((option: any, index: number) => (
              <Chip
                key={option.label}
                variant="outlined"
                label={renderValue(option)}
                {...getTagProps({ index })}
              />
            ))
          }}
          getOptionLabel={option => option.label}
          renderInput={({ ...params }) => (
            <TextField
              {...params}
              placeholder="필터 입력"
              fullWidth
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                inputRef: inputStartEl,
              }}
            />
          )}
        />
      </Toolbar>
      <Divider />
      <Popover
        id={id}
        open={open}
        anchorEl={inputStartEl && (inputStartEl.current as any)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {!!activeField && (
          <BaseForm
            {...activeField}
            onSubmit={handleSubmit}
            onClickClose={handleClose}
          />
        )}
      </Popover>
    </>
  )
}

export default FilterBar
