import React, { FC } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    padding: 10,
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    padding: '5px 0',
  },
  description: {
    padding: '5px 0',
  },
})

interface TemplateProps {
  id: string
  title: string
  description?: string
}

const Template: FC<TemplateProps> = ({ children, id, title, description }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root} id={id} component="div">
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      {description && (
        <Typography className={classes.description} variant="body1">
          {description.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
      )}
      {children}
    </Paper>
  )
}

export default Template
