import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import {
  useCampaignCreate_CampaignCreateMutation,
  CampaignCreateInput,
  CampaignAdCreateInput,
} from 'generated/graphql'
import useAttachmentFileUpload, {
  ADVERTISEMENT,
} from 'hooks/useAttachmentFileUpload'
import { useSnackbar } from 'notistack'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import { BaseLayout } from 'components/layout/content'
import CampaignForm, {
  CampaignFormData,
  convertTimetableDataToCampaignSchedule,
} from './CampaignForm'

const CampaignCreate: FC = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueApolloError } = useErrorSnackbar()
  const { fileUpload } = useAttachmentFileUpload({
    path: ADVERTISEMENT,
  })
  const [createCampaign] = useCampaignCreate_CampaignCreateMutation({
    onCompleted: () => {
      enqueueSnackbar('캠페인이 생성되었습니다.', { variant: 'success' })
      history.push('/advertisement/campaigns/list')
    },
    onError: enqueueApolloError,
  })

  const handleSubmit = async ({
    ads,
    schedules: timetableData,
    startAt,
    endAt,
    ...other
  }: CampaignFormData) => {
    const { upsertAds } = ads

    const newAds: CampaignAdCreateInput[] = []
    for (const upsertAd of upsertAds) {
      if (!upsertAd.imageFiles) {
        continue
      }

      const imageFile = upsertAd.imageFiles[0]

      const uploadedImage = await fileUpload(imageFile)

      if (!uploadedImage) {
        continue
      }

      newAds.push({
        adPlacementID: upsertAd.adPlacementID,
        imageID: uploadedImage.id,
      })
    }

    const schedules = convertTimetableDataToCampaignSchedule(timetableData)

    const campaignInput: CampaignCreateInput = {
      ...other,
      ads: newAds,
      schedules,
      startAt: new Date(startAt),
      endAt: new Date(endAt),
    }

    await createCampaign({
      variables: {
        input: campaignInput,
      },
    })
  }

  return (
    <BaseLayout title="캠페인 생성">
      <CampaignForm onSubmit={handleSubmit} />
    </BaseLayout>
  )
}

export default CampaignCreate

gql`
  mutation CampaignCreate_campaignCreate($input: CampaignCreateInput!) {
    campaignCreate(input: $input) {
      campaign {
        id
      }
    }
  }
`
