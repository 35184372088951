import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'

import OrganizationDropoutLog from './OrganizationDropoutLog'
import UserDropoutLog from './UserDropoutLog'

const DropOutRouter: FC = () => {
  return (
    <>
      <Switch>
        <Route path="/dropoutlogs/user">
          <UserDropoutLog />
        </Route>
        <Route path="/dropoutlogs/organization">
          <OrganizationDropoutLog />
        </Route>
      </Switch>
    </>
  )
}

export default DropOutRouter
